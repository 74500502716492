import React from "react";
import { Link, Redirect } from 'react-router-dom';
import  AdminNavigation  from './AdminNavigation';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { isAuthenticated } from './auth'; // Import the isAuthenticated helper function
function Pages(){
  useEffect(() => {
    // Check if 'userId' is not present in localStorage
    if (!localStorage.getItem('userRole') && localStorage.getItem('userRole')!== 1) {
      // Redirect to the "/login" page if 'userId' is not present
      window.location.href = "/login";
    }
  }, []); // The empty dependency array ensures that this effect runs once when the component mounts
    const [pageslist, getPagesData] = useState([]);
const API_URL = `${process.env.REACT_APP_API_URL}/pages`;
const TOKEN = localStorage.getItem('adminToken');
//console.log("Token",TOKEN);

// Define a loading state variable
const [loading, setLoading] = useState(true);

const fetchPagesData = () => {
  fetch(API_URL, {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res['data']);
      getPagesData(res['data']);
    }).catch((error) => {
        // Handle any errors and hide the loader
        
        console.error('Error fetching user data:', error);
      }).finally(() => {
        // Hide the loader whether the request succeeds or fails
        setLoading(false);
      });
};

useEffect(() => {
    fetchPagesData();
}, []);

useEffect(() => {
  //console.log("pageslist", pageslist);
}, [pageslist]);
//Delete Functionality 

const delPagedata = (id) => {
    //console.log(id);
    const TOKEN = localStorage.getItem('adminToken');
    if (window.confirm('Do You want to delete this page ?')) {
    axios.delete(`${process.env.REACT_APP_API_URL}/pages/delete/${id}`,{ headers: {
        Authorization: `Bearer ${TOKEN}`,
      },}).then(() => {
        fetchPagesData();
    })
    }
 }
//Update functionality
const updatePagedata = (update_page_data)=>{
    //console.log(update_page_data);
    let { id, bannerurl, bannertitle,bannerdesc,pagetitle,pagedesc,template,slug,sort,top_menu,pageImage} = update_page_data;
       localStorage.setItem('ID', id);
       localStorage.setItem('Banner URL', bannerurl);
       localStorage.setItem('Banner Title', bannertitle);
       localStorage.setItem('Banner Description', bannerdesc);
       localStorage.setItem('Page Title', pagetitle);
       localStorage.setItem('Page Description', pagedesc);
       localStorage.setItem('Page Template', template);
       localStorage.setItem('Page Slug', slug);
       localStorage.setItem('Page Sort', sort);
       localStorage.setItem('Top menu', top_menu);
       localStorage.setItem('Page Image', pageImage);

}

if (loading) {
  // Render a loading overlay with the "Loading..." message
  return (
    <div className="loading-overlay">
      {/* <div className="loading-content">Loading...</div> */}
      <img src='/img/loader.gif'></img>
    </div>
  );
}
    return(
        <>
         <div id="wrapper" className="toggled">
      <AdminNavigation/>
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                       
                        <Link to={"/admin/add_pages"} className="btn btn-primary mb-3">Add New Page</Link>
                       <h2>All Pages</h2>
                       <div className="table-responsive">
  <table className="table table-hover">
    <thead>
      <tr>
        <th>Sno</th>
        <th>Banner URL</th>
        <th>Banner Title</th>
        <th>Banner Description</th>
        <th>Page Title</th>
        <th>Page Slug</th>
        <th>Page Description</th>
        <th>Template</th>
        <th>Sort</th>
        <th>Top menu</th>
        <th>Page Image</th>
        <th>Created At</th>
        <th colSpan={2}>Action</th>
      </tr>
    </thead>
    <tbody>
      {pageslist.map((PageListData, key) => {
        return (
          <tr key={key}>
            <td>{key + 1}</td>
            {/*{PageListData.bannerurl} */}
            <td><img src={PageListData.bannerurl}></img></td>
            <td>{PageListData.bannertitle}</td>
            <td>{PageListData.bannerdesc}</td>
            <td>{PageListData.pagetitle}</td>
            <td>{PageListData.slug}</td>
            <td>{PageListData.pagedesc && PageListData.pagedesc.length > 100 ? `${PageListData.pagedesc.slice(0, 100)}...` : PageListData.pagedesc}</td>

            <td>{PageListData.template}</td>
            <td>{PageListData.sort}</td>
            <td>{PageListData.top_menu}</td>
            <td><img src={PageListData.pageImage} /></td>
            <td>{PageListData.created_at}</td>
            <td>
              <Link to={'/admin/updatepagedata'}>
                <button onClick={() => updatePagedata(PageListData)} className='btn btn-primary'>Edit</button>
              </Link>
            </td>
            <td>
              <button onClick={() => delPagedata(PageListData.id)} className='btn btn-danger'>Delete</button>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
</div>

                    
                    </div>
                </div>
            </div>
        </div>
    </div>
        </>
    );

}
export default Pages