import React from "react";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { isAuthenticated } from './auth'; // Import the isAuthenticated helper function
import { Link, Redirect,useNavigate } from "react-router-dom";
import  AdminNavigation  from './AdminNavigation';
function ConsultationFormDataListing(){
  const navigate = useNavigate();
    useEffect(() => {
        // Check if 'userId' is not present in localStorage
        if (!localStorage.getItem('userRole') && localStorage.getItem('userRole')!== 1) {
          // Redirect to the "/login" page if 'userId' is not present
          window.location.href = "/login";
        }
      }, []); // The empty dependency array ensures that this effect runs once when the component mounts

    // Define a loading state variable
const [loading, setLoading] = useState(true);

const [FormList,getVideoChatFormData] = useState([]);

console.log(FormList,"FormList");

const API_URL = `${process.env.REACT_APP_API_URL}/svconsultation/list?type=singlevacationconsulatant`;
  //Get admin token form the database for the token
  const TOKEN = localStorage.getItem('adminToken');

  const fetchVideoChatFormData = () =>{
    // Set loading to true to indicate that data fetching is in progress
    setLoading(true);
    fetch(API_URL)
    .then((res)=>res.json())
    .then((res)=>{
        // console.log(res['data']);
        getVideoChatFormData(res['data'].data);
     }).catch((error) => {
         // Handle any errors and hide the loader
         
         console.error('Error fetching user data:', error);
       }).finally(() => {
         // Hide the loader whether the request succeeds or fails
         setLoading(false);
       });

  }
  useEffect(() => {
    fetchVideoChatFormData()
  }, [])

useEffect(() => {
   // console.log("userlist", userslist);
}, [FormList])
// Render a loader conditionally based on the "loading" state



//Delete Functionality 

const delUserdata = (id) => {
  //console.log(id);
  if (window.confirm('Do You want to delete ?')) {
  axios.delete(`${process.env.REACT_APP_API_URL}/svconsultation/delete/${id}`, {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  }).then(() => {
    fetchVideoChatFormData();
  })
  }
}


const handleViewandManageCallDetails = (callID) => {
  navigate(`/admin/viewandassigntranslators/${callID}&type=singlevacationconsulatant`,
  { state: { callID } });
};

return(<>
<div id="wrapper" className="toggled">
   <AdminNavigation/>
        
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-lg-offset-2">
                      {/* {deactivate_msg} */}
                   
                      <h5>Consultation Form Data</h5>
                      <div className="table-responsive mb-3">
                      <table className="table table-light table-striped">
                        <thead>
                        <tr>
                            <th>Sno</th>
                            <th>Firstname</th>
                            <th>Lastname</th>
                            <th>Age</th>
                            <th>country</th>
                            <th>State</th>
                            <th>City</th>
                            <th>Occupation</th>
                            <th>Consultation Time</th>
                            <th>Amount</th>
                            <th>Ladies Profile Id</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                            <th>Action</th>
                            
                        </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(FormList) && FormList.map((VideoChatListData, key) => {
                            return(<tr>
                            <td>{key+1}</td>
                            <td>{VideoChatListData.fname}</td>
                            <td>{VideoChatListData.lname}</td>
                            <td>{VideoChatListData.age}</td>
                            <td>{VideoChatListData.Country.name}</td>
                            <td>{VideoChatListData.State.name}</td>
                            <td>{VideoChatListData.City.name}</td>
                            <td>{VideoChatListData.occupation}</td>
                            <td>{VideoChatListData.consultation_time}</td>
                            <td>{VideoChatListData.amount}</td>
                            <td className='ladies_profile_number_td'>{VideoChatListData.profilenumber_ladies.replace(/,/g, ',\n')}</td>
                            <td>{VideoChatListData.createdAt}</td>
                            <td>{VideoChatListData.updatedAt}</td>
                            <td><button onClick={() => delUserdata(VideoChatListData.id)} className='btn btn-danger'>Delete</button></td>
                            <td>
                            <a onClick={() => handleViewandManageCallDetails(VideoChatListData.id)}  class="btn btn-primary mb-2">View </a>
                            </td>
                            </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>);

}
export default ConsultationFormDataListing