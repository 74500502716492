import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import  AdminNavigation  from './AdminNavigation';
import { useNavigate } from "react-router-dom";
function UpdateAdminEmailSettingsData(){
    const[admin_email,setAdminUrl] = useState(''); 
    const[id,setID]=useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        setID(localStorage.getItem('ID'));
        setAdminUrl(localStorage.getItem('Admin Email'));
       
       
}, []);
const TOKEN = localStorage.getItem('adminToken');
console.log(TOKEN);

const updateSelectedAdminEmailSettingData = () => {
    let res =   axios.put(`${process.env.REACT_APP_API_URL}/setting/${id}`, {
        admin_email}, {
       headers: {
         Authorization: `Bearer ${TOKEN}`
       }
     });
   if(res){
           console.log(res);
          // alert("updated"+res);
          // window.location.href = "/admin/pages";
          navigate("/admin/settings");
       }else{
         console.log(res);
          //alert("failed to update");
       }
   }

   return(<>
    <div id="wrapper" className="toggled">
     <AdminNavigation/>
          
          <div id="page-content-wrapper">
              
              <div className="container">
                  <div className="row">
                      <div className="col-md-4 mx-auto"></div>
                  <h2>Update Site URL</h2>
                  <form>
                     
                      <label>Admin Email:</label>
                      <textarea name="admin_email" id="admin_email" className="form-control" placeholder="Enter Site URL" value={admin_email} onChange = {(e) => setAdminUrl(e.target.value)} required></textarea>
                      <br/>
                      <input type="submit" onClick={updateSelectedAdminEmailSettingData} className="btn btn-primary mb-2" id='update_admin_email' value="Update Admin Email"/>
  
                  </form>
      </div>
      </div>
      </div>
      </div>
   </>
   )
   

}
export default UpdateAdminEmailSettingsData