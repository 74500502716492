import React, { useState, useEffect } from "react";
import OwlSliderSection from './OwlsliderSection';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
//import { useParams } from 'react-router-dom';

function Aboutus() {
  const [allSlides, setSlidesData] = useState();
  const API_URL = `${process.env.REACT_APP_API_URL}/setting/getsetting`;
  const [loading,setLoading] = useState(true);//Add a loader 
  const navigate = useNavigate();

  const fetchSliderData = async () => {
    try {
      const response = await axios.get(API_URL);
      const data = response.data.data[0];
      setSlidesData(data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }finally {
      setLoading(false); // Set loading to false whether the request succeeds or fails
    }
  };

  useEffect(() => {
    fetchSliderData();
  }, []);
  const [pageContent, setPageContent] = useState(null);
  
  useEffect(() => {
    // Fetch page content from the server based on the slug prop
    const fetchPageContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/slug/aboutus`);
        setPageContent(response.data.data['0']);
        
      } catch (error) {
        console.error('Error fetching page content:', error);
         console.error('Error fetching user data:', error);
            if (error.response.status === 401) {
              navigate("/login");
            }
      }
    };

    fetchPageContent();
  },[]);

  if (loading) {
    // Render a loading overlay with the "Loading..." message
    return (
      <div className="loading-overlay">
        {/* <div className="loading-content">Loading...</div> */}
        <img src='/img/loader.gif'></img>
      </div>
    );
  }
  if (!pageContent) {
    return null; // Or render some loading/error message
  }
  const style = {
    backgroundImage: `url('${pageContent.bannerurl}')`,
    // Add other CSS properties as needed
  };
  
  return (
    
    
    <>
      <section className="page-info-sec">
        <div className="bg-fixed"  style={style}>
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>{pageContent ? pageContent.bannertitle : 'About Us'}</h1>
                <p>{pageContent ? pageContent.bannerdesc : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur malesuada risus quis ligula pellentesque fermentum. Aenean vel ex finibus, lacinia lacus non, sodales dolor. Phasellus eget turpis neque. Sed aliquet nec felis non faucibus. Quisque euliy tristique elit, sit amet laoreet neque. Vestibulum aliquam at augue at dictum. Praes ent interdum blandit elit, nec ullamcorper odio.'}</p>
                <p className="page_nav"> <a href="/">Home</a>/<a href="#" className="currentPage">{pageContent ? pageContent.bannertitle : 'About us'}</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="find-woman-sec secpd70">
        <div className="container">
          {/* <div className="row flex-md-row-reverse"> */}
          <div className="row mb-3">
            <div className="col-md-10 mx-auto aboutus_section">
            {pageContent ? (
                  <>
                    <h2 className="title-30 mb-3">{pageContent.pagetitle}</h2>
                    {/* <p>{pageContent.pagedesc}</p> */}
                    <p dangerouslySetInnerHTML={{ __html: pageContent.pagedesc }} />
                    {/* Add other dynamic content here */}
                    {/* <a href="#" className="btn-1 btn-md">View more</a> */}
                  </>
                ) : (
                  <p>Loading...</p>
                )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mx-auto">
            <OwlSliderSection allSlides={allSlides} />
            </div>
           
          </div>
        </div>
      </section>
    </>
  )
}

export default Aboutus;
