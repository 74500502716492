import React, { useState, useEffect } from "react";
import axios from 'axios';
import AdminNavigation from './AdminNavigation';

const CreateMeeting = () => {
  const [meetingTime, setMeetingTime] = useState('');
  const [error, setError] = useState(null);
  const TOKEN = localStorage.getItem('adminToken');

  useEffect(() => {
    const createMeeting = async () => {
      try {
        const CreateMeetingAPI = `${process.env.REACT_APP_API_URL}/zoom/create-meeting`;
        const response = await axios.post(CreateMeetingAPI,
          { start_time: meetingTime },
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`, // Pass JWT Token in Authorization header
            },
          }
        );
        console.log(response, "MEETING RESP");
        setError(null);
      } catch (error) {
        console.error('Error creating Zoom meeting:', error);
        setError(error.response?.data?.message || 'An error occurred while creating the meeting.');
      }
    };

    if (meetingTime !== '') { // Only create meeting if meetingTime is not empty
      createMeeting();
    }
  }, [meetingTime, TOKEN]);

  return (
    <div id="wrapper" className="toggled">
      <AdminNavigation />
      <div id="page-content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-lg-offset-2">
              <h3>Add A Meeting</h3>
              {error && <div className="alert alert-danger" role="alert">{error}</div>}
              <form className="mt-4">
                <div className="mb-3">
                  <label htmlFor="meetingTime" className="form-label">
                    Meeting Time:
                  </label>
                  <input
                    type="datetime-local"
                    id="meetingTime"
                    className="form-control"
                    value={meetingTime}
                    onChange={(e) => setMeetingTime(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary" onClick={(e) => e.preventDefault()}>
                  Create Meeting
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMeeting;
