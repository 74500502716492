import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { isAuthenticated } from './auth'; // Import the isAuthenticated helper function
import { useNavigate } from "react-router-dom";
import  AdminNavigation  from './AdminNavigation';
function UpdateVideosData(){
    const [title, setVideoTitle] = useState('');
    const [url, setVideoURL] = useState('');
    const [id, setID] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");

    const navigate = useNavigate();

    const siteUrl = process.env.REACT_APP_SITE_URL;
    useEffect(() => {
            setID(localStorage.getItem('ID'))
            setVideoTitle(localStorage.getItem('Video Title'));
            setVideoURL(localStorage.getItem('Video URL'));
            
    }, []);
    const TOKEN = localStorage.getItem('adminToken');
    console.log(TOKEN);
    const updatePlaylistVideosData = () => {
     let res =   axios.put(`${process.env.REACT_APP_API_URL}/video/update/${id}`, {
        title,
        url
     }, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      });
    if(res){
            console.log(res, 'res');
            //alert("updated"+res);
            //window.location.href = "/admin/admin_video_playlist";
            // window.location.href = `${siteUrl}/admin/admin_video_playlist`;
            navigate("/admin/admin_video_playlist");

            
        }else{
          console.log(res);
           //alert("failed to update");
        }
    }
    return(
		<div id="wrapper" className="toggled">
   <AdminNavigation/>
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            </div>
        <div className="container">
        <div className="row mt-4">
          <div className="col-md-8 mx-auto registration_section">
          {successMessage && (
              <div className="alert alert-success mt-3 text-center" role="alert">
                {successMessage}
              </div>
            )}
            <h4>Edit Videos</h4>
            <form>
              <div className="mb-3 mt-3">
                <label htmlFor="title" className="form-label">Video Title:</label>
                <input type="text" className="form-control" id="title" placeholder="Enter Video Title" name="title" value={title} onChange={(e) => setVideoTitle(e.target.value)} required />
              </div>
              <div className="mb-3 mt-3">
                <label htmlFor="url" className="form-label">Video URL:</label>
                <input type="text" className="form-control" id="url" placeholder="Enter Video URL" name="url" value={url} onChange={(e) => setVideoURL(e.target.value)} required />
              </div>
              <button type="submit" onClick={updatePlaylistVideosData} className="btn btn-primary regsiter_btn mb-4">Update</button>
            </form>
        </div>
        </div>
      </div>
      </div>
	)

}
export default UpdateVideosData
 