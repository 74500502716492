import React, { useEffect, useState } from 'react';
import OwlSliderSection from './OwlsliderSection';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Home() {
  const [allSlides, setSlidesData] = useState();
  const API_URL = `${process.env.REACT_APP_API_URL}/setting/getsetting`;
  const [pageContent, setPageContent] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const navigate = useNavigate();

  const fetchSliderData = async () => {
    try {
      const response = await axios.get(API_URL);
      const data = response.data.data[0];
      setSlidesData(data);
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false); // Set loading to false whether the request succeeds or fails
    }
  };

  useEffect(() => {
    fetchSliderData();
  }, []);

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/slug/home`);
        setPageContent(response.data.data['0']);
      } catch (error) {
        // Handle error
        console.error('Error fetching user data:', error);
            if (error.response.status === 401) {
              navigate("/login");
            }
      }
    };

    fetchPageContent();
  }, []);

  if (loading) {
    // Render a loading overlay with the "Loading..." message
    return (
      <div className="loading-overlay">
        {/* <div className="loading-content">Loading...</div> */}
        <img src='/img/loader.gif'></img>
      </div>
    );
  }

  if (!pageContent) {
    return null; // Or render some loading/error message
  }

  return (
    <>
      <section className="find-woman-sec secpd70">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              {pageContent.pagedesc && (
                <div className="adv_box" dangerouslySetInnerHTML={{ __html: pageContent.pagedesc }}></div>
              )}
            </div>
            <div className="col-md-9">
              <h2 className="title-30 mb-3">{pageContent.bannerdesc}</h2>
              <OwlSliderSection allSlides={allSlides} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
