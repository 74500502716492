import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
function TranslatorDashboard(){
    useEffect(() => {
        // Check if 'userId' is not present in localStorage
        if (!localStorage.getItem('userId')) {
          // Redirect to the "/login" page if 'userId' is not present
          window.location.href = "/login";
        }
      }, []); // The empty dependency array ensures that this effect runs once when the component mounts
      return(<>
        <section className="page-info-sec">
    <div className="bg-fixed" style={{backgroundImage:"url('./img/inner_banner.png')"}}>
    </div>
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col">
          <div className="page-info-block">
            <h1>Transaltor Status</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur male</p>
            <p className="page_nav"> <a href="/">Home</a><span  className="mx-2">/</span><a href="#" className="currentPage">Transaltor Status</a></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="welcome-sec secpd70">
    <div className="container">
      <div className="shadow-wrap-1">
        <div className="row gap-40">
          <div className="col-12">
            <h2 className="title-30 mb-3">Hello {localStorage.getItem('Username')},</h2>
          </div>
          {/* <div className="col-1/5">
            <Link to ="/updateprofile">
            <div className="welcome-box">
              <div className="wb-icon-box">
                <img src="img/icon-1.png" alt=""/>
              </div>
              <p>Update Profile</p>
            </div>
            </Link>
          </div>
    
          <div className="col-1/5">
          <Link to="/resetpassword">
            <div className="welcome-box">
              <div className="wb-icon-box">
                <img src="img/icon-2.png" alt=""/>
              </div>
              <p>Reset Password</p>
            </div>
            </Link>
          </div> */}

          
         
          <div className="col-1/5">
          <Link to="/requestedcallslisitng">
            <div className="welcome-box">
              <div className="wb-icon-box">
              <img src="img/icon-3.png" alt=""/>
              </div>
              <p>All Call Request</p>
            </div>
            </Link>
          </div>
          <div className="col-12">
            <hr className="form-divider" />
          </div>
        </div>
        
      </div>
    </div>
  </section>
        </>
);
}
export default TranslatorDashboard