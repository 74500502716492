// EyeIcon.js

import React from 'react';

const EyeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
    <path d="M12 4.5c-3.935 0-7.538 2.731-9.765 7.248C4.462 14.481 8.065 17.5 12 17.5s7.538-3.019 9.765-5.752C19.538 7.231 15.935 4.5 12 4.5zm0 10c-2.112 0-4-1.688-4-3.75s1.888-3.75 4-3.75 4 1.688 4 3.75-1.888 3.75-4 3.75zm0-7.5c-1.103 0-2 .875-2 1.875s.897 1.875 2 1.875 2-.875 2-1.875-.897-1.875-2-1.875z"/>
  </svg>
);

export default EyeIcon;
