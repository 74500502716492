import React, { useState, useEffect } from "react";
import axios from "axios";

function Feedback() {
  const [commenttext, setCommentText] = useState('');
  const [feedbackcomment, setFeedbackComment] = useState('');
  const [rating, setFeedbackRating] = useState('');
  const [feedbackfiles, setFeedbackFiles] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const userid = localStorage.getItem('userId');
  //console.log("UID===================>",userid)
  const handelFileChange = (event) => {
    const feedbackFiles = Array.from(event.target.files);
    setFeedbackFiles(feedbackFiles);
  }
  const hideErrorMessage = () => {
    setErrorMessage('');
  }
   
   const handelSubmit = async (submitevent) => {
    submitevent.preventDefault();

     // Simple form validation
     if (!commenttext || !feedbackcomment || !rating ) {
      setErrorMessage('Please fill out all the required fields.');
      setTimeout(hideErrorMessage, 5000); // Auto-hide the error message after 5 seconds
      return;
      ;
    }


    const formData = new FormData();
    feedbackfiles.forEach((file, index) => {
      formData.append('attachfile', file); // Use 'images' as the key
    });
    formData.append('title', commenttext);
    formData.append('comments', feedbackcomment);
    formData.append('rating', rating);
    formData.append('user_id', userid);
    // const FeedbackFormData = { commenttext, feedbackcomment, rating,feedbackfiles,userid };
    //console.log("FormData",FeedbackFormData);
    const TOKEN = localStorage.getItem('adminToken');

    //console.log(userid,"userid");
    //console.log(commenttext, feedbackcomment, rating,feedbackfiles,userid,"Data");
    //console.log(FeedbackFormData,"FEDbackData");

    try {
      const feedback_response = await axios.post(
        `${process.env.REACT_APP_API_URL}/feedback/add/`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Content-Type': 'multipart/form-data', // Set the Content-Type header for file uploads
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );

      // console.log('FeedbackFormData', FeedbackFor mData);
      console.log('response', feedback_response);
      setSuccessMessage('Feedback sent successfully!');
    } catch (error) {
      // Handle the error here
      console.error('Error:', error);
    }
  }

  

  const [pageContent, setPageContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/slug/feedback`);
        setPageContent(response.data.data[0]);
      } catch (error) {
        console.error('Error fetching page content:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchPageContent();
  }, []);

  if (loading) {
    return (
      <div className="loading-overlay">
        <img src="/img/loader.gif" alt="Loading" />
      </div>
    );
  }

  if (!pageContent) {
    return null;
  }

  const style = {
    backgroundImage: `url('${pageContent.bannerurl}')`,
  };

  return (
    <>
      <section className="page-info-sec">
        <div className="bg-fixed" style={style}></div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>{pageContent ? pageContent.bannertitle : 'Feedback'}</h1>
                <p>{pageContent ? pageContent.bannerdesc : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...'}</p>
                <p className="page_nav"> <a href="/">Home</a>/<a href="#" className="currentPage">{pageContent ? pageContent.bannertitle : 'Feedback'}</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="video-chat-form-sec secpd70">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {successMessage && (
                <div className="alert alert-success mt-3 text-center" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="main-from-wrap">
                <div className="main-from-inner-wrap">
                  <div className="title-centered text-center">
                    <h2 className="title-30 mb-3">How was your Experience?</h2>
                    <p>If you have bought a service and wish to discuss your experience, please fill out this review form.</p>
                  </div>

                  <div className="formwrapper">
                  {errorMessage && (
                          <div className="alert alert-danger mt-3" role="alert">
                            {errorMessage}
                          </div>
                        )}
                    <form onSubmit={handelSubmit} encType="multipart/form-data">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-3 mb-md-4">
                            <label>Rate<span className="required">*</span></label>
                            <div className="rating_box_wrap">
                              <input type="radio" name="rating" value="1" onChange={(e) => setFeedbackRating(e.target.value)} />
                              <input type="radio" name="rating" value="2" onChange={(e) => setFeedbackRating(e.target.value)} />
                              <input type="radio" name="rating" value="3" onChange={(e) => setFeedbackRating(e.target.value)} />
                              <input type="radio" name="rating" value="4" onChange={(e) => setFeedbackRating(e.target.value)} />
                              <input type="radio" name="rating" value="5" onChange={(e) => setFeedbackRating(e.target.value)} />
                              <div className="rating_box"></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3 mb-md-4">
                            <label>Title<span className="required">*</span> <small>(Limit to 100 characters)</small></label>
                            <input type="text" id="title" name="title" className="form-control" placeholder="Journey of life" value={commenttext} onChange={(e) => setCommentText(e.target.value)} />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3 mb-md-4">
                            <label>Comment<span className="required">*</span> <small>(Limit to 500 characters)</small></label>
                            <textarea name="comments" id="comments" className="form-control" rows="4" placeholder="Lorem ipsum doler sit amet elit" value={feedbackcomment} onChange={(e) => setFeedbackComment(e.target.value)}></textarea>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3 mb-md-4">
                            <label className="c_file-upload">
                              <input name="attachfile" id="attachfile" type="file" onChange={handelFileChange} multiple />
                              <button className="btn btn-outline-primary"><i className="fa fa-cloud-upload"></i> Upload Picture</button>
                              <span className="choosefile">Choose Files</span>
                            </label>
                            <p className="small">Limited between 1 and 10 photos (under 10 MB of file size)</p>
                          </div>
                        </div>

                        <div className="col-md-1"></div>
                        <div className="col-md-10"><button type="submit" className="btn-1 w-100">Submit</button></div>
                        <div className="col-md-1"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Feedback;
