import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const storedLoginResponse = localStorage.getItem("loginResponse");
const loginResponse = storedLoginResponse
  ? JSON.parse(storedLoginResponse)
  : null;
const token =
  loginResponse && loginResponse.data && loginResponse.data.tokan
    ? loginResponse.data.tokan
    : null;

const UserOrder = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState(null);
  const [giftData, setGiftData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const fetchUserOrderData = async () => {
    const storedLoginResponse = localStorage.getItem("loginResponse");
    const loginResponse = storedLoginResponse
      ? JSON.parse(storedLoginResponse)
      : null;
    const token =
      loginResponse && loginResponse.data && loginResponse.data.tokan
        ? loginResponse.data.tokan
        : null;
    const userId = localStorage.getItem("userId");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/orderbyuserid/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
          params: {
            pageNumber: currentPage,
            pageSize: pageSize,
          },
        }
      );
      setOrderData(response.data.data.data);
      console.log(response.data.data.data);      
      setTotalPages(response.data.data.totalPages || 1);
    } catch (error) {
      if (error.response) {
        setErrors({
          form: "An error occurred. Please try again later.",
        });
      } else {
        setErrors({
          form: "An error occurred. Please try again later.",
        });
      }
      console.error('Error fetching user data:', error);
            if (error.response.status === 401) {
              navigate("/login");
            }
    }
  };

  const fetchGiftData = async () => {
    try {
      const userData = await axios.get(
        `${process.env.REACT_APP_API_URL}/gifts/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      setGiftData(userData.data);
    } catch (error) {
      if (error.response) {
        setErrors({
          form: "An error occurred. Please try again later.",
        });
      } else {
        setErrors({
          form: "An error occurred. Please try again later.",
        });
      }
    }
  };

  useEffect(() => {
    fetchUserOrderData();
    fetchGiftData();
  }, [currentPage, pageSize]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleViewOrderDetails = (orderId) => {
    navigate(`/view-details/${orderId}`);
  };
  // Function to handle order cancellation
  const handleCancelOrder = async (orderId) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/order/orderupdate/${orderId}`,
        { status: "cancel" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);

      if (response.data.status) {
        // Refresh the order data after successful update
        fetchUserOrderData();
      } else {
        console.error("Failed to cancel the order:", response.data);
        setErrors("Failed to cancel the order. Please try again.");
      }
    } catch (error) {
      console.error("Error during order cancellation:", error);
      setErrors("Failed to cancel the order. Please try again.");
    }
  };
  // Function to handle order refund rquest
  const handleRefundOrder = async (orderId) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/order/orderupdate/${orderId}`,
        { status: "refund" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.data.status) {
        // Refresh the order data after successful update
        fetchUserOrderData();
      } else {
        console.error("Failed to cancel the order:", response.data);
        setErrors("Failed to cancel the order. Please try again.");
      }
    } catch (error) {
      console.error("Error during order cancellation:", error);
      setErrors("Failed to cancel the order. Please try again.");
    }
  };

  return (
    <>
      <br />
      <main>
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto" style={{ display: "flex" }}>
              <p className="section-title">Order History</p>
              {/* <br /> */}
            </div>
          </div>
          {orderData ? (
            orderData?.map((order, index) => (
              <>
              <div className="row">
                <div className="col-md-10 mx-auto">
                <div class="card mb-3">
                  <div class="mb-6">
                    
                    {order?.Giftcarts?.map((gift, giftIndex) =>

                    (
                      <div class="row justify-content-between align-items-center">
                        <div class="col-lg-3 col-12">
                          <div class="d-md-flex">

                            <div className="img-fluid">
                              <img src={`${process.env.REACT_APP_API_URL}/${gift?.Gift?.image}`} className="img-fluid"/>
                            </div>
                           
                          </div>
                        </div>
                        <div className="col-md-4">
                      
                              <h5 class="mb-1">
                                {gift.Gift.title}
                              </h5>
                              <span>Quantity: <span class="text-dark">{gift.quantity}</span>, Order Number:<span class="text-dark"> {order.id}</span>
                              </span>
                              <div class="mt-3">
                                <h4>${order.amount}</h4>
                              </div>
                          
                        </div>
                        <div class="col-lg-2 col-12">
                          <a onClick={() => handleViewOrderDetails(order.id)} href="#" class="btn btn-primary mb-2">View Details</a>
                         
                        </div>
                        <div class="col-lg-2 col-12">
                          <a href="#" class="btn btn-primary mb-2">{order.orderStatus}</a>
                         
                        </div>
                      </div>
                    )
                    )}
                  </div>
                </div>
                </div>
                </div>

               
              </>
            ))
          ) : (
            <div>
              <p>No records found</p>
            </div>
          )}
          {/* Pagination controls */}
          <div className="row">
            <div className="col-md-10 mx-auto">
          <nav
            aria-label="Page navigation example"
          // style={{ position: "relative", left: "50%" }}
          >
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <a
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </a>
              </li>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <li
                    key={page}
                    className={`page-item ${currentPage === page ? "active" : ""
                      }`}
                  >
                    <a
                      className="page-link"
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </a>
                  </li>
                )
              )}
              <li
                className={`page-item ${currentPage === totalPages ? "disabled" : ""
                  }`}
              >
                <a
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </a>
              </li>
            </ul>
          </nav>
          </div>
          </div>
          <br />
        </div>
      </main>
    </>
  );
};

export default UserOrder;
