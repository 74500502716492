import React from 'react';
import {useState, useEffect } from "react";
import axios from "axios";
function TermsConditions(){
    const [pageContent, setPageContent] = useState(null);
    useEffect(()=>{
    const fetchPageContent = async()=>{
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/slug/termsconditions`);
        setPageContent(response.data.data['0']);
        
      } catch (error) {
        console.error('Error fetching page content:', error);
      }
    
    }
    fetchPageContent();
    },[]);
    //console.log("Page Contetn",pageContent);
      if (!pageContent) {
        return null; // Or render some loading/error message
      }
      const style = {
        backgroundImage: `url('${pageContent.bannerurl}')`,
        // Add other CSS properties as needed
      };
    return(
        <>
        <section className="page-info-sec">
        <div className="bg-fixed"  style={style}>
    </div>
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col">
          <div className="page-info-block">
            <h1>{pageContent ? pageContent.bannertitle : 'Terms of Use'}</h1>
            <p>{pageContent ? pageContent.bannerdesc : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur malesuada risus quis ligula pellentesque fermentum. Aenean vel ex finibus, lacinia lacus non, sodales dolor. Phasellus eget turpis neque. Sed aliquet nec felis non faucibus. Quisque euliy tristique elit, sit amet laoreet neque. Vestibulum aliquam at augue at dictum. Praes ent interdum blandit elit, nec ullamcorper odio.'}</p>
            <p className="page_nav"> <a href="/">Home</a>/<a href="#" className="currentPage">{pageContent ? pageContent.bannertitle : 'Terms of Use'}</a></p>
          </div>
        </div>
      </div>
    </div>
    </section>
    {/* {pageContent.pagedesc} */}
    {pageContent.pagedesc && (<div dangerouslySetInnerHTML={{ __html: pageContent.pagedesc }} ></div>)} 
    </>
    );

}
export default TermsConditions