import React, { useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function OwlSliderSection(props) {
  const { allSlides } = props;
  const [activeSlide, setActiveSlide] = useState(0);

  const handleThumbnailClick = (index) => {
    setActiveSlide(index);
  };

  return (
    <>
      <div className="find-woman-slider-wrap">
        {allSlides && (
          <>
            <OwlCarousel
              className='owl-theme'
              items={1}
              margin={8}
              autoplay={true}
              startPosition={activeSlide}
            >
              {allSlides.slider_img1 && (
                <div className='item'>
                  <div className="user-img-box main_slider_img">
                    <img src={`${allSlides.slider_img1}`} alt="" />
                  </div>
                </div>
              )}
              {allSlides.slider_img2 && (
                <div className='item'>
                  <div className="user-img-box main_slider_img">
                    <img src={`${allSlides.slider_img2}`} alt="" />
                  </div>
                </div>
              )}
              {allSlides.slider_img3 && (
                <div className='item'>
                  <div className="user-img-box main_slider_img">
                    <img src={`${allSlides.slider_img3}`} alt="" />
                  </div>
                </div>
              )}
            </OwlCarousel>
            <OwlCarousel
              className='owl-theme owl-thumbnails'
              items={3}
              margin={8}
              autoplay={true}
              startPosition={activeSlide}
            >
              {allSlides.slider_img1 && (
                <div
                  className="item thumbnail-item"
                  onClick={() => handleThumbnailClick(0)}
                >
                  <div className="user-img-box thumbnail">
                    <img src={`${allSlides.slider_img1}`} alt="" />
                  </div>
                </div>
              )}
              {allSlides.slider_img2 && (
                <div
                  className="item thumbnail-item"
                  onClick={() => handleThumbnailClick(1)}
                >
                  <div className="user-img-box thumbnail">
                    <img src={`${allSlides.slider_img2}`} alt="" />
                  </div>
                </div>
              )}
              {allSlides.slider_img3 && (
                <div
                  className="item thumbnail-item"
                  onClick={() => handleThumbnailClick(2)}
                >
                  <div className="user-img-box thumbnail">
                    <img src={`${allSlides.slider_img3}`} alt="" />
                  </div>
                </div>
              )}
            </OwlCarousel>
          </>
        )}
      </div>
    </>
  );
}

export default OwlSliderSection;
