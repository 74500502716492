import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Welcome() {
  const [FormList, getVideoChatFormData] = useState([]);
  const userId = localStorage.getItem("userId");

  const navigate = useNavigate();

  // const [totalOrderCount, getTotalOrderCount] = useState(null);
  // const API_URL = `${process.env.REACT_APP_API_URL}/svconsultation/list?user_id=${userId}&type=singlevacationconsulatant`;

  const [waitingCount, setWaitingCount] = useState(0);
  const [completeCount, setCompleteCount] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalNumberOfVideoChats, setTotalNumberOfVideoChats] = useState(0);

  const [datas, setLatestData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responsevideochat = await axios.get(
          `${process.env.REACT_APP_API_URL}/svconsultation/list/?user_id=${userId}&type=videochat`
        );
        const data = responsevideochat.data.data.data;
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/svconsultation/list/?user_id=${userId}&type=Singlevacationconsulatant`
        );
        const responsesvc = response.data.data.data;

        if (responsesvc.length > 0) {
          // Find the entry with the highest ID
          const latestEntry = responsesvc.reduce((latest, current) => {
            return current.id > latest.id ? current : latest;
          });

          setLatestData(latestEntry);
        }

        const totalNumberOfVideoChats = data.filter(
          (item) => item?.MeetingDetail?.id
        );
        // Filter and count items with status "waiting"
        const waitingItems = data.filter(
          (item) => item?.MeetingDetail?.status === "waiting"
        );
        const completeItems = data.filter(
          (item) => item?.MeetingDetail?.status === "complete"
        );
        const total = data.reduce(
          (sum, item) => sum + parseFloat(item.amount),
          0
        );
        setTotalCost(total);
        setTotalNumberOfVideoChats(totalNumberOfVideoChats.length);
        setWaitingCount(waitingItems.length);
        setCompleteCount(completeItems.length);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // const fetchVideoChatFormData = () => {
  //   fetch(API_URL)
  //     .then((res) => res.json())
  //     .then((res) => {
  //       //Get length of the data
  //       const totalCount = Object.keys(res["data"]).length;
  //       getTotalOrderCount(totalCount); //Pass length to the

  //       const style = document.createElement("style");
  //       style.type = "text/css";
  //       const css =
  //         totalCount && totalCount > 0
  //           ? `ul.f_link li:first-child { display: block; }` //if length is grater than 0 so it will display the feedback option otherwise it will not display feedback option
  //           : `ul.f_link li:first-child { display: none; }`; // Adjust as necessary for different conditions

  //       style.appendChild(document.createTextNode(css));
  //       document.head.appendChild(style);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user data:", error);
  //       if (error.res.status === 401) {
  //         navigate("/login");
  //       }
  //     })
  //     .finally(() => {
  //       // Any final actions to perform
  //     });
  // };

  // useEffect(() => {
  //   fetchVideoChatFormData();
  // }, []);

  useEffect(() => {
    // Check if 'userId' is not present in localStorage
    if (!localStorage.getItem("userId")) {
      // Redirect to the "/login" page if 'userId' is not present
      window.location.href = "/login";
    }
  }, []); // The empty dependency array ensures that this effect runs once when the component mounts

  return (
    <>
      <section className="page-info-sec">
        <div
          className="bg-fixed"
          style={{ backgroundImage: "url('./img/inner_banner.png')" }}
        ></div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>Member Status</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur male
                </p>
                <p className="page_nav">
                  {" "}
                  <a href="/">Home</a>
                  <span className="mx-2">/</span>
                  <a href="#" className="currentPage">
                    Member Status
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="welcome-sec secpd70">
        <div className="container">
          <div className="shadow-wrap-1">
            <div className="row gap-40">
              <div className="col-12">
                <h2 className="title-30 mb-3">
                  Hello {localStorage.getItem("Username")},
                </h2>
              </div>
              <div className="col-1/5">
                <Link to="/updateprofile">
                  <div className="welcome-box">
                    <div className="wb-icon-box">
                      <img src="img/icon-1.png" alt="" />
                    </div>
                    <p>Update Profile</p>
                  </div>
                </Link>
              </div>
              <div className="col-1/5">
                <div className="welcome-box">
                  <div className="wb-icon-box">
                    <img src="img/icon-2.png" alt="" />
                  </div>
                  <p>Update Account</p>
                </div>
              </div>

              <div className="col-1/5">
                <Link to="/videoplaylist">
                  <div className="welcome-box">
                    <div className="wb-icon-box">
                      <img src="img/icon-3.png" alt="" />
                    </div>
                    <p>Video Playlist</p>
                  </div>
                </Link>
              </div>

              <div className="col-1/5">
                <Link to="/uploadphotos">
                  <div className="welcome-box">
                    <div className="wb-icon-box">
                      <img src="img/icon-4.png" alt="" />
                    </div>
                    <p>Upload Photo</p>
                  </div>
                </Link>
              </div>
              <div className="col-1/5">
                <Link to="/ladies_profile">
                  <div className="welcome-box">
                    <div className="wb-icon-box">
                      <img src="img/icon-5.png" alt="" />
                    </div>
                    <p>Profiles of Ladies</p>
                  </div>
                </Link>
              </div>
              <div className="col-1/5">
                <Link to="/video_logs">
                  <div className="welcome-box">
                    <div className="wb-icon-box">
                      <img src="img/icon-6.png" alt="" />
                    </div>
                    <p>Video Chats with Ladies</p>
                  </div>
                </Link>
              </div>
              <div className="col-1/5">
                <Link to="/Singlevacationconsulatant">
                  <div className="welcome-box">
                    <div className="wb-icon-box">
                      <img src="img/icon-7.png" alt="" />
                    </div>
                    <p>Singles Vacation Consultation</p>
                  </div>
                </Link>
              </div>
              {totalNumberOfVideoChats > 0 && (
                <div className="col-1/5">
                  <Link to="/feedback">
                    <div className="welcome-box">
                      <div className="wb-icon-box">
                        <img src="img/icon-8.png" alt="" />
                      </div>
                      <p>Customer Feedback</p>
                    </div>
                  </Link>
                </div>
              )}

              <div className="col-1/5">
                <Link to="/deactivateaccount">
                  <div className="welcome-box">
                    <div className="wb-icon-box">
                      <img src="img/icon-9.png" alt="" />
                    </div>
                    <p>Deactivate Account</p>
                  </div>
                </Link>
              </div>
              <div className="col-1/5">
                <Link to="/contactus">
                  <div className="welcome-box">
                    <div className="wb-icon-box">
                      <img src="img/icon-10.png" alt="" />
                    </div>
                    <p>Contact Us</p>
                  </div>
                </Link>
              </div>
              <div className="col-1/5">
                <Link to="/resetpassword">
                  <div className="welcome-box">
                    <div className="wb-icon-box">
                      <img src="img/icon-2.png" alt="" />
                    </div>
                    <p>Reset Password</p>
                  </div>
                </Link>
              </div>

              <div className="col-1/5">
                <Link to="/profile_image_gallery">
                  <div className="welcome-box">
                    <div className="wb-icon-box">
                      <img src="img/icon-4.png" alt="" />
                    </div>
                    <p>Profile Image Gallery</p>
                  </div>
                </Link>
              </div>
              {/* <div className="col-1/5">
          <Link to="/myvideo-chats">
            <div className="welcome-box">
              <div className="wb-icon-box">
              <img src="img/icon-3.png" alt=""/>
              </div>
              <p>My Video Chat</p>
            </div>
            </Link>
          </div> */}
              {/* <div className="col-1/5">
          <Link to="/myconsultation">
            <div className="welcome-box">
              <div className="wb-icon-box">
              <img src="img/icon-3.png" alt=""/>
              </div>
              <p>My Video Consultation</p>
            </div>
            </Link>
          </div> */}
              <div className="col-1/5">
                <Link to="/user_order">
                  <div className="welcome-box">
                    <div className="wb-icon-box">
                      <img src="img/icon-2.png" alt="" />
                    </div>
                    <p>View Orders</p>
                  </div>
                </Link>
              </div>
              <div className="col-12">
                <hr className="form-divider" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h2 className="title-30 mb-3">Your Status:</h2>
              </div>
              <div className="col-xl-6 mb-4">
                <div className="img-left-box">
                  <div className="leftimg-wrap">
                    <img src="./img/status-1.png" alt="" />
                  </div>
                  <div className="ilb-content">
                    <ul>
                      <li>Video Chat with Ladies: {completeCount}</li>
                      <li>Video Chat Left: {waitingCount}</li>
                      <li>Date/Time Schedule: 2/06/2023, 04:23:15 PM</li>
                      <li>TIme Zone: (GMT+1)</li>
                      <li>
                        Total Number of Video Chats: {totalNumberOfVideoChats}
                      </li>
                      <li>Total Cost: ${totalCost.toFixed(2)}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 mb-4">
                <div className="img-left-box">
                  <div className="leftimg-wrap">
                    <img src="./img/status-2.png" alt="" />
                  </div>
                  <div className="ilb-content">
                    <ul>
                      <li>Single Vacation Consultation: Booked</li>
                      <li>
                        Date/Time Schedule: {datas?.meetingDate},{" "}
                        {datas?.meetingTime}
                      </li>
                      <li>TIme Zone: (GMT+1)</li>
                      <li>Time Length: {datas?.consultation_time} minutes</li>
                      <li>Total Cost: ${datas?.amount}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Welcome;
