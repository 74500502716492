import { useState, useEffect } from 'react';

import axios from 'axios';
function ResetPassword(){
    const [password,setpassword] = useState("");
    const [newpassword,setNewpassword] = useState("");
    const [id, setID] = localStorage.getItem('userId');
    const [errors, setErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    // useEffect(() => {
    //     setID(localStorage.getItem('userId'))
    // }, []);
    const handleSubmit = async(e)=>{
        e.preventDefault();

        // Validate form fields
        const validationErrors = {};
        if (!password) {
            validationErrors.password = "Password is required.";
            //alert("Password is required");
          } else if (password.length < 12) {
            validationErrors.password = "Password must be at least 6 characters long.";
           // alert("Password must be at least 12 characters long");
          }
          if (!newpassword) {
            validationErrors.newpassword = "New Password is required.";
            //alert("NewPassword is required");
          } else if (newpassword.length < 12) {
            validationErrors.newpassword = "New Password must be at least 6 characters long.";
           // alert("NewPassword must be at least 12 characters long");
          }
          
          // Display error messages, if any
            setErrors(validationErrors);

            if(Object.keys(validationErrors).length === 0){
                setFormSubmitted(false);
                const id = localStorage.getItem('userId');
                const pwd_data = {password,newpassword,id}; 
                const TOKEN = localStorage.getItem('adminToken');
               
                try{
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/resetpassowrd`, pwd_data, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${TOKEN}`,
                          },      
                      });
                      console.log('Form response', response);
                      //alert(pwd_data);
                      setFormSubmitted(true);

                }catch(error){
                    console.error('Error during Password Reset:', error);
                   // alert(pwd_data);
                    // Handle error or display error message
                    setFormSubmitted(false);
                    setErrors({ form: "An error occurred during resetting Password. Please try again later." });

                }
            }
    }
    return(
        <>
        <div className="contant"> 
       
        <section className="LoginMain">
            <div className="loginIn">
                <div className="loginBg loginPageBg" style={{backgroundImage:"url('./img/form-left-img.png')"}}></div>
                <div className="loginHalf">
                <div className="loginForm">
                    <div className="LoginTitle">
                    <h1>Reset Password</h1>
                    <p>Your new password must be different from previous user password</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                    <div className="mb-3 mb-md-4">
                        <label className="form-label" >New Password<span className="required">*</span></label>
                        <input type="password" name="password" placeholder="New Password" value={password} onChange={(e)=>setpassword(e.target.value)}   className="form-control"/>
                        <p className="small_info">Limit of 12 characters (imcluding a lowercase letter, uppercase letter, number, and symbol )</p>
                        {errors.password && <p className="text-danger">{errors.password}</p>}
                    </div>
                    <div className="mb-3 mb-md-4">
                        <label className="form-label" >Verify New Password<span className="required">*</span></label>
                        <input type="password" name="confirmpassword" value={newpassword} onChange={(e)=>setNewpassword(e.target.value)}  placeholder="Verify New Password" className="form-control"/>
                        {errors.newpassword && <p className="text-danger">{errors.newpassword}</p>}
                    </div>
                    <input type="hidden" name="user_id" value={localStorage.getItem('userId')}></input>
                    <button type="submit" className="btn-1">Reset Password</button>
                    </form>
                    {formSubmitted && (
                  <div className="alert alert-success mt-3" role="alert">
                    Password Updated successful! You may now log in using your new credentials.
                  </div>
                )}
                {errors.form && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {errors.form}
                  </div>
                )}
                </div>
                
                </div>
            </div>
        </section>
        
        </div>
        </>
    );

}
export default ResetPassword