import React, { useState, useEffect } from "react";
import AdminNavigation from "../AdminNavigation";

// import DefaultPagination from "../Pagination";
import DeleteGift from "./DeleteGift";
import AddGift from "./AddGift";
import EditGift from "./EditGift";
import commonFunction from "../common/commonFunction";

const API_URL = `${process.env.REACT_APP_API_URL}/gifts/list`;

const GiftList = () => {
  const TOKEN = localStorage.getItem("adminToken");
  const [giftList, setGiftList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const [dialogState, setDialogState] = useState({
    add: false,
    edit: false,
    delete: false,
    deletingGiftId: null,
    editingGiftId: null,
    giftData: null,
  });

  useEffect(() => {
    const response = commonFunction.fetchCategoryData();
    response.then((data) => {
      setCategories(data.data);
    });
  }, []);

  const getCategoryName = (categoryId) => {
    if (categories?.length === 0) {
      return "Loading...";
    }

    const category = categories.find((cat) => cat.id === parseInt(categoryId));
    return category ? category.title : "Unknown";
  };

  useEffect(() => {
    fetchGiftData();
  }, []);

  const fetchGiftData = () => {
    fetch(API_URL, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setGiftList(res["data"]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  };

  const handleAction = (action, id, giftData) => {
    if (action === "add") {
      setDialogState({ ...dialogState, add: true });
    } else if (action === "edit") {
      setDialogState({
        ...dialogState,
        edit: true,
        editingGiftId: id,
        giftData: giftData,
      }); // Pass categoryData here
    } else if (action === "delete") {
      setDialogState({ ...dialogState, delete: true, deletingGiftId: id });
    }
  };

  const handleCloseDialog = (dialog) => {
    setDialogState({ ...dialogState, [dialog]: false });
  };

  return (
    <>
      <div id="wrapper" className="toggled">
        <AdminNavigation />
        <div className="container-fluid ">
          <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
            <h1 className="h3 mb-0 text-gray-800 fontBold">Gifts</h1>
            <a>
              <span className="text">
                <button
                  className="btn btn-primary"
                  onClick={() => handleAction("add")}
                >
                  + Add Gift
                </button>
              </span>
            </a>
          </div>

          <div className="card shadow mb-4">
            <div className="card-body">
              <div
                className="table-responsive"
                style={{ overflowX: "inherit" }}
              >
                <div className="row">
                  <table
                    className="table table-bordered"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th scope="col" width="5%">
                          No
                        </th>
                        <th scope="10%">Title</th>
                        <th scope="10%">Description</th>
                        <th scope="10%">Image</th>
                        <th scope="10%">Category</th>
                        <th scope="10%">Price</th>
                        <th scope="col">Status</th>
                        <th width="10%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading && giftList && giftList?.length > 0 ? (
                        giftList.map((gift, index) => (
                          <tr key={index}>
                            <td scope="row">{index + 1}</td>
                            <td>{gift.title}</td>
                            <td>{gift.description}</td>
                            <td style={{ width: "52px", height: "52px" }}>
                              {gift.image ? (
                                <div className="bordered-img-table">
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}/${gift.image}`}
                                    alt={gift.title}
                                    className="table-img"
                                  />
                                </div>
                              ) : (
                                "--"
                              )}
                            </td>
                            <td>{getCategoryName(gift.categoryId)}</td>
                            <td>{gift.price}</td>
                            <td>{gift.status ? "Active" : "Inactive"}</td>
                            <td>
                              <a
                                className="actionIcon"
                                title="Edit"
                                onClick={() =>
                                  handleAction("edit", gift.id, gift)
                                }
                              >
                                <img
                                  src="/img/EditIcon.png"
                                  alt="Edit"
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                              <a
                                className="actionIcon"
                                title="Delete"
                                onClick={() => handleAction("delete", gift.id)}
                              >
                                <img
                                  src="/img/Bin.png"
                                  alt="Delete"
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="noRecordFound">
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddGift
        open={dialogState.add}
        onClose={() => handleCloseDialog("add")}
      />

      <EditGift
        open={dialogState.edit}
        onClose={() => handleCloseDialog("edit")}
        giftId={dialogState.editingGiftId}
        giftData={dialogState.giftData}
      />

      <DeleteGift
        open={dialogState.delete}
        onClose={() => handleCloseDialog("delete")}
        giftId={dialogState.deletingGiftId}
      />
    </>
  );
};

export default GiftList;
