import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded";
import FormData from 'form-data';
 

function LadiesProfile() {
  const [pageContent, setPageContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ladieslisting, setLadiesListingData] = useState([]);
  const filterParameter = 'gender';

//consultation Time for the video call
const [consultation_time_for_call,setConsultationTime]= useState('');

  /**
   * Custom Filters section 
   */
  const [filterKey, setFilterKey] = useState('');
  const [sortKey, setSortKey] = useState('');
  

  const [favorites, setFavorites] = useState([]); 

  const [favoritessuccessmsg, setFavoritesSuccess] = useState(''); 
  const navigate = useNavigate();

  const [FavListdataById, setFavouritrProfileDataById] = useState('');

/**
 * Zoom Integration for video call
 */
const client = ZoomMtgEmbedded.createClient();

var authEndpoint = "https://zoomsdk-sign-generator-express.onrender.com/";
  var sdkKey = "8SN3hNZ8RHmEo_hhBvPpFA";
  var meetingNumber = "7677432145";
  var passWord = "6Qm3Hq";
  var role = 0;
  var userName = "gorank";
  var userEmail = "gws.gorank@gmail.com";
  var registrantToken = "";
  var zakToken = "";
  var duration = consultation_time_for_call; // Set the duration in minutes


// const getSignature = async (e) => {
//   e.preventDefault();

//   try {
//     const response = await fetch(authEndpoint, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({
//         meetingNumber: meetingNumber,
//         role: role,
//         duration: '1',
//       }),
//     });

//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }

//     const responseData = await response.json();
//     startMeeting(responseData.signature);
//   } catch (error) {
//     console.error('Error fetching signature:', error);
//   }
// };
let video_calluserID = localStorage.getItem('userId');
const AUTHTOKEN = localStorage.getItem('adminToken');

const getSignature = async (selected_ladies_id,ladiesListData_email, e) => {
  // navigate('/Singlevacationconsulatant');
  navigate(`/Singlevacationconsulatant`, {
    state: { selected_ladies_id, ladiesListData_email },
  });
  // const formData = new FormData();
  // if (e) {
  //   e.preventDefault();
  // }


  // const video_calluserID = localStorage.getItem('userId');
  // const AUTHTOKEN = localStorage.getItem('adminToken');
  // //console.log(AUTHTOKEN);
  // const lid = '%23FCW_' + selected_ladies_id;
  // formData.append(video_calluserID,lid); 
  // let checkExistingUrl = `${process.env.REACT_APP_API_URL}/svconsultation/checkexisting/?user_id=${video_calluserID}&ladies_id=${lid}`;
  // //alert(checkExistingUrl);
  // try {
   
  //   let checkExistingResponse = await axios.get(checkExistingUrl, {
  //     headers: { Authorization: `Bearer ${AUTHTOKEN}`,
  //    },
  //   //  params: formData, 
  //   });
  //   console.log(checkExistingResponse,"checkExistingResponsecheckExistingResponsecheckExistingResponse");

  //   // console.log(checkExistingResponse, "I am a response");
  //  console.log(checkExistingResponse.data.status,"Resp status")

  //   if (checkExistingResponse.data.status === 1) {
  //     // Assuming the checkexisting API returns JSON data
  //     const checkExistingData = checkExistingResponse.data.data;
  //     //console.log(checkExistingData,"I am else block!!!!!!!!!!");
  //     //console.log(checkExistingResponse.data,"I am resp block!!!!!!!!!!");
  //     setConsultationTime(checkExistingResponse.data.data.consultation_time);
  //     // Check if the data indicates that the user and lady exist
  //     if (checkExistingResponse.data.status === 1) {
  //       var authEndpoint = "https://zoomsdk-sign-generator-express.onrender.com";
  //       var sdkKey = "8SN3hNZ8RHmEo_hhBvPpFA";
  //       var meetingNumber = "7677432145";
  //       var passWord = "6Qm3Hq";
  //       var role = 0;
  //       var userName = "gorank";
  //       var userEmail = "gws.gorank@gmail.com";
  //       var registrantToken = "";
  //       var zakToken = "";
  //       var duration = consultation_time_for_call; // Set the duration in minutes
  //       const authResponse = await fetch(authEndpoint, {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify({
  //           meetingNumber: meetingNumber,
  //           role: role,
  //           duration: '1',
  //         }),
  //       });

       

  //       if (!authResponse.ok) {
  //         throw new Error(`HTTP error! Status: ${authResponse.status}`);
  //       }

  //       const authData = await authResponse.json();
  //       //console.log(authData,"authData");
  //       //console.log(authData.signature);
  //       startMeeting(authData.signature);
        
  //     } else {
  //       // Redirect to another page if the user and lady do not exist
  //      //window.location.href = '/Singlevacationconsulatant';
  //     }
  //   } else {
     
  //     throw new Error(`HTTP error! Status: ${checkExistingResponse.status}`);
  //   }
  // } catch (error) {
  //  // console.log(error.response.data.status, 'error');
  //   if (error.response && error.response.data.status === 0) {
    
  //     // navigate('/Singlevacationconsulatant');
  //     navigate(`/Singlevacationconsulatant`, {
  //       state: { selected_ladies_id, ladiesListData_email },
  //     });
  //   } 
  // }
};


// const getSignature = async (selected_ladies_id, e) => {
//   try {
//     const video_calluserID = localStorage.getItem('userId');
//     const AUTHTOKEN = localStorage.getItem('adminToken');
//     const lid = '%23FCW_' + selected_ladies_id;

//     const checkExistingUrl = `https://spnode4002.elb.cisinlive.com/svconsultation/checkexisting/?user_id=${video_calluserID}&ladies_id=${lid}`;

//     const myHeaders = new Headers();
//     myHeaders.append("Authorization", `Bearer ${AUTHTOKEN}`);

//     const requestOptions = {
//       method: "GET",
//       headers: myHeaders,
//       redirect: "follow"
//     };

//     const response = await fetch(checkExistingUrl, requestOptions);
//     const result = await response.text();

//     console.log(result);
//   } catch (error) {
//     console.error('Error:', error);
//   }

// }

// useEffect(() => {
//   getSignature();
// }, []);

function startMeeting(signature) {
  //console.log(signature,"INSIDE MEEEEEETING GG")
  let meetingSDKElement = document.getElementById("meetingSDKElement");

  client
    .init({
      zoomAppRoot: meetingSDKElement,
      language: "en-US",
      patchJsMedia: true,
    })
    .then(() => {
      client
        .join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          password: passWord,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
        })
        .then(() => {
          console.log("joined successfully");

          // Open the Zoom meeting in a new window
         // Set a timer to end the meeting after the specified duration
         setTimeout(() => {
          endZoomMeeting();
        }, duration * 60 * 1000); // Convert duration to milliseconds
      })
        .catch((error) => {
          console.log(error, "Meeting having error");
          handleJoinMeetingError(error);
        });
    })
    .catch((error) => {
      console.log(error, "Meeting having error in catch");
    });
}
function endZoomMeeting() {
  //alert("End metting!!" + duration);
  ///VideoChatForm
  // Redirect to /VideoChatForm
  window.location.href = '/VideoChatForm';
}

function openZoomMeetingInNewWindow() {
  // Customize window features if needed (e.g., width, height, etc.)
  const windowFeatures = "width=800,height=600,resizable=yes,scrollbars=yes";

  // Construct the Zoom meeting URL
 // const zoomMeetingURL = `https://zoom.us/wc/${meetingNumber}/join?prefer=1&un=${encodeURIComponent(userName)}&pwd=${passWord}&role=${role}`;

  // Open the Zoom meeting in a new window
 // window.open(zoomMeetingURL, "_blank", windowFeatures);
}

function handleJoinMeetingError(error) {
  // Log the error details
  console.error('Error joining the meeting:', error);

  // Check the error type and provide user-friendly feedback
  if (error.type === 'JOIN_MEETING_FAILED') {
    //alert(`Failed to join the meeting. Reason: ${error.reason} (Error Code: ${error.errorCode})`);
  } else {
   // alert('An unexpected error occurred while joining the meeting. Please try again.');
  }
}

/**
 * Zoom Integration for video call ends here
 */

  const handleSortChange = (event) => {
    setSortKey(event.target.value);
    fetchLadiesData();
   // console.log(event.target.value,"Target values");
  };
  const handleFilterChange = (event) => {
    setFilterKey(event.target.value);
    fetchLadiesData();
    //console.log(event.target.value,"setFilterKey values");
  };
  useEffect(()=>{
    const fetchPageContent = async()=>{
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/slug/ladiesprofile`);
        setPageContent(response.data.data['0']);
        
      } catch (error) {
        //console.error('Error fetching page content:', error);
      }finally {
        setLoading(false); // Set loading to false whether the request succeeds or fails
      }
    
    }
    fetchPageContent();
    },[]);
    
  const fetchLadiesData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/getfiltereduser/?filter_key=${filterKey}&sort_key=${sortKey}`, {
        [filterParameter]: 'female'
      });
      //console.log(response,"responseresponseresponseresponse")
     
      setLadiesListingData(response.data.data);
      setLoading(false);
    } catch (error) {
      //console.error('ERROR:', error);
      console.error('Error fetching user data:', error);
            if (error.response.status === 401) {
              navigate("/login");
            }
    }
  };

  useEffect(() => {
    fetchLadiesData();
  }, [filterKey, sortKey]);//Pass filter keys in our useeffect hook so based on the values it filters the data


  //console.log(ladieslisting,"======================XXXXXXXXXXXXXXXXX=================");

  // const getAllFavProfile = async (Profile_ID) => {
  //   try {
  //     const TOKEN = localStorage.getItem('adminToken');
  //     // console.log('TOKEN====',TOKEN);
  //     // const Profile_ID = 31;
  //     //console.log(Profile_ID,"PROFILE DATA");
  //     const allfav_listing = await axios.get(`${process.env.REACT_APP_API_URL}/favorite/${Profile_ID}`, {
  //       headers: {
  //         Authorization: `Bearer ${TOKEN}`,
  //       }
  //     });
  //  console.log("FAVVVVVV", allfav_listing.data.data, "vvvvvvvvvvvvvvvvvvv");
  //   } catch (error) {
  //     // Handle errors here
  //   }
  // }

  

  // useEffect(()=>{getAllFavProfile()},[]);



  if (loading) {
    // Render a loading overlay with the "Loading..." message
    return (
      <div className="loading-overlay">
        {/* <div className="loading-content">Loading...</div> */}
        <img src='/img/loader.gif'></img>
      </div>
    );
  }
    if (!pageContent) {
      return null; // Or render some loading/error message
    }
    const style = {
      backgroundImage: `url('${pageContent.bannerurl}')`,
      // Add other CSS properties as needed
    };

  //Getting loggedin user ID
    const userID = localStorage.getItem('userId');
    const TOKEN = localStorage.getItem('adminToken');
    //console.log('TOKEN====',TOKEN);
    
    
    //Hindel Single ladies profile
    const handelSingleLadiesProfile = (ladiesId)=>{
      //console.log("ladies=====>",ladiesId);
      navigate(`/singleLadiesProfile/${ladiesId}`);

    }

    /**
     *  <Route path="/singleLadiesProfile/:ladiesid" element={<SingleLadiesProfile />} />
     * pass dynamic data by :and our field name in this code ladiesid is dynamic
     */


    /**
     * Function for getting all favourite profile
     */

     
/**
 * Functionality for calculating age based on the given birth date starts here
 *
*/

    const calculate_age = (dob1) => {
      var today = new Date();
      var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
      {
          age_now--;
      }
      console.log(age_now);
      return age_now;
    }
   
    
/**
 * Functionality for calculating age based on the given birth date ends here
 *
*/

/**
 * 
 * Functionality for getting likes of ladies profile
 */
const handleFavProfile = async (ladiesID) => {
  //console.log("LadiesID", ladiesID);
  let user_id = userID; // Assuming userID is defined somewhere in your code
  //console.log(user_id, "user_id");

  try {
    const favresp = await axios.post(
      `${process.env.REACT_APP_API_URL}/favorite/managefavorite`,
      {
        user_id: user_id,
        ladies_user_id: ladiesID,
      },
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    );
    //console.log("Favresp", favresp);
    fetchLadiesData();
  } catch (error) {
    console.log(error);
  }
};

/**
 * 
 * Functionality for getting likes of ladies profile
 */


const handelgift = (ladiesId) => {
  localStorage.setItem("ladiesId", ladiesId);
  navigate(`/giftcategory/${ladiesId}`);
};



return (
    <>
    
      <section className="page-info-sec">
    
    <div className="bg-fixed"  style={style}>
    </div>
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col">
          <div className="page-info-block">
            <h1>{pageContent ? pageContent.bannertitle : 'Ladies'}</h1>
            <p>{pageContent ? pageContent.bannerdesc : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur malesuada risus quis ligula pellentesque fermentum. Aenean vel ex finibus, lacinia lacus non, sodales dolor. Phasellus eget turpis neque. Sed aliquet nec felis non faucibus. Quisque euliy tristique elit, sit amet laoreet neque. Vestibulum aliquam at augue at dictum. Praes ent interdum blandit elit, nec ullamcorper odio.'}</p>
            <p className="page_nav"> <a href="/">Home</a>/<a href="#" className="currentPage">{pageContent ? pageContent.bannertitle : 'Ladies'}</a></p>
          </div>
        </div>
      </div>
    </div>
    </section>
      <section className="lady-profiles-sec secpd70">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            {favoritessuccessmsg && (<div className="alert alert-success mt-3" role="alert"> {favoritessuccessmsg}</div>)}
              <div className="main-from-wrap" id="meetingSDKElement">
                <div className="titleLR-wrap mb-3 mb-md-3">
                  <h2 className="title-30">Short Profile of ladies</h2>
                  <div className="right-options-wrap">
                    {/* <div className="view-by">
                      <label>View by:</label>
                      <select className="form-select">
                        <option value="">18</option>
                        <option value="">20</option>
                        <option value="">22</option>
                        <option value="">24</option>
                        <option value="">26</option>
                      </select>
                    </div> */}
                    <div className="sort-by">
                      <label>Sort by:</label>
                      {/* <select className="form-select">
                        <option value="">Recently Added</option>
                        <option value="">Number of likes</option>
                        <option value="">Sort by Age</option>
                       
                      </select> */}
                       <select className="form-select" name="sort_key" value={sortKey} onChange={handleSortChange}>
                        {/* Add your options based on available sort keys */}
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                        {/* Add more options as needed */}
                      </select>
                      <select className="form-select ms-2" name="filter_key" value={filterKey} onChange={handleFilterChange}>
                        {/* Add your options based on available sort keys */}
                        <option value="favorite">Favorite</option>
                        <option value="age">age</option>
                        <option value="id">id</option>
                        {/* Add more options as needed */}
                      </select>
                    </div>
                  </div>
                </div>
                {/* <div className="row lady-profile-wrap">
                  {ladieslisting.map((ladiesListData, key) => (
                    
                    <div className="col-sm-6 col-12 col-xl-4" key={key}>
                      <div className="profile-card">
                        <div className="img-holder">
                       { ladiesListData.profile_pic !== null ? (
                          
                          
                          <img src={`${process.env.REACT_APP_API_URL}${ladiesListData.profile_pic}`} alt="" className="ih_img" />
                        ) : (<img src="./img/lady-2.png" alt="" className="ih_img" />)}
                         <div className="profile-actions">
                         
                         <a href="#" className="icobtn" onClick={() => handleFavProfile(ladiesListData.id)}>
                        
                          <i className={`fa fa-heart${ladiesListData.favorites[0].total_favorite!= 0 ? ' redHeartIcon' : ''}`}>
                            {ladiesListData.favorites && ladiesListData.favorites[0] && ladiesListData.favorites[0].total_favorite!= 0
                              ? ladiesListData.favorites[0].total_favorite
                              : ''}
                          </i>
                        </a>
                        <a href="#" className="icobtn" onClick={(e)=>getSignature(ladiesListData.id,ladiesListData.email,e)}>
                            <i className="fas fa-video"></i>
                        </a>
                          </div>
                        </div>
                        <div className="profile-content">
                          <div className="lady_name">
                            <h3>
                              <a href="#">{ladiesListData.fname}  <small>({calculate_age(ladiesListData.dob)})</small></a>
                            </h3>
                            <span className="ln_right_box">#FCW_{ladiesListData.id} </span>
                          </div>
                          <p>{ladiesListData.address}</p>
                          <div className="btn-wrap">
                            <a  onClick={()=>handelSingleLadiesProfile(ladiesListData.id)} className="btn-1 btn-sm visit_profile">Visit Profile</a>
                            <a onClick={() => handelgift(ladiesListData.id)}
                               className="outline-btn-1 btn-sm">Send Gift to Her</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div> */}
                <div className="row lady-profile-wrap">
                  {ladieslisting.map((ladiesListData, key) => (
                    <div className="col-sm-6 col-12 col-xl-4" key={key}>
                      <div className="profile-card">
                        <div className="img-holder">
                          {ladiesListData.profile_pic ? (
                            <img src={`${process.env.REACT_APP_API_URL}${ladiesListData.profile_pic}`} alt="" className="ih_img" />
                          ) : (
                            <img src="./img/lady-2.png" alt="" className="ih_img" />
                          )}
                          <div className="profile-actions">
                            <a href="#" className="icobtn" onClick={() => handleFavProfile(ladiesListData.id)}>
                              <i className={`fa fa-heart${ladiesListData.favorites && ladiesListData.favorites[0] && ladiesListData.favorites[0].total_favorite !== 0 ? ' redHeartIcon' : ''}`}>
                                {ladiesListData.favorites && ladiesListData.favorites[0] && ladiesListData.favorites[0].total_favorite !== 0
                                  ? ladiesListData.favorites[0].total_favorite
                                  : ''}
                              </i>
                            </a>
                            <a href="#" className="icobtn" onClick={(e) => getSignature(ladiesListData.id, ladiesListData.email, e)}>
                              <i className="fas fa-video"></i>
                            </a>
                          </div>
                        </div>
                        <div className="profile-content">
                          <div className="lady_name">
                            <h3>
                              <a href="#">{ladiesListData.fname} <small>({calculate_age(ladiesListData.dob)})</small></a>
                            </h3>
                            <span className="ln_right_box">#FCW_{ladiesListData.id} </span>
                          </div>
                          <p>{ladiesListData.address}</p>
                          <div className="btn-wrap">
                            <a onClick={() => handelSingleLadiesProfile(ladiesListData.id)} className="btn-1 btn-sm visit_profile">Visit Profile</a>
                            <a onClick={() => handelgift(ladiesListData.id)} className="outline-btn-1 btn-sm">Send Gift to Her</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LadiesProfile;
