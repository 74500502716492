import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import AdminNavigation from './AdminNavigation';

function onChange(value) {
  // console.log("Captcha value:", value);
}

function AddMedia() {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setMediaFiles(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    mediaFiles.forEach((file, index) => {
      formData.append('images', file); // Use 'images' as the key
    });
  
    const TOKEN = localStorage.getItem('adminToken');
  
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/media/add`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'multipart/form-data', // Set the Content-Type header for file uploads
          },
        }
      );
  
      // Check if the response indicates success (you might have a different way of checking it)
      if (res.status === 200) {
        setSuccessMessage('Media Added successfully!');
      } else {
        setSuccessMessage('Media failed to add.');
      }
    } catch (error) {
      console.error('Error:', error);
      setSuccessMessage('Media creation failed.');
    }
  };
  

  return (
    <>
     <div id="wrapper" className="toggled">
        <AdminNavigation />
        <div id="page-content-wrapper">
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-8 mx-auto registration_section">
            {successMessage && (
              <div className="alert alert-success mt-3 text-center" role="alert">
                {successMessage}
              </div>
            )}
            <h4>Add Media</h4>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              {/* Add multiple file input */}
              <div className="mb-3 mt-3">
                <label htmlFor="mediaFiles" className="form-label">
                  Upload Media:
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="mediaFiles"
                  name="mediaFiles"
                  onChange={handleFileChange}
                  multiple // Allow multiple file selection
                  required
                />
              </div>

              <button type="submit" className="btn-1">
                Upload
              </button>
            </form>
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
}

export default AddMedia;
