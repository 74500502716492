import React from "react";
import axios from 'axios';
import {useNavigate } from 'react-router-dom';
function DeactivateAcc(){
    //Getting user ID from the localstorage
   const user_id = localStorage.getItem('userId');
   //Get API URL path from the ENV File.
   const API_URL = `${process.env.REACT_APP_API_URL}/users/deactivate`;
   //Get admin token form the database for the token
   const TOKEN = localStorage.getItem('adminToken');
   //Getting site url with login page link so when a account is deactivated it will redirect to the login page.
   const siteUrl = `${process.env.REACT_APP_SITE_URL}/login`;

   const navigate = useNavigate();
   //Deactivate account functionality
    const HandelSubmitForm =(user_id)=>{
        //console.log("Logged in User Id is " + user_id)
        if (window.confirm('Do You want to permanently delete your account ?')) {
            //https://spnode4002.elb.cisinlive.com/users/deactivate

            try {
                const respone = axios.put(API_URL,{ user_id },{
                    headers: {
                      Authorization: `Bearer ${TOKEN}`
                    }
                  });
                //console.log(respone);
                if(respone){
                    //Clear local storage data
                    localStorage.clear();
                    //Navigate to the login page when response is there.
                    navigate('/login');
                }
                
            } catch (error) {
                
            }
        }
    }

    return(<><section className="page-info-sec">
    <div className="bg-fixed" style={{backgroundImage:"url('./img/inner_banner.png')"}}>
    </div>
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col">
          <div className="page-info-block">
            <h1>Deactivate Account</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur male</p>
            <p className="page_nav"> <a href="/">Home</a><span  className="mx-2">/</span><a href="#" className="currentPage">Deactivate Account</a></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="faq-section my-3">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="faq">
                  <h2>Deactivate Account</h2>
                  <p>When you choose to deactivate your account, please be aware that this action will result in the suspension of all activities associated with your account. Once deactivated, you will no longer have the ability to engage in any actions, including but not limited to logging in, posting content, or accessing any personalized features.</p>
                  <p>It's important to note that account deactivation is a permanent decision, and once initiated, the deactivation cannot be reversed. Your account will essentially be dormant, and you won't be able to reactivate it in the future. This includes the loss of access to any associated data, settings, or personalized information linked to your account.</p>
                  <p>Before proceeding with account deactivation, we recommend reviewing any potential implications on your current subscriptions, services, or ongoing interactions within the platform. It's advisable to ensure that you have saved any essential information or content that you may need in the future, as account deactivation is a comprehensive and irreversible process.</p>
                  <p>If you have any concerns or questions about the deactivation process, please don't hesitate to reach out to our support team. We are here to assist you and provide any necessary information to make this process as smooth as possible.</p>

                  <form >
                      <input type="hidden" name="user_id" value={user_id}></input>
                      <button type="button" onClick={() => HandelSubmitForm(user_id)} name="deactivate_acc" value="deactivate_Acc" className="btn btn-danger btn-sm">Deactivte My Account</button>
                  </form>
                </div>
                </div>
                </div>
                </div>
                </section>  
  </>);
}
export default DeactivateAcc