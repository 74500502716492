import axios from "axios";
import React, { useState, useEffect } from "react";
import  AdminNavigation  from './AdminNavigation';

function AddFaqs(){
    useEffect(() => {
        // Check if 'userId' is not present in localStorage
        if (!localStorage.getItem('userRole') && localStorage.getItem('userRole')!== 1) {
          // Redirect to the "/login" page if 'userId' is not present
          window.location.href = "/login";
        }
      }, []); // The empty dependency array ensures that this effect runs once when the component mounts

const [question,setFaqQuestion] = useState("");
const [answer,setFaqAnswer] = useState("");
const [sort,setSortOrder]= useState("");
const [insertsuccess,setInsertSuccess]= useState("");
const [errormsg,setErrorMsg] = useState("");

// console.log(faqquestion,"faqquestion");
// console.log(faqanswer,"faqanswer");
// console.log(sortorder,"sortorder");

const handelSubmit = async (e)=>{
    e.preventDefault();
if(question === "" || answer === "" || sort === ""){
    setErrorMsg("All Fileds Are Required");
}

   
    const faqData = {question,answer,sort };
    //alert(faqData);
    console.log(faqData);
    
    const TOKEN = localStorage.getItem('adminToken');
    if(TOKEN!='' || TOKEN!=null){
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/faq/add`,faqData, {
                headers: {
                    'Content-Type': 'application/json',
                  Authorization: `Bearer ${TOKEN}`
                },});
            //console.log(resp);
               
                    setInsertSuccess("FAQ Inserted Successfully!!");
                
            
        } catch (error) {
            console.log(error);
            
        }
        
    }else{
        console.log("Token Expired!!");
    }
    

}

return(<>
 <div id="wrapper" className="toggled">
   <AdminNavigation/>
        
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            <div className='container'>
                <div className="row">
                    <div className="col-md-6 mx-auto">
                    {insertsuccess && (
                        <div className="alert alert-success mt-3 text-center" role="alert">
                            {insertsuccess}
                        </div>
                    )}
                    {errormsg && (
                        <div className="alert alert-danger mt-3 text-center" role="alert">
                            {errormsg}
                        </div>
                    )}
                    <form onSubmit={handelSubmit}>
                        <label>FAQ Question:</label>
                    <input type="text" name="question" value={question} onChange={(e)=>setFaqQuestion(e.target.value)} placeholder="Enter FAQ Question" className="form-control mb-2"></input>
                    <label>FAQ Answer:</label>
                    <input type="text" name="answer" value={answer} onChange={(e)=>setFaqAnswer(e.target.value)} placeholder="Enter FAQ Answer" className="form-control mb-2"></input>
                    <label>Sort Order:</label>
                    <input type="text" name="sort" value={sort} className="form-control mb-2" onChange={(e) => setSortOrder(e.target.value)}
placeholder="Enter Sort Order"
                    />
                    <button type="submit" className="btn btn-primary">Add</button>
                </form>
                    </div>
                </div>
               
            </div>
            </div>
            </div>
</>)
}
export default AddFaqs