import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import  AdminNavigation  from './AdminNavigation';
import { useNavigate } from "react-router-dom";
function UpdateAPIURLSettingsData(){
    const[api_url,setAPIURLText]=useState('');
    const [id, setID] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        setID(localStorage.getItem('ID'));
        setAPIURLText(localStorage.getItem('API URL'));
       
       
}, []);
const TOKEN = localStorage.getItem('adminToken');
    console.log(TOKEN);
    const updateSelectedAPIURLSettingData = () => {
     let res =   axios.put(`${process.env.REACT_APP_API_URL}/setting/${id}`, {
        api_url}, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      });
    if(res){
            console.log(res);
           // alert("updated"+res);
           // window.location.href = "/admin/pages";
           navigate("/admin/settings");
        }else{
          console.log(res);
           //alert("failed to update");
        }
    }
    
    return(<>
     <div id="wrapper" className="toggled">
      <AdminNavigation/>
           
           <div id="page-content-wrapper">
               
               <div className="container">
                   <div className="row">
                       <div className="col-md-4 mx-auto"></div>
                   <h2>Update Site URL</h2>
                   <form>
                      
                       <label>Site URL:</label>
                       <textarea name="api_url" id="api_url" className="form-control" placeholder="Enter API URL" value={api_url} onChange = {(e) => setAPIURLText(e.target.value)} required></textarea>
                       <br/>
                       <input type="submit" onClick={updateSelectedAPIURLSettingData} className="btn btn-primary mb-2" id='update_api_url' value="Update API URL"/>
   
                   </form>
       </div>
       </div>
       </div>
       </div>
    </>
    )

}
export default UpdateAPIURLSettingsData