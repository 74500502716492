import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import EyeIcon from './EyeIcon'; // Import your EyeIcon component

function Login({ onCustom }) {
  const [pageContent, setPageContent] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loader
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for showing password
  const navigate = useNavigate();

  const location = useLocation();
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);


  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/slug/login`);
        setPageContent(response?.data?.data['0']);
      } catch (error) {
        console.error('Error fetching page content:', error);
      } finally {
        setLoading(false); // Set loading to false whether the request succeeds or fails
      }
    }
    fetchPageContent();
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError('');
  };

  const handleLogin = async (e) => {
    e.preventDefault();
  
    // const captchaElement = document.getElementById('g-recaptcha-response');
    // const captchaValue = captchaElement ? captchaElement.value : null;
    // if (!captchaValue) {
    //   setError('Please complete the captcha.');
    //   return;
    // }

    if (!captchaValue) {
      setError("Please complete the captcha.");
      recaptchaRef.current.reset(); // Reset the captcha
      return;
    }
  
    if (!email || !password) {
      setError('Please enter both email and password.');
      return;
    }
  
    try {
      console.log("response COMINNG");

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/login`, { email, password });
      console.log(response.error,"response");

      // Check if the response indicates an error
    if (response.data.error) {
      setError(response.data.error); // Set the error state
      return;
    }


  
      const LoggedinuserRole = response.data.data.role;
      localStorage.setItem('userRole', LoggedinuserRole);
  
      if (LoggedinuserRole === 1) {
        localStorage.setItem('loginResponse', JSON.stringify(response));
        localStorage.setItem('adminToken', response.data.tokan);
        onCustom(LoggedinuserRole);
        navigate('/admin/dashboard');
      } else if (LoggedinuserRole === 0) {
        localStorage.setItem('loginResponse', JSON.stringify(response));
        localStorage.setItem('Username', response.data.data.fname);
        localStorage.setItem('userId', response.data.data.id);
        localStorage.setItem('adminToken', response.data.tokan);
        onCustom(LoggedinuserRole);
        navigate('/welcome');
      } else if (LoggedinuserRole === 2) {
        localStorage.setItem('loginResponse', JSON.stringify(response));
        localStorage.setItem('Username', response.data.data.fname);
        localStorage.setItem('userId', response.data.data.id);
        localStorage.setItem('adminToken', response.data.tokan);
        onCustom(LoggedinuserRole);
        navigate('/translatordashboard');
      }
  
    } catch (error) {
      console.log(error.response.error,"catch")
      console.log(error,"simplecatch")

      const errMsg = error?.response?.data?.msg || error.response?.data?.error;
      setError(errMsg);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };
  

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setError(""); // Clear any existing error when captcha is successfully completed
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem('rememberedEmail');
    const storedRememberMe = localStorage.getItem('rememberMe');

    if (storedEmail && storedRememberMe) {
      setEmail(storedEmail);
      setRememberMe(storedRememberMe === 'true');
    }
  }, []);

  const handleRememberMeChange = () => {
    localStorage.setItem('rememberedEmail', email);
    localStorage.setItem('rememberMe', !rememberMe);
    setRememberMe(!rememberMe);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="contant">
      <section className="LoginMain">
        <div className="loginIn">
          <div className="loginBg loginPageBg" style={{ backgroundImage: `url(${pageContent && pageContent.bannerurl})` }}></div>
          <div className="loginHalf">
            <div className="loginForm">
              <div className="LoginTitle">
                <h1>{pageContent ? pageContent.bannertitle : 'Logins'}</h1>
                <p>{pageContent ? pageContent.bannerdesc : ''}</p>
              </div>
              {error && (
                <div className='row'>
                  <div className='col-md-12' id='err'>
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                      {error}
                      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                  </div>
                </div>
              )}
              <form onSubmit={handleLogin}>
                <div className="mb-3 mb-md-4">
                  <label htmlFor="exampleInputEmail1" className="form-label">Email<span className="required">*</span></label>
                  <input type="email" name='email' placeholder="JohnP@xyz.com" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    value={email} onChange={handleEmailChange} />
                </div>
                <div className="mb-3 mb-md-4">
                  <label htmlFor="exampleInputPassword1" className="form-label">Password<span className="required">*</span></label>
                  <div className="password-input">
                    <input type={showPassword ? 'text' : 'password'} name='password' placeholder="*********" className="form-control" id="exampleInputPassword1"
                      value={password} onChange={handlePasswordChange} />
                    <button type="button" className="password-toggle-btn" onClick={togglePasswordVisibility}>
                      <EyeIcon /> {/* Replace EyeIcon with your SVG or component */}
                    </button>
                  </div>
                </div>
                <div className="mb-3 mb-md-4 form-check">
                  <div className="remeberMe">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={rememberMe} onChange={handleRememberMeChange} />
                    <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
                  </div>
                  <Link className="forgotId" to={"/forgetpassword"}>Forgot Password?</Link>
                </div>
                <div className="not-a-robot">
                  <ReCAPTCHA 
                    ref={recaptchaRef}
                    key={location.pathname}
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    onChange={handleCaptchaChange}
                   />
                </div>
                <button type="submit" className="btn-1">Log in</button>
              </form>
            </div>
            <div className="bottom-signin">
              <p>Don’t have an account? <Link to="/register">Signup</Link></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
