import React, { useState, useEffect } from "react";
import AdminNavigation from "../AdminNavigation";
import AddCategory from "./AddCategory";
import DeleteCategory from "./DeleteCategory";
import EditCategory from "./EditCategory";
// import DefaultPagination from "../Pagination";

const API_URL = `${process.env.REACT_APP_API_URL}/giftcategory/list`;

const Category = () => {
  const TOKEN = localStorage.getItem("adminToken");

  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogState, setDialogState] = useState({
    add: false,
    edit: false,
    delete: false,
    deletingCategoryId: null,
    editingCategoryId: null,
    categoryData: null, // Added categoryData state
  });

  useEffect(() => {
    fetchCategoryData();
  }, []);

  const fetchCategoryData = () => {
    fetch(API_URL, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setCategoryList(res["data"]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  };

  const handleAction = (action, id, categoryData) => {
    if (action === "add") {
      setDialogState({ ...dialogState, add: true });
    } else if (action === "edit") {
      setDialogState({
        ...dialogState,
        edit: true,
        editingCategoryId: id,
        categoryData: categoryData,
      }); // Pass categoryData here
    } else if (action === "delete") {
      setDialogState({ ...dialogState, delete: true, deletingCategoryId: id });
    }
  };

  const handleCloseDialog = (dialog) => {
    setDialogState({ ...dialogState, [dialog]: false });
  };

  return (
    <>
      <div id="wrapper" className="toggled">
        <AdminNavigation />
        <div className="container-fluid ">
          <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
            <h1 className="h3 mb-0 text-gray-800 fontBold">
              Gift Categories List
            </h1>
            <a>
              <span className="text">
                <button
                  className="btn btn-primary"
                  onClick={() => handleAction("add")}
                >
                  + Add Category
                </button>
              </span>
            </a>
          </div>

          <div className="card shadow mb-4">
            <div className="card-body">
              <div
                className="table-responsive"
                style={{ overflowX: "inherit" }}
              >
                <div className="row">
                  <table
                    className="table table-bordered"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th scope="col" width="5%">
                          No
                        </th>
                        <th scope="10%">Title</th>
                        <th scope="10%">Description</th>
                        <th scope="10%">Image</th>
                        <th scope="col">Status</th>
                        <th width="10%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading && categoryList?.length > 0 ? (
                        categoryList.map((category, index) => (
                          <tr key={index}>
                            <td scope="row">{index + 1}</td>
                            <td>{category.title}</td>
                            <td>{category.description}</td>
                            <td style={{ width: "52px", height: "52px" }}>
                              {category.image ? (
                                <div className="bordered-img-table">
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}/${category.image}`}
                                    alt={category.title}
                                    className="table-img"
                                  />
                                </div>
                              ) : (
                                "--"
                              )}
                            </td>
                            <td>{category.status ? "Active" : "Inactive"}</td>
                            <td>
                              <a
                                className="actionIcon"
                                title="Edit"
                                onClick={
                                  () =>
                                    handleAction("edit", category.id, category) // Pass categoryData here
                                }
                              >
                                <img
                                  src="/img/EditIcon.png"
                                  alt="Edit"
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                              <a
                                className="actionIcon"
                                title="Delete"
                                onClick={() =>
                                  handleAction("delete", category.id)
                                }
                              >
                                <img
                                  src="/img/Bin.png"
                                  alt="Delete"
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="noRecordFound">
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddCategory
        open={dialogState.add}
        onClose={() => handleCloseDialog("add")}
      />

      <EditCategory
        open={dialogState.edit}
        onClose={() => handleCloseDialog("edit")}
        categoryId={dialogState.editingCategoryId}
        categoryData={dialogState.categoryData} // Pass categoryData here
      />

      <DeleteCategory
        open={dialogState.delete}
        onClose={() => handleCloseDialog("delete")}
        categoryId={dialogState.deletingCategoryId}
      />
    </>
  );
};

export default Category;
