import { Link } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useState } from 'react';
import  AdminNavigation  from './AdminNavigation';
function Translator(){
    const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState(""); // New state for gender
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [UserRole,setUserRole]=useState(2);
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const validationErrors = {};
    if (!fname) {
      validationErrors.fname = "First name is required.";
    }
    if (!lname) {
      validationErrors.lname = "Last name is required.";
    }
    if (!dob) {
      validationErrors.dob = "Date of Birth is required.";
    }
    if (!email) {
      validationErrors.email = "Email address is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = "Invalid email address.";
    }
    if (!password) {
      validationErrors.password = "Password is required.";
    } else if (password.length < 12) {
      validationErrors.password = "Password must be at least 12 characters long.";
    } else if (!/[a-z]/.test(password)) {
      validationErrors.password = "Password must contain at least one lowercase letter.";
    } else if (!/[A-Z]/.test(password)) {
      validationErrors.password = "Password must contain at least one uppercase letter.";
    } else if (!/[0-9]/.test(password)) {
      validationErrors.password = "Password must contain at least one number.";
    } else if (!/[$@!%*?&]/.test(password)) {
      validationErrors.password = "Password must contain at least one symbol ($, @, !, %, *, ?, or &).";
    }
    if(!gender){
      validationErrors.gender = "Gender field is required.";
    }

    // Display error messages, if any
    setErrors(validationErrors);
    const TOKEN = localStorage.getItem('adminToken');
    const sendMail = async (username, email,password) => {
        try {
          const mailData = {
            mailid: email,
            subject: 'Welcome to FCW',
            html: `Hello, ${username},<br/><br/>Thank you for registering on Foreign Chat Web website.
            <br/><br/> Your username is:  ${username} <br/><br/> Your password is: ${password}<br/><br/> 
            <a href='${process.env.REACT_APP_SITE_URL}/login'>Please login to your new account.</a><br/><br/>
            Thanks`,
          };
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/svconsultation/sendCustomMail`, mailData, {
            headers: {
                Authorization: `Bearer ${TOKEN}`,
              },
          });
    
        } catch (error) {
         // console.error('Error sending mail:', error);
          // Handle error sending email
        }
      };


    // If there are no validation errors, proceed with form submission
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      setFormSubmitted(false);
        const role = '2';
      const user = { fname, lname, email, password, dob, username,gender,role };

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/registration`, user, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'application/json'
          },
        });
        setLoading(false);
        setFormSubmitted(true);
        if (response.data.status === 0 && response.data.result.includes("Email id already exist!")) {
          setLoading(false);
          setErrors({ email: "Email already exists. Please use a different email address." });
          return;
      }
        if(response.status === 200){
            await sendMail(user.username, user.email,user.password);
        }
        // Handle success or redirect to a success page
      } catch (error) {
        console.error('Error during registration:', error);
        setLoading(false);
        // Handle error or display error message
        setFormSubmitted(false);
        setErrors({ form: "An error occurred during registration. Please try again later." });
      }
    }
  };
return(
<>
<div id="wrapper" className="toggled">
   <AdminNavigation/>
        
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            <div className='container'>
                <h4>Add Translator</h4>
            <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="mb-3 mb-md-3 col-12 col-md-6">
                      <label className="form-label">First Name<span className="required">*</span></label>
                      <input type="text" name="fname" id="fname" placeholder="David" className="form-control" value={fname} onChange={(e) => setFname(e.target.value)} />
                      {errors.fname && <p className="text-danger">{errors.fname}</p>}
                    </div>
                    <div className="mb-3 mb-md-3 col-12 col-md-6">
                      <label className="form-label">Last Name<span className="required">*</span></label>
                      <input type="text" placeholder="Parker" className="form-control" name="lname" value={lname} onChange={(e) => setLname(e.target.value)} />
                      {errors.lname && <p className="text-danger">{errors.lname}</p>}
                    </div>
                    <div className="mb-3 mb-md-3 col-12">
                      <label className="form-label">Date of Birth<span className="required">*</span></label>
                      <input type="date" placeholder="DD/MM/YYYY" className="form-control" name="dob" value={dob} onChange={(e) => setDob(e.target.value)} />
                      {errors.dob && <p className="text-danger">{errors.dob}</p>}
                    </div>
                    <div className="mb-3 mb-md-3 col-12">
                      <label className="form-label">Email Address<span className="required">*</span></label>
                      <input type="email" placeholder="DavidP@xyz.com" className="form-control" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                      {errors.email && <p className="text-danger">{errors.email}</p>}
                    </div>
                    <div className="mb-3 mb-md-3 col-12">
                      <label className="form-label">Gender</label>
                      <select className="form-control" name="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        
                      </select>
                      {errors.gender && <p className="text-danger">{errors.gender}</p>}
                    </div>
                    <div className="mb-3 mb-md-3 col-12">
                  <label className="form-label" >Username (optional)</label>
                  <input type="text" placeholder="DavidP@xyz.com" className="form-control" name='username' value={username} onChange={(e)=>setUsername(e.target.value)} />
                  <p className="small_info">Limit of 15 characters (including a lowercase letter, uppercase letter, number, and symbol )</p>
                </div>
                <div className="mb-3 mb-md-3 col-12">
                  <label for="exampleInputPassword1" className="form-label">Password<span className="required">*</span></label>
                  <input type="password" placeholder="*********" className="form-control" id="exampleInputPassword1" name='password' value={password} onChange={(e)=>setPassword(e.target.value)} />
                  <p className="small_info">Limit of 12 characters (including a lowercase letter, uppercase letter, number, and symbol )</p>
                  {errors.password && <p className="text-danger">{errors.password}</p>}
                </div>
                  </div>
                  
                  <button type="submit" className="btn-1" disabled={loading}>{loading ? "Loading..." : "Submit"}</button>
                </form>
                {formSubmitted && (
                  <div className="alert alert-success mt-3" role="alert">
                    Registration successful! You may now log in using your credentials.
                  </div>
                )}
                {errors.form && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {errors.form}
                  </div>
                )}
        </div>
    </div>
    </div>
</>
)
}
export default Translator