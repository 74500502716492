import React from "react";
import { Link, Redirect } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { isAuthenticated } from './auth'; // Import the isAuthenticated helper function
import  AdminNavigation  from './AdminNavigation';

function AdminVideoPlaylist(){
    useEffect(() => {
        // Check if 'userId' is not present in localStorage
        if (!localStorage.getItem('userRole') && localStorage.getItem('userRole')!== 1) {
          // Redirect to the "/login" page if 'userId' is not present
          window.location.href = "/login";
        }
      }, []); // The empty dependency array ensures that this effect runs once when the component mounts

    //Code for fetching users list
const [videolist, getVideosData]= useState([]);
const API_URL = `${process.env.REACT_APP_API_URL}/video/getlist`;

// Define a loading state variable
const [loading, setLoading] = useState(true);


const fetchVideosData = ()=>{
    fetch(API_URL)
    .then((res)=>res.json())
    .then((res)=>{
        console.log(res['data']);
        getVideosData(res['data']);
    }).catch((error) => {
        // Handle any errors and hide the loader
        
        console.error('Error fetching user data:', error);
      }).finally(() => {
        // Hide the loader whether the request succeeds or fails
        setLoading(false);
      });

} 
useEffect(() => {
  fetchVideosData()
  }, [])

useEffect(() => {
    console.log("videolist Data", videolist);
}, [videolist])
//Delete Functionality 

const ViewSingleVideo = (id) => {
    //console.log(id);
    
    
 }
//Update functionality
const updateVideosdata = (updateVideosdata)=>{
    //console.log(updatedata);
    let {id,title,url} = updateVideosdata;
       localStorage.setItem('ID', id);
       localStorage.setItem('Video Title', title);
       localStorage.setItem('Video URL', url);
}

//Delete Functionality 

const delVideodata = (id) => {
    //console.log(id);
    const TOKEN = localStorage.getItem('adminToken');
    if (window.confirm('Do You want to delete this page ?')) {
    axios.delete(`${process.env.REACT_APP_API_URL}/video/delete/${id}`,{ headers: {
        Authorization: `Bearer ${TOKEN}`,
      },}).then(() => {
        fetchVideosData();
    })
    }
}

if (loading) {
    // Render a loading overlay with the "Loading..." message
    return (
      <div className="loading-overlay">
        {/* <div className="loading-content">Loading...</div> */}
        <img src='/img/loader.gif'></img>
      </div>
    );
  }

return(
    <>
    <div id="wrapper" className="toggled">
   <AdminNavigation/>
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-lg-offset-2">
                    <Link to={"/admin/add_video_playlist"} className="btn btn-primary">Add Videos</Link>
                      <h5>All Videos </h5>
                      <table class="table table-dark table-striped">
                        <thead>
                        <tr>
                            <th>Sno</th>
                            <th>Video Title</th>
                            <th>URL</th>
                            <th>Created At</th>
                            <th colSpan={2}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {videolist.map((videolistData, key) => {
                            return(

                        <tr>
                            <td>{key+1}</td>
                            <td>{videolistData.title}</td>
                            <td>{videolistData.url}</td>
                            <td>{videolistData.createdAt}</td>
                            <td> <Link to='/admin/edit_video'><button  onClick={() => updateVideosdata(videolistData)} className='btn btn-primary'>Edit</button> </Link></td>
                            <td><button onClick={() => delVideodata(videolistData.id)} className='btn btn-danger'>Delete</button></td>
                            
                        </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
)

}
export default AdminVideoPlaylist