import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Snackbar } from "@mui/material";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_test_51M7ESnSCq9c1L3o5dzZjWFWg7BzFr6Hl8TgJy0vzFSwdmjDXJZez5IibxUG0ocAjvhfmYG70gJKue2wVKjK6H3H100iagpGxlx"
    );
  }

  return stripePromise;
};

function VideoChatForm() {
  const navigate = useNavigate();
  const [showPaymentOptions, setShowPaymentOptions] = useState(true);
  const [userRole, setUserRole] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const stripe = useStripe();
  // const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);

  //States lists
  const [states_list, setStates] = useState(null);

  //City list
  const [city_list, setCityList] = useState(null);

  const [pageContent, setPageContent] = useState(null);
  //Loader section
  const [loading, setLoading] = useState(true);

  //Paypal success message constant
  const [paymentsuccess, setPaymentSuccess] = useState("");

  //paypal error message constant
  const [paypalerror, setPaypalError] = useState("");

  /**
   * Form Fields and submisson section starts here
   */
  const [fname, setFirstName] = useState("");
  const [lname, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [form_country, setSelectedCountryName] = useState(null);
  const [form_state, setSelectedStateName] = useState(null);
  const [form_city, setSelectedCityName] = useState(null);
  const [occupation, setOccupation] = useState("");
  const [profile_num_lady1, setLadyProfileNumberOne] = useState("");
  const [profile_num_lady2, setLadyProfileNumberTwo] = useState("");
  const [profile_num_lady3, setLadyProfileNumberThree] = useState("");
  const [profile_num_lady4, setLadyProfileNumberFour] = useState("");
  const [profile_num_lady5, setLadyProfileNumberFive] = useState("");
  const [profile_num_lady6, setLadyProfileNumberSix] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  //const [video_chat_amount,setVideoChatAmount] = useState('200');

  const [Formtype, setFormType] = useState("videochat");
  const [selectedPaymentMethod, setPaymentSelectedMethod] = useState(null);
  const [setFormSubmittedpaymentMethod, SetSubmittedPayment] = useState(null);
  const [paid_video_chat_amount, setPaidVideoChatAmount] = useState(null);
  const payment_type = selectedPaymentMethod;
  const payment_status = "Pending";
  const [ladiesListData_email, setLadiesListData_email] = useState(null);
  const userId = localStorage.getItem("userId");
  const [ladiesListingData, setLadiesListingData] = useState([]);
  const [meetingDate, setMeetingDate] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const filterParameter = "gender";
  // const [selectedLady, setSelectedLady] = useState("");
  const [selectedLady, setSelectedLady] = useState({ id: "", email: "" });

  const [order_id, setOrderID] = useState(null);
  const [formData, setFormdata] = useState();

  const fetchLadiesData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/getfiltereduser`,
        {
          [filterParameter]: "female",
        }
      );

      setLadiesListingData(response.data.data);
      setLoading(false);
    } catch (error) {
      //console.error('ERROR:', error);
    }
  };
  useEffect(() => {
    fetchLadiesData();
  }, []);
  // Merge values and create a comma-separated string
  const mergedProfileNumbers = [
    profile_num_lady1,
    profile_num_lady2,
    profile_num_lady3,
    profile_num_lady4,
    profile_num_lady5,
    profile_num_lady6,
  ]
    .filter((value) => value !== "") // Filter out empty values
    .join(",");

  /*Calculate video_chat_amount based on the number of selected profiles*/
  const selectedProfilesCount = mergedProfileNumbers
    ? mergedProfileNumbers.split(",").length
    : 0;
  const selectedLadyLength = selectedLady.email ? selectedLady.email.length : 1;
  const video_chat_amount = (selectedProfilesCount + selectedLadyLength) * 50;

  const [insertsuccess, setInsertSuccess] = useState("");
  const [errors, setErrors] = useState({});
  const handelVideoChatForm = async (submit_event) => {
    submit_event.preventDefault();
    // Validate form fields
    // Hide the payment options
    setShowPaymentOptions(false);
    const validationErrors = {};
    if (!fname) {
      validationErrors.fname = "First name is required.";
    }
    if (!lname) {
      validationErrors.lname = "First name is required.";
    }
    if (!age) {
      validationErrors.age = "Age is required.";
    }
    if (!form_country) {
      validationErrors.form_country = "Country is required.";
    }
    if (!form_state) {
      validationErrors.form_state = "State is required.";
    }
    if (!form_city) {
      validationErrors.form_city = "City is required.";
    }
    if (!occupation) {
      validationErrors.occupation = "Occupation is required.";
    }
    if (!meetingDate) {
      validationErrors.occupation = "Meeting Date is required.";
    }
    if (!meetingTime) {
      validationErrors.occupation = "Meeting Time is required.";
    }
    if (!selectedLady) {
      validationErrors.selectedLady = "Please Insert Ladies Profile Id.";
    }
    if (!selectedLady) {
      validationErrors.selectedLady = "Please Insert Ladies Profile Id.";
    }
    setErrors(validationErrors);

    // Clear errors after 10 seconds
    setTimeout(() => {
      setErrors({});
    }, 10000); // 10000 milliseconds = 10 seconds

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      setFormSubmitted(false);
      const country = form_country;
      const state = form_state;
      const city = form_city;
      const profilenumber_ladies = selectedLady;
      const otherLadies = mergedProfileNumbers;
      const amount = video_chat_amount;
      const type = Formtype;
      const user_id = userId;
      const video_chat_form_data = {
        fname,
        lname,
        age,
        country,
        state,
        city,
        occupation,
        profilenumber_ladies,
        amount,
        payment_type,
        payment_status,
        user_id,
        type,
        meetingDate,
        meetingTime,
        otherLadies,
      };

      try {
        const insert_form_data = await axios.post(
          `${process.env.REACT_APP_API_URL}/svconsultation/add`,
          video_chat_form_data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        SetSubmittedPayment(insert_form_data.data.data.payment_type);
        setFormdata(insert_form_data?.data?.data);

        setPaidVideoChatAmount(insert_form_data.data.data.amount);

        /**
         * Set Order ID for updating the form after successfull transaction
         */
        setOrderID(insert_form_data.data.data.id);

        setLoading(false);
        setInsertSuccess("Data Inserted Successfully!!");
      } catch (error) {
        if (error.response) {
          // console.error('Server responded with error status:', error.response.status);
          //console.error('Error response data:', error.response.data);
          setErrors({
            form: "An error occurred during registration. Please try again later.",
          });
        } else if (error.request) {
          // The request was made but no response was received.
          // console.error('No response received from the server:', error.request);
          setErrors({
            form: "No response received from the server. Please try again later.",
          });
        } else {
          // Something happened in setting up the request that triggered an Error.
          //console.error('Error during request setup:', error.message);
          setErrors({
            form: "An error occurred during registration. Please try again later.",
          });
        }

        setLoading(false);
        setFormSubmitted(false);
      }
    }
  };
  useEffect(() => {
    // Retrieve UserRole from localStorage
    const role = localStorage.getItem("userRole");
    setUserRole(role);
  }, []);
  useEffect(() => {
    const createAndJoinMeeting = async () => {
      const storedLoginResponse = localStorage.getItem("loginResponse");
      const loginResponse = storedLoginResponse
        ? JSON.parse(storedLoginResponse)
        : null;
      const userEmail = loginResponse?.data?.data?.email;
      const localStorageToken =
        loginResponse && loginResponse.data && loginResponse.data.tokan
          ? loginResponse.data.tokan
          : null;
      const token = localStorageToken;
      const userId = localStorage.getItem("userId");

      const [year, month, day] = meetingDate.split("-").map(Number);
      const [hour, minute, second] = meetingTime.split(":").map(Number);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/zoom/create-meeting`,
          {
            userId: userId,
            ladiesId: selectedLady,
            translaterId: "",
            meetingDuration: 30,
            svcId: order_id,
            // meetingDateTime: [2024, 5, 20, 15, 30, 0],
            meetingDateTime: [year, month, day, hour, minute, second],
          },
          {
            // Include necessary headers and payload for the Zoom API request
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            // body: JSON.stringify({
            //   topic: "Scheduled Meeting",
            //   type: 2,
            //   start_time: "2024-06-20T04:30:00Z",
            //   duration: 7,
            //   timezone: "Asia/Calcutta",
            //   settings: {
            //     host_video: true,
            //     participant_video: true,
            //     join_before_host: true,
            //   },
            // }),
          }
        );
        const meetingData = response?.data?.data?.meetingdata;
        const joinUrl = meetingData?.join_url;
        // setMeetingUrl(joinUrl);
        // Redirect to the join URL
        // window.location.href = joinUrl;

        // if (paymentsuccess === "Payment was approved!") {
        //   localStorage.removeItem("cartItems");
        //Email functionality for sending email
        // const mails = { ladiesListData_email, userEmail };
        const mailid = [ladiesListData_email, userEmail].filter(Boolean); // Ensure no null or undefined values;

        const subject = "Meeting Created Successfully";
        //     const html = `<p>Hello,
        //       <b>${formData?.fname} ${formData?.lname}</b>
        // <br> Your meeting has been successfully created
        //  Your Meeting ID is : ${meetingData?.meetingId} <br>
        //  <b> Please join the meeting using this URL : ${joinUrl}</b> </p>`;
        const html = `
  <p>Hello, 
    <b>${formData?.fname} ${formData?.lname}</b> 
    <br> Your meeting has been successfully created. 
    Your Meeting ID is: ${meetingData?.meetingId} <br>
    <b>Please join the meeting using this URL: 
      <a href="${joinUrl}" target="_blank">${joinUrl}</a>
    </b>
  </p>`;

        const notify_admin = 1;

        const mail_data = {
          mailid,
          subject,
          html,
          notify_admin,
        };

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/svconsultation/sendCustomMail`,
            mail_data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((mail_response) => {
            navigate("/welcome");
            alert(mail_response?.data?.msg);
          });
      } catch (error) {
        console.error("Error creating the meeting", error);
      }
    };
    if (paymentsuccess) {
      createAndJoinMeeting();
    } else {
    }
  }, [paymentsuccess, order_id, selectedLady]);

  const [getcountries, setCountries] = useState([]);
  /**
   * Get Countries listing
   */
  const fetchCountriesList = () => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/location/getcountries`)
        .then((resp) => {
          setCountries(resp.data.data);
        });
    } catch (error) {}
  };
  useEffect(() => {
    fetchCountriesList();
  }, []);

  /**
   * Page content function Starts Here
   */

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/pages/slug/VideoChatForm`
        );
        setPageContent(response.data.data["0"]);
      } catch (error) {
        console.error("Error fetching page data:", error);
        if (error.response.status === 401) {
          navigate("/login");
        }
      } finally {
        setLoading(false); // Set loading to false whether the request succeeds or fails
      }
    };
    fetchPageContent();
  }, []);

  const handleLadyOneSelectChange = (e) => {
    const ladiesId = e.target.value;
    setSelectedLady(e.target.value);
    const selectedLadyEmail = ladiesListingData.find(
      (lady) => String(lady.id) === ladiesId
    );
    setLadiesListData_email(selectedLadyEmail);
  };

  const handleTimeChange = (event) => {
    const timeValue = event.target.value;
    const formattedTime = `${timeValue}:00`;
    setMeetingTime(formattedTime);
  };

  /**
   * Page content function Ends Here
   */
  /**
   * Check loader is enabled or not
   */
  if (loading) {
    // Render a loading overlay with the "Loading..." message
    return (
      <div className="loading-overlay">
        {/* <div className="loading-content">Loading...</div> */}
        <img src="/img/loader.gif"></img>
      </div>
    );
  }

  /**
   * Return Null if page content is not there
   */
  if (!pageContent) {
    return null; // Or render some loading/error messageform_country
  }

  const style = {
    backgroundImage: `url('${pageContent.bannerurl}')`,
    // Add other CSS properties as needed
  };

  //Get State isting Based on the Country Id
  const getStateByCountryId = async (e) => {
    const selectedCountryId = e.target.value;
    setSelectedCountryName(e.target.value);

    try {
      const state_resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/location/getstates/${selectedCountryId}`
      );
      setStates(state_resp.data.data);
    } catch (error) {
      console.log("Error is" + error);
    }
  };

  /**
   * Get City name based on the state id
   */

  const getCityByStateId = async (event_one) => {
    const selectedStateId = event_one.target.value;
    setSelectedStateName(event_one.target.value);
    try {
      const cityresp = await axios.get(
        `${process.env.REACT_APP_API_URL}/location/getcities/${selectedStateId}`
      );
      setCityList(cityresp.data.data);
    } catch (error) {}

    //setCityList();
  };

  const getCityName = async (e1) => {
    const selectedcity = e1.target.value;
    setSelectedCityName(e1.target.value);
  };

  const handlePaymentOptionClick = (e) => {
    if (!userRole) {
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } else {
      // Set the selected payment method
      setPaymentSelectedMethod(e.target.value);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <section className="page-info-sec">
        <div className="bg-fixed" style={style}></div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>
                  {pageContent ? pageContent.bannertitle : "Video Chat Form"}
                </h1>
                <p>
                  {pageContent
                    ? pageContent.bannerdesc
                    : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur malesuada risus quis ligula pellentesque fermentum. Aenean vel ex finibus, lacinia lacus non, sodales dolor. Phasellus eget turpis neque. Sed aliquet nec felis non faucibus. Quisque euliy tristique elit, sit amet laoreet neque. Vestibulum aliquam at augue at dictum. Praes ent interdum blandit elit, nec ullamcorper odio."}
                </p>
                <p className="page_nav">
                  {" "}
                  <a href="/">Home</a>/
                  <a href="#" className="currentPage">
                    {pageContent ? pageContent.bannertitle : "Video Chat Form"}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="video-chat-form-sec secpd70">
        <div className="container">
          <div className="row">
            <div className="col-md-12"></div>
            <div className="col-md-12">
              <div className="main-from-wrap">
                <div className="img-holder" style={{ marginLeft: "5%" }}>
                  <img src={pageContent.pageImage} alt="" />
                </div>
                <div className="main-from-inner-wrap">
                  <div className="title-centered text-center">
                    <h2 className="title-30 mb-3">Video Chats with Ladies</h2>
                    <p>{pageContent && pageContent.pagedesc}</p>
                  </div>

                  <div className="formwrapper">
                    <div className="col-md-6 mx-auto">
                      {insertsuccess && (
                        <div
                          className="alert alert-success mt-3 text-center"
                          role="alert"
                        >
                          {insertsuccess}
                        </div>
                      )}
                    </div>
                    <form onSubmit={handelVideoChatForm}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              First Name<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={fname}
                              name="fname"
                              className="form-control"
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                            {errors.fname && (
                              <p className="text-danger">{errors.fname}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Last Name<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={lname}
                              name="lname"
                              className="form-control"
                              onChange={(e) => setLastName(e.target.value)}
                            />
                            {errors.lname && (
                              <p className="text-danger">{errors.lname}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Age<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={age}
                              name="age"
                              className="form-control"
                              onChange={(e) => setAge(e.target.value)}
                            />
                            {errors.age && (
                              <p className="text-danger">{errors.age}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Country<span className="required">*</span>
                            </label>
                            <select
                              className="form-select"
                              name="country"
                              aria-label="Default select example"
                              value={form_country}
                              onChange={getStateByCountryId}
                            >
                              <option value="">Select</option>
                              {getcountries &&
                                getcountries.map((countrylist, index) => {
                                  return (
                                    <option key={index} value={countrylist.id}>
                                      {countrylist.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.form_country && (
                              <p className="text-danger">
                                {errors.form_country}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              State<span className="required">*</span>
                            </label>
                            <select
                              className="form-select"
                              name="state"
                              aria-label="Default select example"
                              value={form_state}
                              onChange={getCityByStateId}
                            >
                              <option value="">Select</option>
                              {states_list &&
                                states_list.length > 0 &&
                                states_list.map((statelist, index) => {
                                  return (
                                    <option key={index} value={statelist.id}>
                                      {statelist.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.form_state && (
                              <p className="text-danger">{errors.form_state}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              City<span className="required">*</span>
                            </label>
                            <select
                              className="form-select"
                              name="city"
                              aria-label="Default select example"
                              value={form_city}
                              onChange={getCityName}
                            >
                              <option value="">Select</option>
                              {city_list &&
                                city_list.length > 0 &&
                                city_list.map((citylist, index) => {
                                  return (
                                    <option key={index} value={citylist.id}>
                                      {citylist.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.form_city && (
                              <p className="text-danger">{errors.form_city}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div>
                            <label>
                              Occupation<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={occupation}
                              name="occupation"
                              className="form-control"
                              onChange={(e) => setOccupation(e.target.value)}
                            />
                            {errors.occupation && (
                              <p className="text-danger">{errors.occupation}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-12">
                          <hr className="form-divider" />
                        </div>

                        <div className="col-12">
                          <h3 className="title-20">Profile Number of Ladies</h3>
                        </div>

                        {/* <div className="col-md-4">
                          <div className="mb-3 mb-md-4">
                            <label>Lady 1<span className="required">*</span></label>
                            <input type="text" className="form-control" name="profilenumber_ladies" placeholder="15678" value={profile_num_lady1}  onChange={(e)=>setLadyProfileNumberOne(e.target.value)}/>
                            {errors.profile_num_lady1 && <p className="text-danger">{errors.profile_num_lady1}</p>}
                          </div>
                        </div> */}
                        <div className="col-md-4">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Lady Name<span className="required">*</span>
                            </label>
                            <select
                              className="form-control"
                              value={selectedLady}
                              onChange={handleLadyOneSelectChange}
                            >
                              <option value="">Select a lady</option>
                              {ladiesListingData.map((lady) => (
                                <option key={lady.id} required value={lady.id}>
                                  {lady.fname}{" "}
                                  {/* Assuming each lady object has an 'id' and 'name' */}
                                </option>
                              ))}
                            </select>

                            {errors.selectedLady && (
                              <p className="text-danger">
                                {errors.selectedLady}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="mb-3 mb-md-4">
                            <label>Meeting Date</label>
                            <input
                              type="date"
                              className="form-control"
                              name="profilenumber_ladies"
                              placeholder="20/06/2024"
                              value={meetingDate}
                              required
                              onChange={(e) => setMeetingDate(e.target.value)}
                              min={
                                new Date(Date.now() + 24 * 60 * 60 * 1000)
                                  .toISOString()
                                  .split("T")[0]
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="mb-3 mb-md-4">
                            <label>Meeting Time</label>
                            <input
                              type="time"
                              className="form-control"
                              name="profilenumber_ladies"
                              placeholder="10:00:00"
                              required
                              value={meetingTime.slice(0, 5)} // To show only HH:MM
                              onChange={handleTimeChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="mb-3 mb-md-4">
                            <label>Lady 2 Optional</label>
                            {/* <input
                              type="text"
                              className="form-control"
                              name="profilenumber_ladies"
                              placeholder="24678"
                              value={profile_num_lady2}
                              onChange={(e) =>
                                setLadyProfileNumberTwo(e.target.value)
                              }
                            /> */}
                            <select
                              className="form-control"
                              value={profile_num_lady2}
                              onChange={(e) =>
                                setLadyProfileNumberTwo(e.target.value)
                              }
                            >
                              <option value="">Select a lady</option>
                              {ladiesListingData.map((lady) => (
                                <option key={lady.id} required value={lady.id}>
                                  {lady.fname}{" "}
                                  {/* Assuming each lady object has an 'id' and 'name' */}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 mb-md-4">
                            <label>Lady 3 (optional) </label>
                            <select
                              className="form-control"
                              value={profile_num_lady3}
                              onChange={(e) =>
                                setLadyProfileNumberThree(e.target.value)
                              }
                            >
                              <option value="">Select a lady</option>
                              {ladiesListingData.map((lady) => (
                                <option key={lady.id} required value={lady.id}>
                                  {lady.fname}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 mb-md-4">
                            <label>Lady 4 (optional)</label>
                            <select
                              className="form-control"
                              value={profile_num_lady4}
                              onChange={(e) =>
                                setLadyProfileNumberFour(e.target.value)
                              }
                            >
                              <option value="">Select a lady</option>
                              {ladiesListingData.map((lady) => (
                                <option key={lady.id} required value={lady.id}>
                                  {lady.fname}{" "}
                                  {/* Assuming each lady object has an 'id' and 'name' */}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 mb-md-4">
                            <label>Lady 5 (optional)</label>
                            {/* <input
                              type="text"
                              className="form-control"
                              name="profilenumber_ladies"
                              placeholder="23679"
                              value={profile_num_lady5}
                              onChange={(e) =>
                                setLadyProfileNumberFive(e.target.value)
                              }
                            /> */}
                            <select
                              className="form-control"
                              value={profile_num_lady5}
                              onChange={(e) =>
                                setLadyProfileNumberFive(e.target.value)
                              }
                            >
                              <option value="">Select a lady</option>
                              {ladiesListingData.map((lady) => (
                                <option key={lady.id} required value={lady.id}>
                                  {lady.fname}{" "}
                                  {/* Assuming each lady object has an 'id' and 'name' */}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 mb-md-4">
                            <label>Lady 6 (optional)</label>

                            <select
                              className="form-control"
                              value={profile_num_lady6}
                              onChange={(e) =>
                                setLadyProfileNumberSix(e.target.value)
                              }
                            >
                              <option value="">Select a lady</option>
                              {ladiesListingData.map((lady) => (
                                <option key={lady.id} required value={lady.id}>
                                  {lady.fname}{" "}
                                  {/* Assuming each lady object has an 'id' and 'name' */}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="total-price-of-video">
                            Total Cost of Video Chats: ${video_chat_amount}
                            <input
                              type="hidden"
                              name="amount"
                              value={video_chat_amount}
                            ></input>
                          </div>
                        </div>
                        <div className="col-md-12">
                          {paypalerror && (
                            <div
                              className="alert alert-danger mt-3"
                              role="alert"
                            >
                              {paypalerror}
                            </div>
                          )}
                          {paymentsuccess && (
                            <div
                              className="alert alert-success mt-3"
                              role="alert"
                            >
                              {paymentsuccess}
                            </div>
                          )}
                          {showPaymentOptions && (
                            <div className="payment-options">
                              {/* <label className="payment-option">
                              <input type="radio" name="payment-option" />
                              <span className="mark"></span>
                              <img src="./img/Stripe.png" alt="" />
                            </label> */}

                              {/* <label className="payment-option">
                              <input
                                type="radio"
                                name="payment-option"
                                value="stripe"
                                onClick={(e) =>
                                  setPaymentSelectedMethod(e.target.value)
                                }
                              />
                              <span className="mark"></span>
                              <img src="./img/Braintree_logo.png" alt="" />
                            </label> */}
                              {paymentError && (
                                <p style={{ color: "red" }}>{paymentError}</p>
                              )}
                              <label className="payment-option">
                                <input
                                  type="radio"
                                  name="payment-option"
                                  value="paypal"
                                  onClick={handlePaymentOptionClick}
                                />
                                <span className="mark"></span>
                                <img src="./img/PayPal.png" alt="" />
                              </label>
                            </div>
                          )}
                          {userRole && (
                            <p>
                              Click below to make a payment. There is no refund
                              when your video chat with a consultant begins.
                            </p>
                          )}
                        </div>

                        <div className="col-md-12">
                          {userRole && paid_video_chat_amount === null && (
                            <button type="submit" className="btn-1 w-100">
                              Submit
                            </button>
                          )}

                          {paid_video_chat_amount &&
                            setFormSubmittedpaymentMethod === "paypal" && (
                              <div>
                                {/* PayPal button */}
                                <PayPalScriptProvider
                                  options={{
                                    clientId:
                                      "AUEp11EuL5kicqTcKbLssOG1tToIyRW1_yT7jZ1we8QkS-TTgg4o6ALCebTlojWLl-89j6L1adLGCVlQ",
                                  }}
                                >
                                  <PayPalButtons
                                    style={{ layout: "horizontal" }}
                                    createOrder={(data, actions) => {
                                      return actions.order.create({
                                        purchase_units: [
                                          {
                                            amount: {
                                              value: paid_video_chat_amount,
                                              currency_code: "USD",
                                            },
                                          },
                                        ],
                                      });
                                    }}
                                    onApprove={(data, actions) => {
                                      setPaymentSuccess(
                                        "Payment was approved!"
                                      );

                                      const transactionId = data.orderID;

                                      /**
                                       * If payment dones successfully so it will update data based on the payment id
                                       */
                                      const payment_status = "complete";
                                      const transaction_id = transactionId;
                                      const update_video_chat_form_data = {
                                        payment_status,
                                        transaction_id,
                                      };

                                      try {
                                        const { data } = axios.get(
                                          `${process.env.REACT_APP_API_URL}/svconsultation/paymentReturn?transaction_id=${transactionId}&order_id=${order_id}&status=${payment_status}`,
                                          {
                                            headers: {
                                              "Content-Type":
                                                "application/json",
                                            },
                                          }
                                        );

                                        // Handle the response data as needed
                                      } catch (error) {
                                        if (error.response) {
                                          console.error(
                                            "Server responded with error status:",
                                            error.response.status
                                          );
                                          console.error(
                                            "Error response data:",
                                            error.response.data
                                          );
                                          setErrors({
                                            form: "An error occurred during updation. Please try again later.",
                                          });
                                        } else if (error.request) {
                                          console.error(
                                            "No response received from the server:",
                                            error.request
                                          );
                                          setErrors({
                                            form: "No response received from the server. Please try again later.",
                                          });
                                        } else {
                                          console.error(
                                            "Error during request setup:",
                                            error.message
                                          );
                                          setErrors({
                                            form: "An error occurred during updation. Please try again later.",
                                          });
                                        }
                                      }

                                      /**
                                       * If payment dones successfully so it will update data based on the payment id
                                       */
                                      //window.location.href = `${process.env.REACT_APP_SITE_URL}/Singlevacationconsulatant/paymentReturn?transaction_id=${transactionId}&order_id=${transactionId}&status=complete`;

                                      return actions.order.capture();
                                    }}
                                    onError={(err) => {
                                      console.error("Payment error:", err);
                                      setPaypalError("Payment error:", err);

                                      //window.location.href = `${process.env.REACT_APP_SITE_URL}/VideoChatForm?status=failed`;
                                    }}
                                  />
                                </PayPalScriptProvider>
                              </div>
                            )}

                          {paid_video_chat_amount &&
                            setFormSubmittedpaymentMethod === "stripe" && (
                              <div>
                                {/* Stripe button */}
                                {/* <button onClick={handleStripePayment} className="btn btn-primary">Pay with Stripe</button> */}
                                <form
                                  action={`${process.env.REACT_APP_API_URL}/svconsultation/create-checkout-session`}
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="orderid"
                                    value={order_id}
                                  />
                                  <input
                                    type="hidden"
                                    name="pricekey"
                                    value="price_1OSI60SCq9c1L3o5MG8TAGVu"
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Checkout
                                  </button>
                                </form>
                              </div>
                            )}
                        </div>
                        {/* <div className="col-md-12">
                          <button type="submit" className="btn-1 w-100">Submit</button>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Snackbar Component */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Please log in to fill the form."
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </>
  );
}
export default VideoChatForm;
//export default WrappedVideoChatForm; // Export the wrapped component
