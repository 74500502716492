import { Button, Snackbar } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Gift = () => {
  const { categoryId } = useParams();
  const [gifts, setGifts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGifts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/gifts/listbycatid/${categoryId}`
        );
        setGifts(response.data?.data || []);
      } catch (error) {
        console.error("Error fetching gift data:", error);
      }
    };

    fetchGifts();
  }, [categoryId]);

  const location = useLocation();
  const ladiesId = location.state;

  const addToCart = (gift) => {
    const existingItemIndex = cartItems.findIndex(
      (item) => item.id === gift.id
    );

    if (existingItemIndex !== -1) {
      const updatedCartItems = cartItems.map((item, index) => {
        if (index === existingItemIndex) {
          return { ...item };
        }
        return item;
      });
      setCartItems(updatedCartItems);
    } else {
      const updatedCartItems = [
        ...cartItems,
        { ...gift, quantity: 1, ladiesId: ladiesId },
      ];
      setCartItems(updatedCartItems);
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    }
    setOpenSnackbar(true);
  };

  const viewCart = () => {
    navigate(`/giftcategory/${ladiesId.ladiesId}/gift/${categoryId}/cart`, {
      state: {
        cartItems: cartItems,
      },
    });
  };

  useEffect(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <section className="page-info-sec">
        <div
          className="bg-fixed"
          style={{ backgroundImage: "url('./img/inner_banner.png')" }}
        ></div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>Gift List</h1>
                <p className="page_nav">
                  {" "}
                  <a href="/">Home</a>
                  <span className="mx-2">/</span>
                  <a href="#" className="currentPage">
                    Gift List
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="welcome-sec secpd70">
        <div className="container">
          <div className="shadow-wrap-1">
            <div className="row gap-40">
              <div className="col-12">
                <h2 className="title-30 mb-3">Gift List</h2>
              </div>
              <div className="row">
                <div className="col-12">
                  <Button onClick={viewCart}>View Cart</Button>
                  <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message="Item added to cart successfully"
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  />
                </div>
              </div>

              {gifts?.length > 0 ? (
                gifts?.map((gift) => (
                  <div className="col-1/5" key={gift.id}>
                    <div className="welcome-box-gift">
                      <div className="wb-icon-box-gift">
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${gift.image}`}
                          alt={gift.title}
                          className="table-img"
                        />
                      </div>
                      <p>{gift.title}</p>
                      <Button
                        className="btn btn-default"
                        style={{ top: "27px" }}
                        onClick={() => addToCart(gift)}
                      >
                        Add to Cart
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <p>No records found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gift;
