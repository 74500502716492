import { Link } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useState } from 'react';
import  AdminNavigation  from './AdminNavigation';
function onChange(value) {
  // console.log("Captcha value:", value);
}



function AddPages() {
  const [bannerurl, setBannerurl] = useState("");
  const [bannertitle, setBannertitle] = useState("");
  const [bannerdesc, setBannerdesc] = useState("");
  const [pagetitle, setPagetitle] = useState("");
  const [pagedesc, setPagedesc] = useState("");
  const [template, setTemplate] = useState("");
  const [slug, setSlug] = useState("");
  const [sort, setSort] = useState("");
  const [top_menu, setTopmenu] = useState("");
  const [pageImage, setpageImage] = useState("");
  
  

  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const pageData = { bannerurl, bannertitle, bannerdesc, pagetitle, pagedesc, template, slug,sort,top_menu,pageImage };
    const TOKEN = localStorage.getItem('adminToken');

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/pages/createpage`, pageData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`,
        },
      });

      // Check if the response indicates success (you might have a different way of checking it)
      if (res.status === 200) {
        setSuccessMessage("Page created successfully!");
      } else {
        setSuccessMessage("Page creation failed.");
      }
    } catch (error) {
      setSuccessMessage("Page creation failed.");
    }
  };

  return (
    <>
     <div id="wrapper" className="toggled">
   <AdminNavigation/>
        
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            </div>
<div className="container">
        <div className="row mt-4">
          <div className="col-md-8 mx-auto registration_section">
          {successMessage && (
              <div className="alert alert-success mt-3 text-center" role="alert">
                {successMessage}
              </div>
            )}
            <h4>Add Page</h4>
            <form onSubmit={handleSubmit}>
              <div className="mb-3 mt-3">
                <label htmlFor="bannerurl" className="form-label">Banner URL:</label>
                <input type="text" className="form-control" id="bannerurl" placeholder="Enter Banner URL" name="bannerurl" value={bannerurl} onChange={(e) => setBannerurl(e.target.value)} required />
              </div>
              <div className="mb-3 mt-3">
                <label htmlFor="Banner Title" className="form-label">Banner Title:</label>
                <input type="text" className="form-control" id="bannertitle" placeholder="Enter Banner Title" name="bannertitle" value={bannertitle} onChange={(e) => setBannertitle(e.target.value)} required />
              </div>
              <div className="mb-3 mt-3">
                <label htmlFor="Banner Description" className="form-label">Banner Description:</label>
                <input type="text" className="form-control" id="bannerdesc" placeholder="Enter Banner Description" name="bannerdesc" value={bannerdesc} onChange={(e) => setBannerdesc(e.target.value)} required />
              </div>
              <div className="mb-3 mt-3">
                <label htmlFor="Page Title" className="form-label">Page Title:</label>
                <input type="text" className="form-control" id="pagetitle" placeholder="Enter Page Title" name="pagetitle" value={pagetitle} onChange={(e) => setPagetitle(e.target.value)} required />
              </div>
              <div className="mb-3 mt-3">
                <label htmlFor="Page Description" className="form-label">Page Description:</label>
                <input type="text" className="form-control" id="pagedesc" placeholder="Enter Page Description" name="pagedesc" value={pagedesc} onChange={(e) => setPagedesc(e.target.value)} required />
              </div>
              <div className="mb-3 mt-3">
                <label htmlFor="Page Template" className="form-label">Page Template:</label>
                <input type="text" className="form-control" id="template" placeholder="Enter Page Template" name="template" value={template} onChange={(e) => setTemplate(e.target.value)} required />
              </div>
              <div className="mb-3 mt-3">
                <label htmlFor="Page Slug" className="form-label">Page Slug:</label>
                <input type="text" className="form-control" id="slug" placeholder="Enter Page Slug" name="slug" value={slug} onChange={(e) => setSlug(e.target.value)} required />
              </div>
              <div className="mb-3">
              <label htmlFor="Page Sort" className="form-label">Page Sorting:</label>
              <input type="text" className="form-control" id="sort" placeholder="Enter Page Sort" name="sort" value={sort} onChange={(e)=>setSort(e.target.value)} required/>
            </div>
            <div className="mb-3">
              <label htmlFor="Page Top Menu" className="form-label">Page Menu Postion:</label>
              <input type="text" className="form-control" id="top_menu" placeholder="Enter Menu Position" name="top_menu" value={top_menu} onChange={(e)=>setTopmenu(e.target.value)} required/>
            </div>

            <div className="mb-3">
              <label htmlFor="Page Top Menu" className="form-label">Page Image:</label>
              <input type="text" className="form-control" id="top_menu" placeholder="Enter Page Image" name="top_menu" value={pageImage} onChange={(e)=>setpageImage(e.target.value)} required/>
            </div>

              <button type="submit" className="btn-1">Submit</button>
            </form>

           
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default AddPages;
