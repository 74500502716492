import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminNavigation from './AdminNavigation';
function AdminFAQSection(){
const [faqlisting,setFaqListing] =useState([]);
const API_URL = `${process.env.REACT_APP_API_URL}/faq`;
const getFaqListingData = async ()=>{
try {
  const resp = await axios.get(API_URL);
  const FAQData = resp.data.data;
  //console.log(resp,"FAQ RESP");
  setFaqListing(FAQData);
  
} catch (error) {
  console.log(error);
}
}

useEffect(()=>{getFaqListingData()},[]);

const delFAQdata = async (id) => {
  if (window.confirm('Do you want to delete?')) {
    try {
      const TOKEN = localStorage.getItem('adminToken');
      if (!TOKEN) {
        // Handle the case where the token is missing or invalid
        console.error("Missing or invalid token");
        return;
      }

      await axios.delete(`${process.env.REACT_APP_API_URL}/faq/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      });
      getFaqListingData();
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      // Handle the error, e.g., display an error message to the user
    }
  }
};

/**
 * Update functionality starts here
 */

const updateFAQData = (updatefaqdata)=>{
 let {id,question,answer,sort} = updatefaqdata;
 localStorage.setItem('ID',id);
 localStorage.setItem('Question',question);
 localStorage.setItem('Answer',answer);
  localStorage.setItem('Sort',sort);

}



    return(<>
   <div id="wrapper" className="toggled">
        <AdminNavigation />
        <div id="page-content-wrapper">
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-8 mx-auto registration_section">
            {/* <button >Add FAQ</button> */}
            <Link to = "/admin/addfaq" className="btn btn-primary">Add FAQ</Link>
            <h4>FAQ Section</h4>
            <table className="table table-light table-striped">
                        <thead>
                        <tr>
                            <th>Sno</th>
                            <th>FAQ Question</th>
                            <th>FAQ Answer</th>
                           <th colSpan={2}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                          {faqlisting.map((faq,index)=>(
                        <tr>
                            <td>{index+1}</td>
                            <td>{faq.question}</td>
                            <td>{faq.answer}</td>
                           <td> <Link to='/admin/updatefaqdata'><button className='btn btn-primary'  onClick={()=>updateFAQData(faq)} >Edit</button> </Link></td>
                           <td><button onClick={() => delFAQdata(faq.id)} className='btn btn-danger'>Delete</button></td>
                        </tr>
                        ))}
                          </tbody>
                    </table>
            
          </div>
        </div>
      </div>
      </div>
      </div>
    </>);

}
export default AdminFAQSection