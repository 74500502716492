import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function UpdateProfile() {
    const navigate= useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const userid = localStorage.getItem('userId');
    const [loading, setLoading] = useState(true);//Add a loader 
    //console.log("userid",userid);
    const [userDetails, setUserDetails] = useState({
        userid: null,
        fname: null,
        lname: null,
        dob: null,
        gender: null,
        address: null,
        weight: null,
        height: null,
        eyecolor: null,
        haircolor: null,
        children_ages: null,
        occupation: null,
        hobbies: null,
        personality: null,
        person_to_marry: null,
        yourself_in_future: null,
        
    })
    const filterParameter = 'id';

   // console.log(userDetails,"Userdetailsssss");

    const fetchSingleUserData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/getfiltereduser`, {
                [filterParameter]: userid
            });
            const user = response.data.data[0]
            setUserDetails({
                fname: user.fname,
                lname: user.lname,
                dob: user.dob,
                address: user.address,
                gender: user.gender,
                weight: user.weight,
                height: user.height,
                eyecolor: user.eyecolor,
                haircolor: user.haircolor,
                children_ages: user.children_ages,
                occupation: user.occupation,
                hobbies: user.hobbies,
                personality: user.personality,
                person_to_marry: user.person_to_marry,
                yourself_in_future: user.yourself_in_future,
              
            });
            console.log("useruseruser", user);
             console.log("RESPPPP", response.data.data[0]);
            //console.log("****************************",userDetails,"*********************");
            setLoading(false);
        } catch (error) {
            //console.error('ERROR:', error);console.error('Error fetching user data:', error);
            if (error.res.status === 401) {
                navigate("/login");
              }
            
        }
    };

    const updateSelectedUserDataByObj = (e) => {
        console.log( e.target);
        const { name, value } = e.target;
        setUserDetails({
            ...userDetails,
            [name]: value
        });
    }

     

    const updateSelectedUserData = async () => {
        /**
         * Custom validations for ladies profile page only if gender is female
         */
        if(userDetails.gender === 'female'){
            if (!userDetails.fname || !userDetails.lname || !userDetails.dob || !userDetails.gender || !userDetails.address ||!userDetails.weight ||!userDetails.height||!userDetails.eyecolor ||!userDetails.haircolor||!userDetails.children_ages ||!userDetails.occupation||!userDetails.hobbies||!userDetails.personality||!userDetails.person_to_marry||!userDetails.yourself_in_future) {
                setErrorMessage("Please fill in all required fields marked with *.");
                setTimeout(() => setErrorMessage(""), 5000); 
                return;
            }
        }
         /**
         * Custom validations for ladies profile page only if gender is female ends here
         */
        if (!userDetails.fname || !userDetails.lname || !userDetails.dob || !userDetails.gender || !userDetails.address) {
            setErrorMessage("Please fill in all required fields marked with *.");
            setTimeout(() => setErrorMessage(""), 5000); 
            return;
        }
        //Get admin token form the database for the token
  const TOKEN = localStorage.getItem('adminToken');
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/users/update/${userid}`,
                userDetails,
                {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        // Other headers if needed
                    },
                }
            );
            //console.log(response);
            if (response) {
                // Check if there are errors in the response.
                if (response.data.msg && response.data.msg.errors) {
                    const errors = response.data.msg.errors;
    
                    // Iterate through the errors and display each one.
                    for (const error of errors) {
                        console.log("Error:", error.message);
                        setErrorMessage(error.message);
                    }
                } else {
                    setSuccessMessage("Profile updated successfully");
                    setTimeout(() => setSuccessMessage(""), 5000); 
                }
            } else {
                // Handle the case where the update fails.
            }
    
        } catch (error) {
            console.log(error);
           // alert(error);
          //  console.error('Error updating user data:', error);
            // Handle the error and set an error message using setErrorMessage.
            setErrorMessage("Failed to update profile");
            setTimeout(() => setErrorMessage(""), 5000);
        }
    };

      

    useEffect(() => {
        fetchSingleUserData();
    }, []);
    /**
     * ladies listing data get ends here
     */


    return (<>
        <section className="page-info-sec">
            <div className="bg-fixed" style={{ backgroundImage: "url('./img/inner_banner.png')" }}>
            </div>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col">
                        <div className="page-info-block">
                            <h1>Update Profile</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur male</p>
                            <p className="page_nav"> <a href="/">Home</a><span className="mx-2">/</span><a href="#" className="currentPage">Update Profile</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="video-chat-form-sec secpd70">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {successMessage && (
                            <div className="alert alert-success mt-3 text-center" role="alert">
                                {successMessage}
                            </div>
                        )}
                        <div className="main-from-wrap">
                            <div className="main-from-inner-wrap">
                                <div className="title-centered text-center">
                                    <h2 className="title-30 mb-3">Update Profile</h2>
                                    <p></p>
                                </div>

                                <div className="formwrapper">
                                    
                                    {errorMessage && (
                                        <div className="alert alert-danger mt-3" role="alert">
                                            {errorMessage}
                                        </div>
                                    )}
                                    <form>
                                        <div className="row">

                                            <div className="col-lg-6">
                                                <div className="mb-3 mb-md-4">
                                                    <label>First Name:<span className="required">*</span></label>
                                                    <input type="text" id="fname"  name="fname" value={userDetails.fname} className="form-control" placeholder="Journey of life" onChange={(e) => updateSelectedUserDataByObj(e)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3 mb-md-4">
                                                    <label>Last Name:<span className="required">*</span></label>
                                                    <input type="text" id="lname" name="lname" className="form-control" placeholder="Journey of life" value={userDetails.lname} onChange={(e) => updateSelectedUserDataByObj(e)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3 mb-md-4">
                                                    <label className="form-label">Date of Birth<span className="required">*</span></label>
                                                    <input type="date" placeholder="DD/MM/YYYY" className="form-control" name="dob" value={userDetails.dob} onChange={(e) => updateSelectedUserDataByObj(e)} />

                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3 mb-md-4">
                                                    <label className="form-label">Gender</label>
                                                    <select className="form-control" name="gender" value={userDetails.gender} onChange={(e) => updateSelectedUserDataByObj(e)}>
                                                        <option value="">Select Gender</option>
                                                        <option value="male" >Male</option>
                                                        <option value="female" >Female</option>
                                                    </select>


                                                </div>
                                            </div>
                                           
                                            {userDetails.gender === 'female' && (
                                                <>
                                                 <div className="col-lg-6">
                                                 <div className="mb-3 mb-md-4">
                                                 <label className="form-label">Weight<span className="required">*</span></label>
                                                 <input type="text" placeholder="Enter weight" className="form-control" value={userDetails.weight}  name="weight" onChange={(e) => updateSelectedUserDataByObj(e)} />
                                                 </div>
                                             </div>
                                              <div className="col-lg-6">
                                              <div className="mb-3 mb-md-4">
                                              <label className="form-label">Height<span className="required">*</span></label>
                                              <input type="text" placeholder="Enter Height in cm" className="form-control" value={userDetails.height}   name="height" onChange={(e) => updateSelectedUserDataByObj(e)}/>
                                              </div>
                                          </div>
                                          <div className="col-lg-6">
                                              <div className="mb-3 mb-md-4">
                                              <label className="form-label">Eye Color<span className="required">*</span></label>
                                              <input type="text" placeholder="Enter Eye Color" className="form-control" value={userDetails.eyecolor}  name="eyecolor" onChange={(e) => updateSelectedUserDataByObj(e)} />
                                              </div>
                                          </div>
                                          <div className="col-lg-6">
                                              <div className="mb-3 mb-md-4">
                                              <label className="form-label">Hair Color<span className="required">*</span></label>
                                              <input type="text" placeholder="Enter Hair Color" className="form-control" value={userDetails.haircolor} name="haircolor" onChange={(e) => updateSelectedUserDataByObj(e)}/>
                                              </div>
                                          </div>
                                          <div className="col-lg-6">
                                              <div className="mb-3 mb-md-4">
                                              <label className="form-label">Do you have children? If so, what are their ages?<span className="required">*</span></label>
                                              <input type="text" placeholder="Enter Children Ages" className="form-control" value={userDetails.children_ages} name="children_ages" onChange={(e) => updateSelectedUserDataByObj(e)}/>
                                              </div>
                                          </div>
                                          <div className="col-lg-6">
                                              <div className="mb-3 mb-md-4">
                                              <label className="form-label">What is your Occupation or profession?<span className="required">*</span></label>
                                              <input type="text" placeholder="Enter Occupation/profession" value={userDetails.occupation} className="form-control" name="occupation" onChange={(e) => updateSelectedUserDataByObj(e)}/>
                                              </div>
                                          </div>
                                          <div className="col-lg-6">
                                              <div className="mb-3 mb-md-4">
                                              <label className="form-label">What are your favorite hobbies or activities?<span className="required">*</span></label>
                                              <input type="text" placeholder="Enter hobbies or activities"  value={userDetails.hobbies}  className="form-control" name="hobbies" onChange={(e) => updateSelectedUserDataByObj(e)}/>
                                              </div>
                                          </div>
                                          <div className="col-lg-6">
                                              <div className="mb-3 mb-md-4">
                                              <label className="form-label">How would you describe your personality or characteristics?<span className="required">*</span></label>
                                              <input type="text" placeholder="Describe your personality" value={userDetails.personality}  className="form-control" name="personality" onChange={(e) => updateSelectedUserDataByObj(e)}/>
                                              </div>
                                          </div>
                                          <div className="col-lg-6">
                                              <div className="mb-3 mb-md-4">
                                              <label className="form-label">What type of man do you wish to date or marry?<span className="required">*</span></label>
                                              <input type="text" placeholder="Describe man do you wish to date or marry" className="form-control" name="person_to_marry" value={userDetails.person_to_marry}  onChange={(e) => updateSelectedUserDataByObj(e)}/>
                                              </div>
                                          </div>
                                          <div className="col-lg-6">
                                              <div className="mb-3 mb-md-4">
                                              <label className="form-label">Where do you see yourself in the future with the right man?
                                                <span className="required">*</span></label>
                                              <input type="text" placeholder="see yourself in the future with the right man" className="form-control" name="yourself_in_future" value={userDetails.yourself_in_future}  onChange={(e) => updateSelectedUserDataByObj(e)}/>
                                              </div>
                                          </div>
                                          </>
                                            )}
                                            
                                            <div className="col-lg-12">
                                                <div className="mb-3 mb-md-4">
                                                    <label className="form-label">Address</label>
                                                    <textarea name="address" value={userDetails.address} id="address" className="form-control" onChange={(e) => updateSelectedUserDataByObj(e)}></textarea>
                                                </div>
                                            </div>
                                        <div className="col-md-1"></div>
                                            <div className="col-md-10"><button type="button" className="btn-1 w-100" onClick={updateSelectedUserData}>Update Profile</button></div>
                                            <div className="col-md-1"></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)

}
export default UpdateProfile