import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import  AdminNavigation  from './AdminNavigation';
import { useNavigate } from "react-router-dom";
function UpdateSettingsData(){
    const [logo, setLogourl] = useState('');
    const [id, setID] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        setID(localStorage.getItem('ID'))
        setLogourl(localStorage.getItem('Logo Url'));
       
}, []);


const TOKEN = localStorage.getItem('adminToken');
    console.log(TOKEN);
    const updateSelectedSettingData = () => {
     let res =   axios.put(`${process.env.REACT_APP_API_URL}/setting/${id}`, {
        logo}, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      });
    if(res){
            console.log(res);
           // alert("updated"+res);
           // window.location.href = "/admin/pages";
           navigate("/admin/settings");
        }else{
          console.log(res);
           //alert("failed to update");
        }
    }
    return(<>
     <div id="wrapper" className="toggled">
   <AdminNavigation/>
        
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mx-auto"></div>
                <h2>Update Logo</h2>
                <form>
                    <label>Logo:</label>
                    <input type="text" name="logo" id="logo" className="form-control" placeholder="Enter Logo URL" value={logo} onChange = {(e) => setLogourl(e.target.value)} required/><br/>
                    <input type="submit" onClick={updateSelectedSettingData} className="btn btn-primary mb-2" id='update_logo' value="Update Logo"/>

                </form>
    </div>
    </div>
    </div>
    </div>
    </>)

}
export default UpdateSettingsData