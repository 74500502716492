import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import commonFunction from "../common/commonFunction";

const EditGift = ({ open, onClose, giftId, giftData }) => {
  const API_URL = `${process.env.REACT_APP_API_URL}`;
  const TOKEN = localStorage.getItem("adminToken");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageError, setImageError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [getCategories, setGetCategories] = useState([]);

  const [categoryError, setCategoryError] = useState("");

  useEffect(() => {
    if (giftData) {
      setTitle(giftData.title || "");
      setDescription(giftData.description || "");
      setImage(giftData.image || "");
      setPrice(giftData.price || "");
      setCategory(giftData.categoryId || "");
    }
  }, [giftData]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await commonFunction.fetchCategoryData();
        setGetCategories(response.data || []);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      setTitle(value);
    } else if (name === "description") {
      setDescription(value);
    } else if (name === "price") {
      setPrice(value);
    } else if (name === "categoryId") {
      setCategory(value);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImageFile(file);
      setImageError("");
    } else {
      setImageFile(null);
      setImageError("Please select a valid image file.");
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!title.trim()) {
      setTitleError("Title cannot be empty");
      isValid = false;
    } else {
      setTitleError("");
    }
    if (!description.trim()) {
      setDescriptionError("Description cannot be empty");
      isValid = false;
    } else {
      setDescriptionError("");
    }
    if (!price.trim()) {
      setPriceError("Price cannot be empty");
      isValid = false;
    } else {
      setPriceError("");
    }
    if (!category.trim()) {
      setCategoryError("Category cannot be empty");
      isValid = false;
    } else {
      setCategoryError("");
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("price", price);
        formData.append("categoryId", category ? category : "");
        formData.append("image", imageFile);
        const res = await axios.put(
          `${API_URL}/gifts/update/${giftId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        );
        console.log("Gift Update response", res);
        onClose();
        window.location.reload();
      } catch (error) {
        console.error("Error updating gift:", error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal large-dialog">
      <form onSubmit={handleSubmit}>
        <DialogTitle>Edit Gift</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Title"
            name="title"
            value={title}
            onChange={handleChange}
            variant="outlined"
            margin="dense"
            error={Boolean(titleError)}
            helperText={titleError}
          />

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            name="description"
            value={description}
            onChange={handleChange}
            variant="outlined"
            margin="dense"
            error={Boolean(descriptionError)}
            helperText={descriptionError}
          />

          <TextField
            fullWidth
            label="Price"
            name="price"
            value={price}
            onChange={handleChange}
            variant="outlined"
            margin="dense"
            error={Boolean(priceError)}
            helperText={priceError}
          />

          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              className="form-control"
              id="category"
              name="categoryId"
              value={category}
              onChange={handleChange}
            >
              <option value="">Select Category</option>
              {getCategories?.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.title}
                </option>
              ))}
            </select>
            {categoryError && <div className="error m-1">{categoryError}</div>}
          </div>

          {image && (
            <img
              src={`${process.env.REACT_APP_API_URL}/${image}`}
              alt="Category Image"
              style={{ maxWidth: "15%", marginTop: "10px" }}
            />
          )}
          <div className="form-group">
            <input
              type="file"
              className="form-control"
              id="imageFile"
              name="imageFile"
              accept="image/*"
              onChange={handleImageChange}
            />
            {imageError && <div className="error m-1">{imageError}</div>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            Edit
          </Button>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditGift;
