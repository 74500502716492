import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/pages/`;

const SearchResults = () => {
  //const [results, setSearchResults] = useRef([]);
  const [results, setSearchResults] = useState([]);
  const [loading,setLoading] =useState(true);

  
  const callAPI = async (data) => {
    try {
      console.log("api", data)
      await axios.get(API_URL + `/search/${data}`).then((res) => {
        setSearchResults(res.data.data);
        console.log(res, 'res');
      }).catch((err) => {
        console.error('Error searching:', err);
      })
  
    } catch (error) {
      console.error('Error searching:', error);
    }finally{
      setLoading(false);
    }
  }

  useEffect(() => {
    const searchParams = window.location.href.split('/')[4]
    console.log(searchParams, 'searchParams');
    callAPI(searchParams);
  }, [results])
  if (loading) {
    // Render a loading overlay with the "Loading..." message
    return (
      <div className="loading-overlay">
        {/* <div className="loading-content">Loading...</div> */}
        <img src='/img/loader.gif'></img>
      </div>
    );
  }
  return (
    <div className='container'>
      <h2>Search Results</h2>
      {results.length === 0 ? (
        <p>Data not found</p>
      ) : (
        <ul>
          {results.map((result) => (
            <li key={result.id}>
              {result.title}
              <a href={`/${result.slug}`}>{result.bannertitle}</a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchResults;