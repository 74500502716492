import React from "react";
import { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from '../Pagination';
function RequestedCallListing(){
    // Define a loading state variable
const [loading, setLoading] = useState(true);
const [FormList,getVideoChatFormData] = useState([]);
const userId = localStorage.getItem('userId');
const [totalPages, setTotalPages] = useState(null); // Add this line for total pages
    const itemsPerPage = 10;
    // Assuming you have a state variable for the current page number
const [currentPage, setCurrentPage] = useState(1);
const [totalItems, setTotalItems] = useState(0);
console.log(userId,"userId data")
// const COUNT_API_URL = `${process.env.REACT_APP_API_URL}/svconsultation/list?user_id=${userId}&type=singlevacationconsulatant&pageNumber=${currentPage}&pageSize=${itemsPerPage}`;

const API_URL = `${process.env.REACT_APP_API_URL}/svconsultation/list?user_id=${userId}&type=singlevacationconsulatant`;


//Get admin token form the database for the token
const TOKEN = localStorage.getItem('adminToken');
//&pageNumber=1&pageSize=10

const [showPopup, setShowPopup] = useState(false); 
const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
const fetchVideoChatFormData = () => {
    // Set loading to true to indicate that data fetching is in progress
    setLoading(true);
    fetch(API_URL)
      .then((res) => res.json())
      .then((res) => {

        //console.log(res,"RESPONSEEEEEE");
        console.log(res['data'].data,"DAAAAATAAAA");
        // const totalCount = res['data'].data.length;
        getpaginatedData(currentPage)
        // console.log(totalCount, "totalCount");
        // console.log(responseData,"responseData");
        // Calculate total pages based on the totalCount and itemsPerPage
        // const totalPages = (totalCount / itemsPerPage);
        // console.log("totalCount", totalCount);
        // console.log("itemsPerPage", itemsPerPage);
        // console.log("Totpages", totalPages);
        // // Update the state variables
        // setTotalItems(totalCount);
        setTotalPages(totalPages);

      }).catch((error) => {
        // Handle any errors and hide the loader

        console.error('Error fetching user data:', error);
      }).finally(() => {
        // Hide the loader whether the request succeeds or fails
        setLoading(false);
      });

  }


useEffect(() => {
fetchVideoChatFormData()
}, [])

useEffect(() => {
// console.log("userlist", userslist);
}, [FormList])
// Render a loader conditionally based on the "loading" state

//console.log("UserList=========",FormList,"=========");

//Delete Functionality 
//console.log("TOKEN",TOKEN);
const delUserdata = (id) => {
//console.log(id);
if (window.confirm('Do You want to delete ?')) {
axios.delete(`${process.env.REACT_APP_API_URL}/svconsultation/delete/${id}`, {
  headers: {
    Authorization: `Bearer ${TOKEN}`,
  },
}).then(() => {
  fetchVideoChatFormData();
}).catch(error => {
    console.error("Error deleting user data:", error);
    // Handle error as needed
  });
}
}

const togglePopup = (id, name) => {
  setSelectedId(id);
  setSelectedName(name);
  setShowPopup(!showPopup);
}

const main_from_inner_wrap_style = {
    padding: "0px !important"
};

const handlePaginationClick = (newPage) => {
    console.log(newPage, 'newPage');
    setCurrentPage(newPage);
    getpaginatedData(newPage);
   
};



const getpaginatedData = async (newPage) => {
  fetch(`${process.env.REACT_APP_API_URL}/zoom/getMeetings/?translaterId=${userId}`, {
    headers: {
      'Authorization': `Bearer ${TOKEN}`, // Assuming you have a variable `token` holding the token value
      'Content-Type': 'application/json' // Add other headers if necessary
    }
  })
  .then(res => res.json())
  .then(res => {
    console.log(res.data, "RESPPPPPPP");
    const responseData = res.data;
    //console.log(res, "responseDataresponseData");
    getVideoChatFormData(responseData);
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
}


  const [ladieslisting, setLadiesListingData] = useState([]);
  // const filterParameter = 'gender';
  // const fetchLadiesData = async () => {
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/getfiltereduser`, {
  //       [filterParameter]: 'female'
  //     });
  //     //console.log(response,"responseresponseresponseresponse")
     
  //     setLadiesListingData(response.data.data);
  //     setLoading(false);
  //   } catch (error) {
  //     //console.error('ERROR:', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchLadiesData();
  // }, []);//Pass filter keys in our useeffect hook so based on the values it filters the data
  


 

  const handleAssignladySubmit =  async  (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const cahtId = formData.get('caht_id');
    const profilenumber_ladies = formData.get('lady_id');
    const meeting_id = formData.get('meeting_id');
  
    // console.log('caht_id:', cahtId);
    // console.log('lady_id:', profilenumber_ladies);
  
    if (profilenumber_ladies) {
      setError('');
      const TOKEN = localStorage.getItem('adminToken');
      
      const data = new URLSearchParams();
      data.append("ladiesId", "#FCW_" + profilenumber_ladies);
  
      try {
        // Show loading state
        setLoading(true);
  
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/zoom/updateMeetingstatus/${meeting_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`
            }
          }
        );
  
       // console.log(response.data, "RESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS");
        setSuccessMessage('New Lady assigned successfully');
        // After updating, fetch the updated list of calls again
        fetchVideoChatFormData();
        setShowPopup(false);
      } catch (error) {
       // console.error(error);
        setError('Failed to assign new lady. Please try again.');
      } finally {
        // Hide loading state
        setLoading(false);
      }
    } else {
      setError('Please select a lady ID.');
    }
  }
  
  const getLadyName = async (LadyID) => {
    //console.log(LadyID);
    const OnlyLadyID = LadyID.replace('#FCW_', ''); // Remove #FCW_ from LadyID
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${OnlyLadyID}`);
      //console.log(response.data.data.fname,"responseresponseresponseresponse");
      return response.data.data.fname; // Assuming the lady's name is stored in the 'name' field
    } catch (error) {
      //console.error('Error retrieving lady data:', error);
      return null; // Return null if there's an error
    }
  };


  
  const handleNotifyClick = (VideoChatListData) => {
    //console.log(VideoChatListData,"VideoChatListDataVideoChatListDataVideoChatListDataVideoChatListData");

    let UserId = VideoChatListData.userId;
    let VideoID = VideoChatListData.svcId;
    let NameOfCallRequester = VideoChatListData.SvConsultation.fname + ' ' +  VideoChatListData.SvConsultation.lname;
    
  
    if (UserId) {
     // console.log(VideoID, "VideoIDVideoID");
      axios
        .get(`${process.env.REACT_APP_API_URL}/users/${UserId}`)
        .then(async (response) => {
          const email = response.data.data.email;
          const ladyName = await getLadyName(VideoChatListData.SvConsultation.profilenumber_ladies);
          const mailsubject =`Reminder to Join Video Call on ${VideoChatListData.meetingDate  },`
          // alert(email);
          // alert(ladyName);
          if (ladyName) {
           
            const MeetingInfo = `Dear ${NameOfCallRequester},<br/><br/>

            I hope this message finds you well.<br/><br/>
            
            I would like to confirm your scheduled video call with ${NameOfCallRequester} on ${VideoChatListData.meetingDate  }, at ${VideoChatListData.meetingTime}. The call is expected to last for ${VideoChatListData.meetingDuration } minutes.<br/><br/>
            
            Please join the meeting using the following link: <a href=${VideoChatListData.join_url}>Join Meeting</a>.<br/><br/>
            
            Thank you, and I look forward to your participation.`


            sendMail(email, MeetingInfo,mailsubject);
          } else {
           // console.error('Lady data not found');
            // Handle case where lady data is not found
          }
        })
        .catch((error) => {
          console.error('Error retrieving user data:', error);
          // Handle error retrieving user data
        });
    }
  };
  const sendMail = async (email,html,mailsubject) => {
  
    try {
      const mailData = {
        mailid: email,
        subject: mailsubject,
        html: html,
      };
        console.log(mailData,"mailData")
        console.log(TOKEN,"TOKEN")
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/svconsultation/sendCustomMail`, mailData, {
        headers: {
            Authorization: `Bearer ${TOKEN}`,
           },
      });

      //console.log('Mail sent:', response);
      setSuccessMessage('Email Sent successfully');
    } catch (error) {
      //console.error('Error sending mail:', error);
      // Handle error sending email
    }
  };


const GetUsersSelectedLadyListing = async (SelectedCallID) =>{
  
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/svconsultation/get/${SelectedCallID}`,{
      headers: {
        Authorization: `Bearer ${TOKEN}`,
       },
    });
    //console.log(response.data.data.otherLadies,"responseresponseresponseresponseresponseresponseresponseresponseresponseresponseresponseresponseresponseresponseresponseresponse");
    const data = response.data.data.otherLadies;
    if(data.length!==0 || data!=null){
      if (typeof data === 'string') {
        const ladiesArray = data.split(',').map((item, index) => {
          const idMatch = item.match(/#FCW_(\d+)/);
          const id = idMatch ? idMatch[1] : index; // Extract the id from the string, fallback to index if not found
          return { id}; // Mocked role for example purposes
        });
        setLadiesListingData(ladiesArray);
      }
    }else{
      setLadiesListingData('');
    }
   
  } catch (error) {
    console.error('Error retrieving lady data:', error);
    return null; // Return null if there's an error
  }

}

useEffect(()=>{
  GetUsersSelectedLadyListing();
},[])


  const handleAssignNewLady = (VideoChatListData) => {

    //console.log(VideoChatListData,"MOdal Data");
    //console.log(VideoChatListData.SvConsultation.id,"SvConsultation");
    setSelectedId(VideoChatListData.meetingId);
    setSelectedName(VideoChatListData.svcId);
    GetUsersSelectedLadyListing(VideoChatListData?.SvConsultation?.id);
   
    setShowPopup(true);
};
return (
<>
 <section className="page-info-sec">
<div className="bg-fixed" style={{ backgroundImage: "url('./img/inner_banner.png')" }}>
</div>
<div className="container ">
    <div className="row align-items-center justify-content-center">
        <div className="col">
            <div className="page-info-block">
                <h1>All Requested Calls Listing</h1>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur male</p> */}
                <p className="page_nav"> <a href="/">Home</a><span className="mx-2">/</span><a href="#" className="currentPage">All Requested Calls Listing</a></p>
            </div>
        </div>
    </div>
  
</div>
{/* Popup Form */}
{showPopup && ( <div className="modal" tabIndex="-1" role="dialog" style={{ display: showPopup ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
            
              <h5 className="modal-title">Assign Lady </h5><br/>
            
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={togglePopup}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <form onSubmit={handleAssignladySubmit}>
            
              <label>Lady Id:</label>
              {/* onChange={(e) => togglePopup(e.target.value, e.target.options[e.target.selectedIndex].text)}  */}
              {/* <select name="lady_id" className="form-control"  required>
              <option value="">Select a lady to assign</option>
              {ladieslisting.map((ladiesListData, key) => (
                ladiesListData.role !== 2 && // Exclude if role is equal to 2
                <option key={key} value={ladiesListData.id}>#FCW_{ladiesListData.id} {ladiesListData.fname}</option>
              ))}
              </select> */}

              <select name="lady_id" className="form-control" required>
      {ladieslisting.length === 0 ? (
        <option value="">No other lady found</option>
      ) : (
        <>
          <option value="">Select a lady to assign</option>
          {ladieslisting.map((ladiesListData, key) => (
            ladiesListData.role !== 2 && (
              <option key={key} value={ladiesListData.id}>#FCW_{ladiesListData.id} {ladiesListData.fname}</option>
            )
          ))}
        </>
      )}
    </select>


                <input type="hidden" name="caht_id" value={selectedName} />
                <input type="hidden" name="meeting_id" value={selectedId} />
                <input type="submit" name="assign_new_lady" value="Assign" className="mt-2 btn btn-primary"/>
            </form>
            </div>
            {error && <div className="text-danger">{error}</div>}
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={togglePopup}>Close</button>
              {/* Add any other buttons you need */}
            </div>
          </div>
        </div>
      </div>
      )}
</section>
<section className="video-chat-form-sec secpd70">
<div className="container myprofile_container">
 <div className="row">
     <div className="col-md-12">
        
         <div className="main-from-wrap">
             <div className="main-from-inner-wrap" style={main_from_inner_wrap_style}>
                 <div className="title-centered text-center">
                     <h2 className="title-30 mb-3">All Requested Calls Listing</h2>
                     
                 </div>
                 {successMessage && (
                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                      {successMessage}
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setSuccessMessage('')}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  )}

                 <div className="formwrapper">
                 <div className="table-responsive">
                 <table className="table table-light table-striped">
                    <thead>
                    <tr>
                        <th>Sno</th>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Age</th>
                        
                        <th>Occupation</th>
                        <th>Consultation Time</th>
                        <th>Call Type</th>
                        <th>Ladies Profile Id</th>
                        <th>Optional Ladies</th>
                        <th>Amount</th>
                        <th>Meeting Time</th>
                        <th>Meeting Date</th>
                        <th>Meeting Link</th>
                        <th>Assigned Translator</th>
                        <th>Meeting Status</th>
                        <th colSpan={3}>Action</th>
                        
                    </tr>
                    </thead>
                    <tbody>
    {Array.isArray(FormList) && FormList.map((VideoChatListData, key) => {
        return (
            <tr key={key}>
                <td>{key + 1}</td>
                
                <td>{VideoChatListData?.SvConsultation?.fname || 'N/A'}</td>
                <td>{VideoChatListData?.SvConsultation?.lname || 'N/A'}</td>
                <td>{VideoChatListData?.SvConsultation?.age || 'N/A'}</td>
                
                <td>{VideoChatListData?.SvConsultation?.occupation || 'N/A'}</td>
                <td>{VideoChatListData?.SvConsultation?.consultation_time || 'N/A'}</td>
                <td>{VideoChatListData?.type || 'N/A'}</td>
                <td>{VideoChatListData?.ladiesId || 'N/A'}</td>
                <td>{VideoChatListData?.otherLadies || 'N/A'}</td>
                <td>{VideoChatListData?.SvConsultation?.amount || 'N/A'}</td>
                <td>{VideoChatListData?.meetingTime || 'N/A'}</td>
                <td>{VideoChatListData?.meetingDate || 'N/A'}</td>
                <td><a className="btn btn-warning" href={VideoChatListData?.join_url} target="_blank">Join</a></td>
                
                <td>{VideoChatListData?.Translatordata?.fname || 'N/A'}</td>
                <td>{VideoChatListData?.status || 'N/A'}</td>
                <td><button className="btn btn-primary btn-sm" onClick={() => handleNotifyClick(VideoChatListData)}>Notify</button></td>
                <td colSpan={3}><button className="btn btn-primary btn-sm" onClick={() => handleAssignNewLady(VideoChatListData)}>Assign New Lady</button></td>
            </tr>
        );
    })}
</tbody>

                </table>
                
                    </div>
                    <Pagination currentPage={currentPage} totalPages={Math.ceil(totalItems / itemsPerPage)} handlePaginationClick={handlePaginationClick}
                    />
                 </div>
             </div>
         </div>
     </div>
 </div>
</div>
</section>


</>
)


}
export default RequestedCallListing