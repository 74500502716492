import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { isAuthenticated } from './auth'; // Import the isAuthenticated helper function
import  AdminNavigation  from './AdminNavigation';
function Settings(){
    useEffect(() => {
        // Check if 'userId' is not present in localStorage
        if (!localStorage.getItem('userRole') && localStorage.getItem('userRole')!== 1) {
          // Redirect to the "/login" page if 'userId' is not present
          window.location.href = "/login";
        }
      }, []); // The empty dependency array ensures that this effect runs once when the component mounts
  //Code for fetching users list
const [settinglist, getSettingsData]= useState([]);

const API_URL = `${process.env.REACT_APP_API_URL}/setting/getsetting`;

// Define a loading state variable
const [loading, setLoading] = useState(true);

const fetchSettingsData = ()=>{
    fetch(API_URL)
    .then((res)=>res.json())
    .then((res)=>{
       // console.log(res['data']);
        getSettingsData(res['data']);
    }).catch((error) => {
        // Handle any errors and hide the loader
        
        console.error('Error fetching user data:', error);
      }).finally(() => {
        // Hide the loader whether the request succeeds or fails
        setLoading(false);
      });

} 
useEffect(() => {
    fetchSettingsData()
  }, [])

useEffect(() => {
    //console.log("settingslist", settinglist);
}, [settinglist])

//Update functionality
const updateSettingsData = (updatesettingsdata)=>{
   // console.log("Update setting data",updatesettingsdata);
    let {id,logo} = updatesettingsdata;
       localStorage.setItem('ID', id);
       localStorage.setItem('Logo Url', logo);
       

}


const updateSliderSettingsData = (updatesettingsdata)=>{
    
    //console.log("Update setting data",updatesettingsdata);
    let {id,slider_img1,slider_img2,slider_img3} = updatesettingsdata;
    // console.log(slider_img1);
    // console.log(slider_img2);
    // console.log(slider_img3);
    // console.log("slides dataaaaa");
      
    localStorage.setItem('ID', id);
       localStorage.setItem('Slider Image1', slider_img1);
       localStorage.setItem('Slider Image2', slider_img2);
       localStorage.setItem('Slider Image3', slider_img3);
       

}

const updateFooterSettingsData = (updatesettingsdata)=>{
    //console.log("Update Footer setting data",updatesettingsdata);
    let {id,footer_section} = updatesettingsdata;
       localStorage.setItem('ID', id);
       localStorage.setItem('Footer Text', footer_section);
      
       

}


const updateSiteURLSettingsData = (updatesettingsdata)=>{
    let {id,site_url} = updatesettingsdata;
       localStorage.setItem('ID', id);
       localStorage.setItem('Site URL', site_url);

}
const updateAPIURLSettingsData = (updatesettingsdata)=>{
    let {id,api_url} = updatesettingsdata;
       localStorage.setItem('ID', id);
       localStorage.setItem('API URL', api_url);

}


const updateAdminEmailSettingsData = (updatesettingsdata)=>{
    let {id,admin_email} = updatesettingsdata;
       localStorage.setItem('ID', id);
       localStorage.setItem('Admin Email', admin_email);

}


const updateContactEmailSettingsData = (updatesettingsdata)=>{
    let {id,contact_email} = updatesettingsdata;
       localStorage.setItem('ID', id);
       localStorage.setItem('Contact Email', contact_email);

}

if (loading) {
    // Render a loading overlay with the "Loading..." message
    return (
      <div className="loading-overlay">
        <div className="loading-content">Loading...</div>
      </div>
    );
  }

    return (<>
    
    <div id="wrapper" className="toggled">
   <AdminNavigation/>
        
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-lg-offset-2">
                    <h3>Site Settings:</h3>
                      <table className="table table-dark table-striped">
                        <thead>
                        <tr>
                            <th>Sno</th>
                            <th>Logo Image</th>
                            
                            <th colSpan={2}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {settinglist.map((settinglistData, key) => {
                            return(

                        <tr>
                            <td>{key+1}</td>
                          
                            <td><img src={`${settinglistData.logo}`} style={{height: "100px"}}/></td>
                            
                          
                            
                            <td> <Link to='/admin/updatesettingdata'><button  onClick={() => updateSettingsData(settinglistData)} className='btn btn-primary'>Edit</button> </Link></td>
                           
                        </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-lg-offset-2">
                   
                    <h3>Slider Settings:</h3>
                      <table className="table table-dark table-striped">
                        <thead>
                        <tr>
                            <th>Sno</th>
                            <th>Slider Image 1</th>
                            <th>Slider Image 2</th>
                            <th>Slider Image 3</th>
                            
                            <th colSpan={2}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {settinglist.map((settinglistData, key) => {
                            return(

                        <tr>
                            <td>{key+1}</td>
                            <td>
                                <img src={`${settinglistData.slider_img1}`} alt="Slider Image 1" />
                            </td>
                            <td>
                                <img src={`${settinglistData.slider_img2}`} alt="Slider Image 1" />
                            </td>
                            <td>
                                <img src={`${settinglistData.slider_img3}`} alt="Slider Image 1" />
                            </td>
                            <td> <Link to='/admin/update_slider_settingdata'><button onClick={() => updateSliderSettingsData(settinglistData)} className='btn btn-primary'>Edit</button> </Link></td>
                           
                        </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-lg-offset-2">
                   
                    <h3>Footer Settings:</h3>
                      <table className="table table-dark table-striped">
                        <thead>
                        <tr>
                            <th>Sno</th>
                            <th>Footer Text</th>
                           
                            
                            <th colSpan={2}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {settinglist.map((settinglistData, key) => {
                            return(

                        <tr>
                            <td>{key+1}</td>
                            <td>
                               {settinglistData.footer_section}
                            </td>
                            
                            <td> <Link to='/admin/update_footer_settingdata'><button  onClick={() => updateFooterSettingsData(settinglistData)} className='btn btn-primary'>Edit</button> </Link></td>
                           
                        </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-lg-offset-2">
                   
                    <h3>URL:</h3>
                      <table className="table table-dark table-striped">
                        <thead>
                        <tr>
                            <th>Sno</th>
                            <th>Site URL</th>
                           
                            
                            <th colSpan={2}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {settinglist.map((settinglistData, key) => {
                            return(

                        <tr>
                            <td>{key+1}</td>
                            <td>
                               {settinglistData.site_url}
                            </td>
                            
                            <td> <Link to='/admin/update_site_url_settingdata'><button  onClick={() => updateSiteURLSettingsData(settinglistData)} className='btn btn-primary'>Edit</button> </Link></td>
                           
                        </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-lg-offset-2">
                   
                    <h3>API URL:</h3>
                      <table className="table table-dark table-striped">
                        <thead>
                        <tr>
                            <th>Sno</th>
                            <th>API URL</th>
                           
                            
                            <th colSpan={2}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {settinglist.map((settinglistData, key) => {
                            return(

                        <tr>
                            <td>{key+1}</td>
                            <td>
                               {settinglistData.api_url}
                            </td>
                            
                            <td> <Link to='/admin/update_api_url_settingdata'><button  onClick={() => updateAPIURLSettingsData(settinglistData)} className='btn btn-primary'>Edit</button> </Link></td>
                           
                        </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </div>

                <div className='row'>
                <div className="col-lg-12 col-lg-offset-2">
                <h3>Admin Email:</h3>
                <table className="table table-dark table-striped">
                        <thead>
                        <tr>
                            <th>Sno</th>
                            <th>Admin Email</th>
                           <th colSpan={2}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {settinglist.map((settinglistData, key) => {
                            return(

                        <tr>
                            <td>{key+1}</td>
                            <td>
                               {settinglistData.admin_email}
                            </td>
                            
                            <td> <Link to='/admin/update_admin_email_settingdata'><button  onClick={() => updateAdminEmailSettingsData(settinglistData)} className='btn btn-primary'>Edit</button> </Link></td>
                           
                        </tr>
                            )
                        })}
                        </tbody>
                    </table>

                </div>
                </div>

                <div className='row'>
                <div className="col-lg-12 col-lg-offset-2">
                <h3>Contact Email:</h3>
                <table className="table table-dark table-striped">
                        <thead>
                        <tr>
                            <th>Sno</th>
                            <th>Contact Email</th>
                           <th colSpan={2}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {settinglist.map((settinglistData, key) => {
                            return(

                        <tr>
                            <td>{key+1}</td>
                            <td>
                               {settinglistData.contact_email}
                            </td>
                            
                            <td> <Link to='/admin/update_contact_email_settingdata'><button  onClick={() => updateContactEmailSettingsData(settinglistData)} className='btn btn-primary'>Edit</button> </Link></td>
                           
                        </tr>
                            )
                        })}
                        </tbody>
                    </table>

                </div>
                </div>
            </div>
        </div>
    </div>
    </>);
}
export default Settings