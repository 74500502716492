import axios from "axios";
import React from "react";
import { useState, useEffect } from 'react';
import  AdminNavigation  from './AdminNavigation';
function UpdateFAQData(){
    const [question,setFaqQuestion] = useState("");
const [answer,setFaqAnswer] = useState("");
const [sort,setSortOrder]= useState("");
const [insertsuccess,setInsertSuccess]= useState("");
const [errormsg,setErrorMsg] = useState("");
const [id, setID] = useState(null);

useEffect(() => {
    setID(localStorage.getItem('ID'))
    setFaqQuestion(localStorage.getItem('Question'));
    setFaqAnswer(localStorage.getItem('Answer'));
    setSortOrder(localStorage.getItem('Sort'));
    
}, []);
const UpdateSelectedFAQData = (e)=>{

    e.preventDefault();
    if(question === "" || answer === "" || sort === ""){
        setErrorMsg("All Fileds Are Required");
        return false;
    }
    const API_URL = process.env.REACT_APP_API_URL;
    const TOKEN = localStorage.getItem('adminToken');
   // console.log(TOKEN);

   

    let response = axios.put(`${API_URL}/faq/update/${id}`,{question,answer,sort},{
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }});//URL and data in axios.put(``,{});
    if(response){
        //console.log("Updated Successfully");
        setInsertSuccess("FAQ Updated Successfully");

    }else{
        console.log();
        setErrorMsg("Failed to update data");
    }

}

    return(<><div id="wrapper" className="toggled">
    <AdminNavigation/>
         
         <div id="page-content-wrapper">
             {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                 <span className="hamb-top"></span>
                 <span className="hamb-middle"></span>
                 <span className="hamb-bottom"></span>
             </button> */}
             <div className='container'>
                 <div className="row">
                     <div className="col-md-6 mx-auto">
                     {insertsuccess && (
                         <div className="alert alert-success mt-3 text-center" role="alert">
                             {insertsuccess}
                         </div>
                     )}
                     {errormsg && (
                         <div className="alert alert-danger mt-3 text-center" role="alert">
                             {errormsg}
                         </div>
                     )}
                     <form>
                         <label>FAQ Question:</label>
                     <input type="text" name="question" value={question} onChange={(e)=>setFaqQuestion(e.target.value)} placeholder="Enter FAQ Question" className="form-control mb-2"></input>
                     <label>FAQ Answer:</label>
                     <input type="text" name="answer" value={answer} onChange={(e)=>setFaqAnswer(e.target.value)} placeholder="Enter FAQ Answer" className="form-control mb-2"></input>
                     <label>Sort Order:</label>
                     <input type="text" name="sort" value={sort} className="form-control mb-2" onChange={(e) => setSortOrder(e.target.value)}
 placeholder="Enter Sort Order"
                     />
                     <button type="submit" className="btn btn-primary" onClick={UpdateSelectedFAQData}>Update </button>
                 </form>
                     </div>
                 </div>
                
             </div>
             </div>
             </div></>);
}
export default UpdateFAQData