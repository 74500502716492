import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import  AdminNavigation  from './AdminNavigation';
import { useNavigate } from "react-router-dom";
function UpdateFooterSetting(){
    const[footer_section,setFooterText]=useState('');
    const navigate = useNavigate();
    const [id, setID] = useState(null);
    useEffect(() => {
        setID(localStorage.getItem('ID'));
        setFooterText(localStorage.getItem('Footer Text'));
       
       
}, []);
const TOKEN = localStorage.getItem('adminToken');
    console.log(TOKEN);
    const updateSelectedFooterSettingData = () => {
     let res =   axios.put(`${process.env.REACT_APP_API_URL}/setting/${id}`, {
        footer_section}, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      });
    if(res){
            console.log(res);
           // alert("updated"+res);
           // window.location.href = "/admin/pages";
           navigate("/admin/settings");
        }else{
          console.log(res);
           //alert("failed to update");
        }
    }

    return(<>
        <div id="wrapper" className="toggled">
      <AdminNavigation/>
           
           <div id="page-content-wrapper">
               {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                   <span className="hamb-top"></span>
                   <span className="hamb-middle"></span>
                   <span className="hamb-bottom"></span>
               </button> */}
               <div className="container">
                   <div className="row">
                       <div className="col-md-4 mx-auto"></div>
                   <h2>Update Footer Text</h2>
                   <form>
                      
                       <label>Footer Section:</label>
                       <textarea name="footer_section" id="footer_section" className="form-control" placeholder="Enter Footer Text" value={footer_section} onChange = {(e) => setFooterText(e.target.value)} required></textarea>
                       <br/>
                       <input type="submit" onClick={updateSelectedFooterSettingData} className="btn btn-primary mb-2" id='update_footer' value="Update Footer"/>
   
                   </form>
       </div>
       </div>
       </div>
       </div>
       </>)


}
export default UpdateFooterSetting