import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EyeIcon from "./EyeIcon"; // Import your EyeIcon component
function onChange(value) {
  console.log("Captcha value:", value);
}

function Register() {
  const navigate = useNavigate();
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/pages/slug/registration`
        );
        // console.log(response,"responseresponse");
        setPageContent(response.data.data["0"]);
      } catch (error) {
        console.error("Error fetching page content:", error);
      } finally {
        setLoading(false); // Set loading to false whether the request succeeds or fails
      }
    };
    fetchPageContent();
  }, []);

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState(""); // New state for gender
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for showing password

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tempUsername = username || email;
    // Validate form fields
    const validationErrors = {};
    if (!fname) {
      validationErrors.fname = "First name is required.";
    }
    if (!lname) {
      validationErrors.lname = "Last name is required.";
    }
    if (!dob) {
      validationErrors.dob = "Date of Birth is required.";
    }
    if (!email) {
      validationErrors.email = "Email address is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = "Invalid email address.";
    }
    if (!password) {
      validationErrors.password = "Password is required.";
    } else if (password.length < 12) {
      validationErrors.password =
        "Password must be at least 12 characters long.";
    } else if (!/[a-z]/.test(password)) {
      validationErrors.password =
        "Password must contain at least one lowercase letter.";
    } else if (!/[A-Z]/.test(password)) {
      validationErrors.password =
        "Password must contain at least one uppercase letter.";
    } else if (!/[0-9]/.test(password)) {
      validationErrors.password = "Password must contain at least one number.";
    } else if (!/[$@!%*?&]/.test(password)) {
      validationErrors.password =
        "Password must contain at least one symbol ($, @, !, %, *, ?, or &).";
    }
    if (!gender) {
      validationErrors.gender = "Gender field is required.";
    }

    // Display error messages, if any
    setErrors(validationErrors);

    // Clear errors after 10 seconds
    setTimeout(() => {
      setErrors({});
    }, 10000); // 10000 milliseconds = 10 seconds

    // If there are no validation errors, proceed with form submission
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      setFormSubmitted(false);

      const user = {
        fname,
        lname,
        email,
        password,
        dob,
        username: tempUsername,
        gender,
      }; // Include gender

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/users/registration`,
          user,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 1) {
          setFormSubmitted(true);

          // Email functionality after successful registration
          // const subject = "Welcome to Our Service!";
          // const html = `<p>Hello <b>${fname} ${lname}</b>,<br> Thank you for registering with us!</p>`;
          // const mail_data = { mailid: email, subject, html, notify_admin: 1 };

          // await axios.post(
          //   `${process.env.REACT_APP_API_URL}/svconsultation/sendCustomMailwoToken`,
          //   mail_data,
          //   {
          //     headers: {
          //       "Content-Type": "application/json",
          //     },
          //   }
          // );
        } else {
          alert(
            response?.data?.result ||
              response?.data?.result[0] ||
              response?.data?.result.join(", ")
          );
        }
        setLoading(false);
        //setFormSubmitted(true);

        // Handle success or redirect to a success page
      } catch (error) {
        //console.error('Error during registration:', error);
        //console.log('responseDATAAAAAA', error.response.data.msg.fname);

        validationErrors.fname = error.response?.data?.msg.fname;
        validationErrors.lname = error.response?.data?.msg.lname;
        setLoading(false);
        // Handle error or display error message
        setFormSubmitted(false);
        setErrors(validationErrors);

        if (error.response && error.response.data) {
          const serverError = error.response.data;
          // setServerError(serverError.result[0]);
          setErrors((prevErrors) => ({
            ...prevErrors,
            form: serverError.result.join(", "),
          }));
        } else {
          setFormSubmitted(true);
        }
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  //Added given code for the redirection on the login page
  useEffect(() => {
    if (formSubmitted) {
      const timer = setTimeout(() => {
        navigate("/login");
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or if formSubmitted changes
    }
  }, [formSubmitted, navigate]);

  useEffect(() => {
    if (formSubmitted) {
      //Email functionality for sending email
      const mailid = email;

      const subject = "Welcome to Our Service!";
      const html = `<p>Hello <b>${fname} ${lname}</b>,<br> Thank you for registering with us!</p>`;

      const notify_admin = 1;

      const mail_data = { mailid, subject, html, notify_admin };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/svconsultation/sendCustomMailwoToken`,
          mail_data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((mail_response) => {
          navigate("/welcome");
          alert(mail_response?.data?.msg);
        })
        .catch((err) => console.log(err));
    }
  }, [formSubmitted, fname, lname]);

  return (
    <>
      <div className="contant">
        <section className="LoginMain">
          <div className="loginIn">
            <div
              className="loginBg registerPageBg"
              style={{
                backgroundImage: `url(${pageContent && pageContent.bannerurl})`,
              }}
            ></div>
            <div className="loginHalf">
              <div className="loginForm">
                <div className="LoginTitle">
                  <h1>{pageContent ? pageContent.bannertitle : "Register"}</h1>
                  <p>{pageContent ? pageContent.bannerdesc : ""}</p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="mb-3 mb-md-3 col-12 col-md-6">
                      <label className="form-label">
                        First Name<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="fname"
                        id="fname"
                        placeholder="David"
                        className="form-control"
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                      />
                      {errors.fname && (
                        <p className="text-danger">{errors.fname}</p>
                      )}
                    </div>
                    <div className="mb-3 mb-md-3 col-12 col-md-6">
                      <label className="form-label">
                        Last Name<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Parker"
                        className="form-control"
                        name="lname"
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                      />
                      {errors.lname && (
                        <p className="text-danger">{errors.lname}</p>
                      )}
                    </div>
                    <div className="mb-3 mb-md-3 col-12">
                      <label className="form-label">
                        Date of Birth<span className="required">*</span>
                      </label>
                      <input
                        type="date"
                        placeholder="DD/MM/YYYY"
                        className="form-control"
                        name="dob"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                      />
                      {errors.dob && (
                        <p className="text-danger">{errors.dob}</p>
                      )}
                    </div>
                    <div className="mb-3 mb-md-3 col-12">
                      <label className="form-label">
                        Email Address<span className="required">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="DavidP@xyz.com"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.email && (
                        <p className="text-danger">{errors.email}</p>
                      )}
                    </div>
                    <div className="mb-3 mb-md-3 col-12">
                      <label className="form-label">Gender</label>
                      <select
                        className="form-control"
                        name="gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      {errors.gender && (
                        <p className="text-danger">{errors.gender}</p>
                      )}
                    </div>
                    <div className="mb-3 mb-md-3 col-12">
                      <label className="form-label">Username (optional)</label>
                      <input
                        type="text"
                        placeholder="DavidP@xyz.com"
                        className="form-control"
                        name="username"
                        value={username || email}
                        onChange={(e) => setUsername(e.target.value || email)}
                      />
                      <p className="small_info">
                        Limit of 15 characters (including a lowercase letter,
                        uppercase letter, number, and symbol)
                      </p>
                    </div>
                    {/* <div className="mb-3 mb-md-3 col-12">
                      <label htmlFor="exampleInputPassword1" className="form-label">Password<span className="required">*</span></label>
                      <input type="password" placeholder="*********" className="form-control" id="exampleInputPassword1" name='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                      <p className="small_info">Limit of 12 characters (including a lowercase letter, uppercase letter, number, and symbol)</p>
                      {errors.password && <p className="text-danger">{errors.password}</p>}
                      <button type="button" className="password-toggle-btn" onClick={togglePasswordVisibility}>
                      <EyeIcon /> {/* Replace EyeIcon with your SVG or component */}
                    {/* </button>
                    </div> */}
                    <div className="mb-3 mb-md-3 col-12">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Password<span className="required">*</span>
                      </label>
                      <div className="password-input">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="*********"
                          className="form-control"
                          id="exampleInputPassword1"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                          type="button"
                          className="password-toggle-btn"
                          onClick={togglePasswordVisibility}
                        >
                          <EyeIcon />
                        </button>
                      </div>
                      <p className="small_info">
                        Limit of 12 characters (including a lowercase letter,
                        uppercase letter, number, and symbol)
                      </p>
                      {errors.password && (
                        <p className="text-danger">{errors.password}</p>
                      )}
                    </div>
                  </div>
                  <div className="not-a-robot">
                    <ReCAPTCHA
                      sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      onChange={onChange}
                    />
                  </div>
                  <button type="submit" className="btn-1" disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                  </button>
                </form>

                {formSubmitted && (
                  <div className="alert alert-success mt-3" role="alert">
                    Registration successful! You may now log in using your
                    credentials.
                  </div>
                )}
                {errors.form && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {errors.form}
                  </div>
                )}
              </div>
              <div className="bottom-signin">
                <p>
                  Already a Member <Link to="/login">Login</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Register;
