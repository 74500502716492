import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { FacebookShareButton, WhatsappShareButton } from "react-share";

function VideoPlayList() {
  const navigate = useNavigate();
  const [showShareOptions, setShowShareOptions] = useState(false);

  const handleShareButtonClick = () => {
    setShowShareOptions(!showShareOptions);
  };

  const TOKEN = localStorage.getItem("adminToken");

  // State variable to manage the selected sort option
  const [filterKey, setFilterKey] = useState("");
  const [sortKey, setSortKey] = useState("");

  // Function to handle the change in the sort option
  const handleSortChange = (e) => {
    // console.log(e.target.value,"Target value");
    setSortKey(e.target.value);
    fetchVideosData();
  };

  const handleFilterChange = (event) => {
    setFilterKey(event.target.value);
    fetchVideosData();
    //console.log(event.target.value,"setFilterKey values");
  };

  //This use effect is used for checking if user id is not in the localstorage so it will prevent direct access from the database.
  useEffect(() => {
    // Check if 'userId' is not present in localStorage
    if (!localStorage.getItem("userId")) {
      // Redirect to the "/login" page if 'userId' is not present
      window.location.href = "/login";
    }
  }, []);

  //Code for fetching users list
  const [videolist, getVideosData] = useState([]);
  const [playVideoId, setPlayVideoId] = useState();
  const API_URL = `${process.env.REACT_APP_API_URL}/video/getlist/?sort_key=${filterKey}&order_key=${sortKey}`;
  // const fetchVideosData = () => {
  //   fetch(API_URL)
  //     .then((res) => res.json())
  //     .then((res) => {
  //       // console.log(res['data'][0].id,"ressssss daaaaarar")
  //       // console.log(res.data[0].url);
  //       getVideosData(res["data"]);
  //       if (res.data.length) {
  //         const vId = getVideoIdFromUrl(res.data[0].url);
  //         setPlayVideoId(vId);
  //       }
  //     });
  // };

  const fetchVideosData = async () => {
    try {
      const response = await fetch(API_URL);
      const res = await response.json();
      // console.log(res['data'][0].id,"ressssss daaaaarar")
      // console.log(res.data[0].url);
      getVideosData(res["data"]);
      if (res.data.length) {
        const vId = getVideoIdFromUrl(res.data[0].url);
        setPlayVideoId(vId);
      }
    } catch (error) {
      console.error('Error fetching videos data:', error);
      console.error('Error fetching user data:', error);
            if (error.res.status === 401) {
              navigate("/login");
            }
    }
  };
  
  useEffect(() => {
    fetchVideosData();
  }, [filterKey, sortKey]);

  // console.log("Video List", videolist);

  const singleVideo = videolist[0]; // Access the first item in the array

  // Function to extract video ID from YouTube URL
  const getVideoIdFromUrl = (url) => {
    try {
      const urlObject = new URL(url);
      //console.log("URL", urlObject);
      //const videoId = urlObject.searchParams.get('v');
      const videoId = urlObject.pathname;
      //console.log("videoId",videoId);
      if (videoId) {
        // console.log(videoId, 'videoId')

        return videoId;
      }
    } catch (error) {
      console.error("Error extracting video ID:", error);
    }
    return null;
  };

  //Code for fetching users list
  const [videocommentslist, getVideosCommentsData] = useState([]);
  // console.log(videocommentslist, "videocommentslist");
  //console.log(playVideoId,"playVideoId");
  const [CommentParentID, getCommnetParentID] = useState(0);
  const fetchVideosCommentsData = (playVideoId) => {
    //console.log(playVideoId,"playVideoId data ")
    const VIDEO_COMMENTS_API_URL = `${process.env.REACT_APP_API_URL}/commentsvideo/?video_id=${playVideoId}&parent_id=${CommentParentID}`;
    fetch(VIDEO_COMMENTS_API_URL)
      .then((res) => res.json())
      .then((res) => {
        //console.log("Comments data",res);
        getVideosCommentsData(res.data);
      });
  };
  useEffect(() => {
    fetchVideosCommentsData();
  }, []);

  const handleVideoPlay = (e, videolistData) => {
    //console.log(videolistData,"videolistDatavideolistData")
    const vId = getVideoIdFromUrl(videolistData.url);
    setPlayVideoId(vId);
    fetchVideosCommentsData(videolistData.id);
    setVideoId(videolistData.id);
  };

  const [comment_holder_name, setCommentHoldername] = useState([]);
  const getUserInfoByUserId = (UserID) => {
    // console.log("UserIDUserIDUserIDUserID",UserID)
    const GETUSERINFO_API_URL = `${process.env.REACT_APP_API_URL}/users/${UserID}`;
    fetch(GETUSERINFO_API_URL)
      .then((res) => res.json())
      .then((res) => {
        // console.log("UserData data",res.data.fname);
        setCommentHoldername(res.data.profile_pic);
        // return res.data.fname;
      });
  };

  // Filter the videolist array based on the selected sort option
  // const filteredVideoList = () => {
  //   switch (sortBy) {
  //     case "recentlyAdded":
  //       return [...videolist].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  //     // Add more cases for other sorting options if needed
  //     default:
  //       return videolist;
  //   }
  // };

  /**
   * Insert commnets to a video starts here
   */
  const [message, setComment] = useState("");
  const [video_id, setVideoId] = useState("");
  const user_id = localStorage.getItem("userId");
  const parent_id = "0";

  const handleCommentSubmit = () => {
    if (message.trim() !== "") {
      const commentData = {
        video_id: video_id,
        user_id: user_id,
        message: message,
        parent_id: parent_id,
      };

      //console.log(commentData, "commentDatacommentData");

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/commentsvideo/add`,
          commentData,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        )
        .then((response) => {
          //console.log('Comment added successfully:', response.data);
        })
        .catch((error) => {
          //console.error('Error adding comment:', error);
        });
    } else {
      console.log("Please enter a comment before submitting.");
    }
  };

  /**
   * Insert commnets to a video ends here
   */

  const handelCommnetDelete = async (commentId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this comment?"
    );

    if (!confirmed) {
      return; // Do nothing if the user cancels the deletion
    }
    try {
      // Make a DELETE request to your API endpoint
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/commentsvideo/delete/${commentId}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`, // Replace with your actual access token
          },
        }
      );
      console.log("Comment deleted successfully");
      fetchVideosCommentsData(video_id);
      fetchVideosSubCommentsData(video_id);
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedMessage, setEditedMessage] = useState("");
  // Function to initiate the edit process
  const handleEditClick = (commentId, originalMessage) => {
    setEditingCommentId(commentId);
    setEditedMessage(originalMessage);
    setReplyingToCommentId(null); // Ensure reply mode is disabled when editing
    fetchVideosCommentsData(video_id);
    fetchVideosSubCommentsData(video_id);
  };

  // Function to handle canceling the edit process
  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditedMessage("");
  };
  const handleReplyClick = (commentId) => {
    setReplyingToCommentId(commentId);
    setEditingCommentId(null); // Ensure editing mode is disabled when replying
  };

  // Function to handle submitting the edited comment
  const handleEditSubmit = (commentId) => {
    // Send a request to the server to update the comment content

    const user_id = localStorage.getItem("userId");
    const parent_id = "0";
    const updatedCommentData = {
      video_id: video_id,
      user_id: user_id,
      message: editedMessage,
      parent_id: parent_id,
    };
    console.log(updatedCommentData, "updatedCommentData");

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/commentsvideo/update/${commentId}`,
        updatedCommentData,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      )
      .then((response) => {
        console.log("Comment edited successfully:", response.data);
        fetchVideosCommentsData(video_id);
        fetchVideosSubCommentsData(video_id);

        // Update the UI with the edited comment
        // You may need to fetch updated comments from the server or update the state directly
        setEditingCommentId(null);
        setEditedMessage("");
      })
      .catch((error) => {
        console.error("Error editing comment:", error);
      });
  };

  const [replyingToCommentId, setReplyingToCommentId] = useState(null);
  const [replyMessage, setReplyMessage] = useState("");

  //Reply on comment
  const handelreplyComments = (commentId, userId) => {
    setReplyingToCommentId(commentId);
    fetchVideosCommentsData(video_id);
    fetchVideosSubCommentsData(video_id);
  };

  // useEffect(()=>{
  //   handelreplyComments()
  // },[])

  const handleReplySubmit = () => {
    if (replyMessage.trim() !== "") {
      const replyData = {
        video_id: video_id,
        user_id: user_id,
        message: replyMessage,
        parent_id: replyingToCommentId,
      };
      console.log(replyData, "replyDatareplyDatareplyDatareplyDatareplyData");
      axios
        .post(`${process.env.REACT_APP_API_URL}/commentsvideo/add`, replyData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        })
        .then((response) => {
          setReplyingToCommentId(null);
          setReplyMessage("");
          fetchVideosCommentsData(video_id);
          fetchVideosSubCommentsData(video_id);
        })
        .catch((error) => {
          console.error("Error adding reply:", error);
        });
    } else {
      console.log("Please enter a reply message before submitting.");
    }
  };

  /**
   * Like Functionality for video section starts here
   */

  const [isLiked, setIsLiked] = useState(false);
  const [isUnLiked, setIsUnLiked] = useState(false);
  const [likedById, getLikedById] = useState([]);
  const handleLikeClick = async () => {
    try {
      const TOKEN = localStorage.getItem("adminToken");
      const user_id = localStorage.getItem("userId");
      const LikeapiEndpoint = `${process.env.REACT_APP_API_URL}/likeunlike/add`;

      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          // Add any other headers as needed
        },
      };

      const data = {
        video_id: video_id,
        user_id: user_id,
        like: "1",
        unlike: "0",
      };

      console.log("Request Data:", data);
      //console.log('Request Config:', config);

      const like_response = await axios.post(LikeapiEndpoint, data, config);
      //getLikedById(like_response.data.data.list[0].user_id);
      console.log("API Response:", like_response.data);
      // console.log('Liked by id:', like_response.data.data.list[0].user_id);

      // Handle success, update local state, etc.
      setIsLiked(!isLiked);
    } catch (error) {
      // Handle error, show a message to the user, etc.
      console.error("There was a problem with the Axios request:", error);
    }
  };

  /**
   * Like Functionality for video section ends here
   */

  /*** Unlike functionality starts here */
  const handelUnlike = async () => {
    try {
      const TOKEN = localStorage.getItem("adminToken");
      const user_id = localStorage.getItem("userId");
      const unlikeapiendpoint = `${process.env.REACT_APP_API_URL}/likeunlike/add`;
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          // Add any other headers as needed
        },
      };
      const unlike_data = {
        video_id: video_id,
        user_id: user_id,
        like: "0",
        unlike: "1",
      };

      const unlike_response = await axios.post(
        unlikeapiendpoint,
        unlike_data,
        config
      );

      console.log("Unlike API Response:", unlike_response.data);

      setIsUnLiked(!isUnLiked);
    } catch (error) {
      console.error(
        "There was a problem with the Axios request for unlike:",
        error
      );
    }
  };
  /*** Unlike functionality ends here */

  /**Get all like unlike listing starts here */
  //console.log(video_id,"video_id for all video");
  const [video_like_status, getVideoLikeStatus] = useState(null);
  const [video_unlike_status, getVideoUnlike] = useState(null);
  const [currentCommentId, setCurrentCommentId] = useState(null);
  const [subCommentData, setSubCommentData] = useState(null);
  const [count, setCount] = useState({});

  const getVideoLikeUnlikeInfo = async () => {
    try {
      const GetVideoInfoAPI = `${process.env.REACT_APP_API_URL}/likeunlike/list/?key=like&video_id=${video_id}`;
      const GetVideoUnlikeInfoAPI = `${process.env.REACT_APP_API_URL}/likeunlike/list/?key=unlike&video_id=${video_id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          // Add any other headers as needed
        },
      };
      const get_video_like_status = await axios.get(GetVideoInfoAPI, config);
      const get_video_unlike_status = await axios.get(
        GetVideoUnlikeInfoAPI,
        config
      );
      const dynamicIndex = 0;
      //console.log(get_video_like_status.data.data,"GetVideoInfoAPI");
      //console.log(get_video_like_status.data.data.list[dynamicIndex].like,"likeeeeeeeee");
      getVideoLikeStatus(get_video_like_status.data.data.count);
      getLikedById(get_video_like_status.data.data.list);
      getVideoUnlike(get_video_unlike_status.data.data.count);
      //console.log(likedById,"likedByIdlikedByIdlikedById");
    } catch (error) {}
  };
  useEffect(() => {
    getVideoLikeUnlikeInfo();
  }, [video_id]);

  /**Get all like unlike listing ends here */
  // console.log(likedById, "likedddd by");

  // subcomment section

  const fetchVideosSubCommentsData = (commmentId) => {
    //console.log(playVideoId,"playVideoId data ")
    const VIDEO_COMMENTS_API_URL = `${process.env.REACT_APP_API_URL}/commentsvideo/?video_id=${video_id}&parent_id=${commmentId}`;
    fetch(VIDEO_COMMENTS_API_URL)
      .then((res) => res.json())
      .then((res) => {
        setSubCommentData(res.data);
        setCount((prevCounts) => ({
          ...prevCounts,
          [commmentId]: res?.data?.length || 0,
        }));
      });
  };

  const [subCommentDisplayCount, setSubCommentDisplayCount] = useState({});
  const [subCommentViewMode, setSubCommentViewMode] = useState({});

  const SUB_COMMENT_THRESHOLD = 3;

  const handleSubComment = (e, commentId) => {
    e.preventDefault();
    // Toggle the current comment ID for the sub-comment section
    setCurrentCommentId((prevId) => (prevId === commentId ? null : commentId));
    fetchVideosSubCommentsData(commentId);

    // Toggle the view mode for the given commentId
    setSubCommentViewMode((prevMode) => {
      const newMode = !prevMode[commentId];
      // If the view mode changes to showing more, initialize the display count
      if (newMode) {
        setSubCommentDisplayCount((prevCount) => ({
          ...prevCount,
          [commentId]: SUB_COMMENT_THRESHOLD,
        }));
      }
      return {
        ...prevMode,
        [commentId]: newMode,
      };
    });
  };

  const handleViewToggle = (commentId) => {
    setSubCommentViewMode((prevMode) => {
      const newMode = !prevMode[commentId];
      // Adjust the display count according to the new mode
      setSubCommentDisplayCount((prevCount) => ({
        ...prevCount,
        [commentId]: newMode ? subCommentData?.length : SUB_COMMENT_THRESHOLD,
      }));
      return {
        ...prevMode,
        [commentId]: newMode,
      };
    });
  };

  return (
    <>
      {/* {pageContent.pagedesc && (<div dangerouslySetInnerHTML={{ __html: pageContent.pagedesc }} ></div>)}  */}
      <section className="video-playlist-sec secpd70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-9 left_content_wrap">
              <div className="video-playbox">
                <div className="vp-videowrap">
                  <iframe
                    className="w-100"
                    src={`https://www.youtube.com/embed${playVideoId}`}
                  ></iframe>
                  {/* <img src={`https://img.youtube.com/vi${getVideoIdFromUrl(singleVideo?.url)}/default.jpg`}
                          alt="" class="w-100"
                        /> */}
                </div>
                <div className="vp-content">
                  <div className="vp_top_bar">
                    <div className="vp_top_left">
                      <p>{singleVideo?.title} </p>
                      {/**Getting userid and liked id and check if both are same disply in liked */}

                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        {isLiked ? (
                          <>
                            <span>
                              {likedById} {video_like_status} Liked
                            </span>
                            {/* Additional content or styling for liked videos */}
                          </>
                        ) : (
                          <>
                            {video_like_status > 0 ? (
                              <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleLikeClick}
                                disabled
                              >
                                <span>
                                  {video_like_status} &nbsp;
                                  {likedById.map((likedItem) => {
                                    if (likedItem.user_id == user_id) {
                                      return (
                                        <img
                                          src="./img/thumbs-up-blue.png"
                                          className="liked_img"
                                          alt=""
                                        />
                                      );
                                    }
                                    return null; // You can also omit this line if you want to skip rendering for non-matching items
                                  })}
                                  {/* <img src="./img/thum-up.png" alt="" /> */}
                                </span>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleLikeClick}
                              >
                                <img src="./img/thum-up.png" alt="" />
                              </button>
                            )}
                          </>
                        )}

                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={handelUnlike}
                        >
                          {isUnLiked ? (
                            <>
                              <span>{video_unlike_status} </span>
                              {/* Additional content or styling for unliked videos */}
                            </>
                          ) : (
                            <>
                              {video_unlike_status > 0 ? (
                                <span>
                                  {video_unlike_status} &nbsp;
                                  <img
                                    src="./img/thum-down-blue.png"
                                    alt=""
                                    className="unlike_thumb"
                                  />
                                </span>
                              ) : (
                                <img src="./img/thum-down.png" alt="" />
                              )}
                            </>
                          )}
                        </button>
                      </div>

                      <div className="share-buttons">
                        <button
                          type="button"
                          className="btn btn-light rounded-5"
                          onClick={handleShareButtonClick}
                        >
                          <img src="./img/share.png" alt="" /> Share
                        </button>

                        {showShareOptions && (
                          <div className="share-options">
                            <FacebookShareButton
                              url={`https://www.youtube.com/embed${playVideoId}`}
                              quote={singleVideo?.title}
                              className="btn btn-primary"
                            >
                              <FontAwesomeIcon icon={faFacebook} />
                            </FacebookShareButton>

                            <a
                              href={`https://www.instagram.com/share?url=https://www.youtube.com/embed${playVideoId}`}
                              className="btn btn-primary"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon icon={faInstagram} />
                            </a>

                            {/* Replace TwitterShareButton with TikTokShareButton */}
                            {/* Note: TikTok sharing is not as standardized, and you may need to provide a specific TikTok sharing URL */}
                            <button
                              onClick={() => {
                                // Handle TikTok sharing logic here
                                // You may need to create a custom TikTok sharing URL
                              }}
                              className="btn btn-primary"
                            >
                              <FontAwesomeIcon icon={faTiktok} />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="vp_top_right">
                      <ul>
                        <li>
                          <img src="./img/fa-clock.png" alt="" />{" "}
                          {singleVideo?.createdAt}
                        </li>
                        <li>
                          <img src="./img/fa-calendar.png" alt="" />{" "}
                          {singleVideo?.createdAt}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>{singleVideo?.title}</p>
                  <hr className="form-divider" />

                  {/* Comment Section Start */}

                  {
                    <div className="comment-wrap">
                      <img src="./img/comment.png" alt="" className="leftImg" />

                      <input
                        type="text"
                        placeholder="Add a comment...."
                        className="form-control"
                        name="message"
                        value={message}
                        onChange={(e) => setComment(e.target.value)}
                      />
                      <button className="sendbtn" onClick={handleCommentSubmit}>
                        <svg
                          width="29"
                          height="24"
                          viewBox="0 0 29 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.678223 24L28.6782 12L0.678223 0V9.33333L20.6782 12L0.678223 14.6667V24Z"
                            fill="#1167C9"
                          />
                        </svg>
                      </button>
                    </div>
                  }
                  <br />

                  {/* <hr className="form-divider" /> */}
                  {videocommentslist &&
                    videocommentslist?.map((comment, index) => (
                      <div className="card-body p-4" key={index}>
                        <div className="row">
                          <div className="col">
                            <div className="d-flex flex-start">
                              <img
                                className="rounded-circle shadow-1-strong me-3"
                                src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                                alt="avatar"
                                width="40"
                                height="40"
                              />
                              <div
                                className="flex-grow-1 flex-shrink-1"
                                style={{ fontSize: "16px" }}
                              >
                                <div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <p
                                      className="mb-1"
                                      style={{ fontSize: "17px" }}
                                    >
                                      {comment?.User?.fname}{" "}
                                      {comment?.User?.lname}
                                      <span className="small">
                                        - {moment(comment.createdAt).fromNow()}
                                      </span>
                                    </p>
                                  </div>
                                  <p className="small mb-0">
                                    {comment.message}
                                  </p>
                                </div>
                                {!editingCommentId ||
                                editingCommentId !== comment.id ? (
                                  <>
                                    {/* Add the "Edit" button */}
                                    {comment.user_id ===
                                      localStorage.getItem("userId") && (
                                      <>
                                        <a
                                          href="#!"
                                          className="small mb-0 link-grey"
                                          style={{ color: "#aaa" }}
                                          onClick={() =>
                                            handleEditClick(
                                              comment.id,
                                              comment.message
                                            )
                                          }
                                        >
                                          Edit
                                        </a>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  // Display the edit form when editing
                                  <div>
                                    <textarea
                                      className="form-control"
                                      name="message"
                                      value={editedMessage}
                                      onChange={(e) =>
                                        setEditedMessage(e.target.value)
                                      }
                                    />
                                    <br />
                                    <button
                                      className="btn btn-info my-2 mx-2"
                                      onClick={() =>
                                        handleEditSubmit(comment.id)
                                      }
                                    >
                                      Update
                                    </button>
                                    <button
                                      className="btn btn-primary my-2"
                                      onClick={handleCancelEdit}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}
                                {comment.user_id ==
                                localStorage.getItem("userId") ? (
                                  <>
                                    <a
                                      className="small mb-0 link-grey"
                                      href="#"
                                      onClick={() =>
                                        handelCommnetDelete(comment.id)
                                      }
                                    >
                                      Delete
                                    </a>{" "}
                                    <a
                                      href="#"
                                      className="small mb-0 link-grey"
                                      // style={{
                                      //   fontSize: "15px",
                                      // }}
                                      onClick={() =>
                                        handleEditClick(
                                          comment.id,
                                          comment.message
                                        )
                                      }
                                    >
                                      Edit
                                    </a>
                                  </>
                                ) : (
                                  <a
                                    // style={{
                                    //   fontSize: "15px",
                                    // }}
                                    href="#"
                                    className="small mb-0 link-grey"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handelreplyComments(
                                        comment.id,
                                        comment.user_id
                                      );
                                    }}
                                  >
                                    Reply
                                  </a>
                                )}
                                {/* Add reply form conditionally */}
                                {replyingToCommentId === comment.id && (
                                  <div>
                                    <textarea
                                      className="form-control link-grey"
                                      name="replyMessage"
                                      value={replyMessage}
                                      onChange={(e) =>
                                        setReplyMessage(e.target.value)
                                      }
                                    />
                                    <br />
                                    <button
                                      className="small mb-0 link-grey"
                                      onClick={() =>
                                        handleReplySubmit(comment.id)
                                      }
                                    >
                                      Reply
                                    </button>
                                  </div>
                                )}
                                {/* sub comment / reply start*/}{" "}
                                <a
                                  href="#!"
                                  className="small mb-0 link-grey"
                                  // style={{ color: "#aaa" }}
                                  onClick={(e) =>
                                    handleSubComment(e, comment.id)
                                  }
                                >
                                  View {count?.[comment.id] || 0} comments
                                </a>
                                {currentCommentId === comment.id ? (
                                  <div>
                                    {subCommentData
                                      ?.slice(
                                        0,
                                        subCommentDisplayCount[comment.id] ||
                                          SUB_COMMENT_THRESHOLD
                                      )
                                      .map((subComment) => (
                                        <div
                                          className="d-flex flex-start mt-4"
                                          key={subComment.id}
                                        >
                                          <a href="#" className="me-3">
                                            <img
                                              className="rounded-circle shadow-1-strong"
                                              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(11).webp"
                                              alt="avatar"
                                              width="40"
                                              height="40"
                                            />
                                          </a>
                                          <div
                                            className="flex-grow-1 flex-shrink-1"
                                            style={{ fontSize: "16px" }}
                                          >
                                            <div>
                                              <div className="d-flex justify-content-between align-items-center">
                                                <p
                                                  className="mb-1"
                                                  style={{ fontSize: "17px" }}
                                                >
                                                  {subComment?.User?.fname}{" "}
                                                  {subComment?.User?.lname}
                                                  <span className="small">
                                                    -{" "}
                                                    {moment(
                                                      subComment.createdAt
                                                    ).fromNow()}
                                                  </span>
                                                </p>
                                              </div>
                                              <p className="small mb-0">
                                                {subComment.message}
                                              </p>

                                              {!editingCommentId ||
                                              editingCommentId !==
                                                subComment.id ? (
                                                <>
                                                  {/* Add the "Edit" button */}
                                                  {subComment.user_id ===
                                                    localStorage.getItem(
                                                      "userId"
                                                    ) && (
                                                    <>
                                                      <a
                                                        href="#!"
                                                        className="small mb-0 link-grey"
                                                        style={{
                                                          color: "#aaa",
                                                        }}
                                                        onClick={() =>
                                                          handleEditClick(
                                                            subComment.id,
                                                            subComment.message
                                                          )
                                                        }
                                                      >
                                                        Edit
                                                      </a>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                // Display the edit form when editing
                                                <div>
                                                  <textarea
                                                    className="form-control"
                                                    name="message"
                                                    value={editedMessage}
                                                    onChange={(e) =>
                                                      setEditedMessage(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  <br />
                                                  <button
                                                    className="btn btn-info my-2 mx-2"
                                                    onClick={() =>
                                                      handleEditSubmit(
                                                        subComment.id
                                                      )
                                                    }
                                                  >
                                                    Update
                                                  </button>
                                                  <button
                                                    className="btn btn-primary my-2"
                                                    onClick={handleCancelEdit}
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              )}

                                              {subComment.user_id ==
                                              localStorage.getItem("userId") ? (
                                                <>
                                                  <a
                                                    className="small mb-0 link-grey"
                                                    href="#"
                                                    onClick={() =>
                                                      handelCommnetDelete(
                                                        subComment.id
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </a>{" "}
                                                  <a
                                                    href="#"
                                                    className="small mb-0 link-grey"
                                                    // style={{
                                                    //   fontSize: "15px",
                                                    // }}
                                                    onClick={() =>
                                                      handleEditClick(
                                                        subComment.id,
                                                        subComment.message
                                                      )
                                                    }
                                                  >
                                                    Edit
                                                  </a>
                                                </>
                                              ) : (
                                                <a
                                                  // style={{
                                                  //   fontSize: "15px",
                                                  // }}
                                                  href="#"
                                                  className="small mb-0 link-grey"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handelreplyComments(
                                                      subComment.id,
                                                      subComment.user_id
                                                    );
                                                  }}
                                                >
                                                  Reply
                                                </a>
                                              )}
                                            </div>
                                            {/* Add reply form conditionally */}
                                            {replyingToCommentId ===
                                              subComment.id && (
                                              <div>
                                                <textarea
                                                  className="form-control"
                                                  name="replyMessage"
                                                  value={replyMessage}
                                                  onChange={(e) =>
                                                    setReplyMessage(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                                <br />
                                                <button
                                                  className="link-grey"
                                                  onClick={() =>
                                                    handleReplySubmit(
                                                      subComment.id
                                                    )
                                                  }
                                                >
                                                  Reply
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                    {/* Add "View More" or "View Less" button based on the view mode */}
                                    {subCommentData?.length >
                                      SUB_COMMENT_THRESHOLD && (
                                      <a
                                        href="#"
                                        className="small mb-0 link-grey"
                                        style={{ color: "grey" }}
                                        onClick={() =>
                                          handleViewToggle(comment.id)
                                        }
                                      >
                                        {subCommentViewMode[comment.id]
                                          ? "View Less"
                                          : "View More"}
                                      </a>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* sub comment / reply  end*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  {/* Comment Section End */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-3 right_content_wrap">
              <div className="video-playbox side-wrap">
                Sort by:
                <br />
                <div className="sortwrap">
                  <select
                    className="form-select"
                    name="sort_key"
                    value={sortKey}
                    onChange={handleSortChange}
                  >
                    {/* Add your options based on available sort keys */}
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                    {/* Add more options as needed */}
                  </select>
                  <select
                    className="form-select"
                    name="filter_key"
                    value={filterKey}
                    onChange={handleFilterChange}
                  >
                    {/* Add your options based on available sort keys */}

                    <option value="title">Alphabetical Order</option>
                    <option value="id">Recently Added</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
                <div className="vid-wrap">
                  {videolist &&
                    videolist.map((videolistData, key) => {
                      return (
                        <div
                          className="vid-in-left-card"
                          onClick={(e) => handleVideoPlay(e, videolistData)}
                        >
                          <div className="vid-img-wrap">
                            <img
                              src={`https://img.youtube.com/vi${getVideoIdFromUrl(
                                videolistData.url
                              )}/default.jpg`}
                              alt=""
                            />
                            <a href="javascript:void(0)" className="playicon">
                              <img src="img/playicon.png" alt="" />
                            </a>
                          </div>
                          <div className="vid-content-wrap">
                            <h2>{videolistData.title}</h2>
                            {/* <p>2min, 15 sec</p> */}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default VideoPlayList;
