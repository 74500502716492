import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const API_URL = `${process.env.REACT_APP_API_URL}/giftcart/add`;
const ORDER_API_URL = `${process.env.REACT_APP_API_URL}/order/add`;

const Cart = () => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [price, setTotalPrice] = useState([]);
  const { categoryId } = useParams();
  const location = useLocation();
  const ladiesId = location.state;
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);

  const updateQuantity = (itemId, newQuantity) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.id === itemId) {
        const totalPrice = item.price * newQuantity;
        setTotalPrice(totalPrice);
        return {
          ...item,
          quantity: newQuantity,
          totalPrice: totalPrice.toFixed(2),
        };
      }
      return item;
    });
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  const removeFromCart = (itemId) => {
    const item = cartItems.find((item) => item.id === itemId);
    const confirmation = window.confirm(`Are you sure you want to remove from the cart?`);
  
    if (confirmation) {
      const updatedCartItems = cartItems.filter((item) => item.id !== itemId);
      setCartItems(updatedCartItems);
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    }
  };
  

  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const payment = async () => {
    try {
      const storedLoginResponse = localStorage.getItem("loginResponse");
      const loginResponse = storedLoginResponse
        ? JSON.parse(storedLoginResponse)
        : null;
      const token =
        loginResponse && loginResponse.data && loginResponse.data.tokan
          ? loginResponse.data.tokan
          : null;

      if (token) {
        // Retrieve userId from localStorage

        // Step 1: Create the order
        const orderResponse = await axios.post(
          ORDER_API_URL,
          {
            // Include any necessary data for creating the order
            userId: userId, // Include the userId obtained from localStorage
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const orderId = orderResponse.data?.data?.id;

        const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        const cartData = cartItems.map((item) => ({
          userId,
          giftId: String(item.id),
          quantity: item.quantity,
          price: item.price,
          orderId: String(orderId),
        }));

        const response = await axios.post(API_URL, cartData, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        });

        navigate("/make-payment", { state: { orderId } });
      } else {
        console.error("Token not found.");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const totalPrice = cartItems
    .reduce((total, item) => total + item.price * item.quantity, 0)
    .toFixed(2);

  localStorage.setItem("totalPrice", totalPrice);

  return (
    <div>
      <section className="page-info-sec">
        <div
          className="bg-fixed"
          style={{ backgroundImage: "url('./img/inner_banner.png')" }}
        ></div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>Shopping cart</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur male
                </p>
                <p className="page_nav">
                  {" "}
                  <a href="/">Home</a>
                  <span className="mx-2">/</span>
                  <a href="#" className="currentPage">
                    Shopping cart
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-pagetop">
        <div className="container">
          <h2 className="title-page">Shopping cart</h2>
        </div>
      </section>

      <section className="section-content padding-y">
        <div className="container">
          <div className="row">
            <main className="col-md-9">
              <div className="card">
                <table className="table table-borderless table-shopping-cart">
                  <thead className="text-muted">
                    <tr className="small text-uppercase">
                      <th scope="col">Product</th>
                      <th scope="col" style={{ width: "120" }}>
                        Quantity
                      </th>
                      <th scope="col" style={{ width: "120" }}>
                        Price
                      </th>
                      <th
                        scope="col"
                        className="text-right"
                        style={{ width: "200" }}
                      ></th>
                    </tr>
                  </thead>

                  <tbody>
                    {cartItems.length > 0 ? (
                      cartItems.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <figure className="itemside">
                              <div className="aside">
                                <img
                                  className="img-sm"
                                  src={`${process.env.REACT_APP_API_URL}/${item.image}`}
                                  alt={item.title}
                                />
                              </div>
                              <figcaption className="info">
                                <a href="#" className="title text-dark">
                                  {item.title}
                                </a>
                                <p className="text-muted small">
                                  {item.description}
                                </p>
                              </figcaption>
                            </figure>
                          </td>
                          <td>
                            <select
                              className="form-control"
                              value={item.quantity}
                              onChange={(e) =>
                                updateQuantity(
                                  item.id,
                                  parseInt(e.target.value)
                                )
                              }
                            >
                              {[...Array(4).keys()].map((quantity) => (
                                <option key={quantity + 1} value={quantity + 1}>
                                  {quantity + 1}
                                </option>
                              ))}
                            </select>
                          </td>

                          <td>
                            <div className="price-wrap">
                              <var className="price">
                                $ {item.price * item.quantity}
                              </var>
                            </div>
                          </td>

                          <td className="text-right remove-btn">
                            <a className="btn btn-light" onClick={() => removeFromCart(item.id)}>{" "}Remove
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div>
                        <p>No records found</p>
                      </div>
                    )}
                  </tbody>
                </table>
                <div className="card-body border-top">
                  {/* <form action="/create-checkout-session" method="POST"> */}
                  <button
                    className="btn btn-primary float-md-right"
                    disabled={cartItems.length === 0}
                    onClick={() => payment()}
                  >
                    {" "}
                    Make Purchase <i className="fa fa-chevron-right"></i>{" "}
                  </button>
                  {/* </form> */}
                  {/* <div >
                                        <StripeCheckout
                                            stripeKey="pk_test_51NGzlOAHrNT5NcpOCGAf0W74Cb2UTNiUglBH4943uFqqK6AeHHAeAKcGOGMuVTghp82FvfKymdvCvq6pbRsbamDF000RIseh16"
                                            token={handlePayment}
                                            billingAddress
                                            shippingAddress
                                            // amount={totalPrice * 100}
                                            amount={cartState.totalPrice as number * 100}
                                            name="all products in the cart"
                                        />
                                    </div> */}
                  {/* <a className="btn btn-light" onClick={handleOnChange} > <i className="fa fa-chevron-left"></i> Continue Shopping </a> */}
                </div>
              </div>

              <div className="alert alert-success mt-3">
                <p className="icontext">
                  <i className="icon text-success fa fa-truck"></i> Free
                  Delivery within 1-2 weeks
                </p>
              </div>
            </main>

            <aside className="col-md-3">
              {/* <div className="card mb-3">
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <label>Have coupon?</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          name=""
                          placeholder="Coupon code"
                        />
                        <span className="input-group-append">
                          <button className="btn btn-primary">Apply</button>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}
              <div className="card">
                {/* {cartItems.map((item) => ( */}
                <div className="card-body">
                  <dl className="dlist-align">
                    <dt>Total Price:</dt>
                    <dd className="text-right">$ {totalPrice}</dd>
                  </dl>
                  {/* <dl className="dlist-align">
                    <dt>Coupon Discount:</dt>
                    <dd className="text-right">$ 0.00</dd>
                  </dl> */}
                  <dl className="dlist-align">
                    <dt>Total:</dt>
                    <dd className="text-right  h5">
                      <strong>$ {totalPrice}</strong>
                    </dd>
                  </dl>
                  <hr />
                  <p className="text-center mb-3">
                    <img src="/assets/images/misc/payments.png" height="26" />
                  </p>
                </div>
                {/* ))} */}
              </div>
            </aside>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Cart;
