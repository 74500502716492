import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import  AdminNavigation  from './AdminNavigation';
function UpdateTranslatorsDataByAdmin(){

  const navigate = useNavigate();
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [dob, setDob] = useState('');
    const [password, setPassword] = useState('');
    const [id, setID] = useState(null);
    const [gender, setGender] = useState(''); // New state for gender
    //console.log(gender);

    useEffect(() => {
            setID(localStorage.getItem('ID'))
            setFname(localStorage.getItem('First Name'));
            setLname(localStorage.getItem('Last Name'));
            setEmail(localStorage.getItem('Email'));
            setUsername(localStorage.getItem('Username'));
            setDob(localStorage.getItem('DOB'));
            setPassword(localStorage.getItem('password'));
            setGender(localStorage.getItem('gender'));
    }, []);
    const TOKEN = localStorage.getItem('adminToken');
    const updateSelectedTranslatorData = () => {
     
      try {
          const res =  axios.put(`${process.env.REACT_APP_API_URL}/users/update/${id}`,
          { fname, lname, email, username, dob, password, gender },
              {
                  headers: {
                      Authorization: `Bearer ${TOKEN}`,
                  },
              }
          );
  
          console.log("Response data:", res); // Logging the response data
  
          if (res) { // Checking the status code for success
          
            navigate('/admin/alltranslators');
            window.location.reload(); 
            
          } else {
             // alert("Failed to update user data");
          }
      } catch (error) {
          console.error("Error updating user data:", error);
          //alert("Failed to update user data. Please try again later.");
      }
  };
  
    

    return(
		<div id="wrapper" className="toggled">
   <AdminNavigation/>
        
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            <div className='container'>
		<form>
		  <div className="mb-3 mt-3">
		    <label htmlFor="email" className="form-label">First Name:</label>
		    <input type="text" className="form-control" id="firstname" placeholder="Enter firstname" name="fname" value={fname} onChange = {(e) => setFname(e.target.value)} required/>
		  </div>
		   <div className="mb-3 mt-3">
		    <label htmlFor="email" className="form-label">Last Name:</label>
		    <input type="text" className="form-control" id="lastname" placeholder="Enter lastname" name="lname" value={lname} onChange={(e)=>setLname(e.target.value)} required/>
		  </div>
		  <div className="mb-3 mt-3">
              <label htmlFor="email" className="form-label">Email:</label>
              <input type="email" className="form-control" id="email" placeholder="Enter email" name="email" value={email} onChange={(e)=>setEmail(e.target.value)} required/>
           </div>
           <div className="mb-3 mb-md-3 col-12">
                      <label className="form-label">Gender</label>
                      <select className="form-control" name="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        
                      </select>
                     
                    </div>
           <div className="mb-3 mt-3">
              <label htmlFor="Username" className="form-label">Username:</label>
              <input type="text" className="form-control" id="username" placeholder="Enter username" name="username" value={username} onChange={(e)=>setUsername(e.target.value)} required/>
              <span className="text-muted">(Limit of 15 characters)</span>
           </div>
		   <div className="mb-3 mt-3">
              <label htmlFor="dob" className="form-label">dob:</label>
              <input type="date" className="form-control" id="dob" placeholder="Enter dob" name="dob" value={dob} onChange={(e)=>setDob(e.target.value)} required/>
              
           </div>
		  <div className="mb-3">
		    <label htmlFor="pwd" className="form-label">Password:</label>
		    <input type="password" className="form-control" id="pwd" placeholder="Enter password" name="password" value={password} onChange={(e)=>setPassword(e.target.value)} required/>
		  </div>
		  
		  <button type="submit" onClick={updateSelectedTranslatorData} className="btn btn-primary regsiter_btn">Submit</button>
		</form>
		</div>
		</div>
		</div>
	)

}
export default UpdateTranslatorsDataByAdmin
 