import axios from "axios";
import React, { useState, useEffect } from "react";

const API_URL = `${process.env.REACT_APP_API_URL}/zoom/getMeetings`;

const VideoChatLog = (data) => {
  const [meetingData, setMeetingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [svConsultations, setSvConsultations] = useState();
  const [errors, setErrors] = useState();

  const fetchUserMeetings = async () => {
    const storedLoginResponse = localStorage.getItem("loginResponse");
    const loginResponse = storedLoginResponse
      ? JSON.parse(storedLoginResponse)
      : null;
    const token =
      loginResponse && loginResponse.data && loginResponse.data.tokan
        ? loginResponse.data.tokan
        : null;
    const userId = localStorage.getItem("userId");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/zoom/getMeetings`,
        {
          params: {
            userId: userId,
            // ladiesId: 100,
            // meetingId: 79429407678,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      setMeetingData(response.data.data);
      setSvConsultations(response.data.data.SvConsultation);
    } catch (error) {
      if (error.response) {
        setErrors({
          form: "An error occurred. Please try again later.",
        });
      } else {
        setErrors({
          form: "An error occurred. Please try again later.",
        });
      }
     // console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserMeetings();
  }, []);

  return (
    <>
      <div className="card text-center">
        <div id="wrapper" className="toggled">
          {/* <AdminNavigation /> */}
          <div className="container-fluid ">
            <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
              <h1 className="h3 mb-0 text-gray-800 fontBold">Meeting List</h1>
            </div>

            <div
              className="card shadow mb-4"
              style={{
                width: "100%",
                height: "800px",
                overflowY: "scroll",
                overflowX: "scroll",
                minHeight: "300px",
              }}
            >
              <div className="card-body">
                <div
                  // className="table-responsive"
                  style={{ overflowX: "inherit" }}
                >
                  <div className="row">
                    <table
                      className="table table-bordered "
                      // width="100%"
                      // height="50px"
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          <th scope="col" width="5%">
                            No
                          </th>
                          {/* <th scope="10%">Id</th> */}
                          <th scope="10%">MeetingId</th>
                          {/* <th scope="10%">UserId</th> */}
                          <th scope="10%">LadiesID</th>
                          <th scope="10%">TranslaterId</th>
                          <th width="10%">Meeting Date</th>
                          <th width="10%">Meeting Time</th>
                          <th width="10%">Meeting Url</th>
                          <th scope="col">M_Duration</th>
                          <th width="10%">CreatedAt</th>
                          <th width="10%">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading && meetingData && meetingData?.length > 0 ? (
                          meetingData.map((meeting, index) => (
                            <tr key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>{meeting.meetingId}</td>
                              {/* <td>{meeting.userId}</td> */}
                              <td>{meeting?.Ladiesdata?.fname}</td>
                              <td>
                                {meeting?.Translatordata?.fname
                                  ? meeting.Translatordata?.fname
                                  : "wait for assign"}
                              </td>
                              <td>{meeting.meetingDate}</td>
                              <td>{meeting.meetingTime}</td>
                              {/* <td>{meeting.join_url}</td> */}
                              <td>
                                <a
                                style={{width:"80px"}}
                                  href={meeting.join_url}
                                  target="_blank"
                                  rel="noopener noreferrer" className="btn btn-primary btn-sm mt-2 w-70"
                                >
                                  Join
                                </a>
                              </td>

                              <td>{meeting.meetingDuration}</td>
                              <td>{meeting.createdAt}</td>
                              <td>{meeting.status}</td>
                              {/* <td style={{ width: "52px", height: "52px" }}>
                              {meeting.image ? (
                                <div className="bordered-img-table">
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}/${meeting.image}`}
                                    alt={meeting.title}
                                    className="table-img"
                                  />
                                </div>
                              ) : (
                                "--"
                              )}
                            </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="noRecordFound">
                              No records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoChatLog;
