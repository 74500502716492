import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
function onChange(value) {
  console.log("Captcha value:", value);
}
function ContactUs() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  //constant for loader 
  const [loading,setLoading]=useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple form validation
    if (!fname || !lname || !email || !comment) {
      setErrorMessage('Please fill out all the required fields.');
      return;
    }

    const contactFormData = { fname, lname, email, comment };
    const TOKEN = localStorage.getItem('adminToken');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contact`,
        contactFormData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );

      console.log('response', response);
      setSuccessMessage('Message sent successfully!');
      setErrorMessage(""); // Clear any previous error message
    } catch (error) {
      console.error('Error:', error);
      setSuccessMessage(""); // Clear any previous success message
      setErrorMessage('An error occurred while sending the message.');
    }
  };

  const [pageContent, setPageContent] = useState(null);
  useEffect(()=>{
  const fetchPageContent = async()=>{
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/slug/contactus`);
      setPageContent(response.data.data['0']);
      
    } catch (error) {
      console.error('Error fetching page content:', error);
    }finally{
      setLoading(false);
    }
  
  }
  fetchPageContent();
  },[]);
  console.log("Page Contetn",pageContent);
  if (loading) {
    // Render a loading overlay with the "Loading..." message
    return (
      <div className="loading-overlay">
        {/* <div className="loading-content">Loading...</div> */}
        <img src='/img/loader.gif'></img>
      </div>
    );
  }

    if (!pageContent) {
      return null; // Or render some loading/error message
    }
    const style = {
      backgroundImage: `url('${pageContent.bannerurl}')`,
      // Add other CSS properties as needed
    };


    // Add this JavaScript code to hide the error message after 5 seconds
if (errorMessage) {
  setTimeout(() => {
    // Set errorMessage to null (or any other appropriate action to clear it)
    //errorMessage = null;
    setErrorMessage('');
    // Re-render the component to hide the error message
    // This might involve changing the state or props that control the rendering
  }, 5000); // 5000 milliseconds = 5 seconds
}
  return (
    <>
      <section className="page-info-sec">
    
    <div className="bg-fixed"  style={style}>
    </div>
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col">
          <div className="page-info-block">
            <h1>{pageContent ? pageContent.bannertitle : 'Video Chat Form'}</h1>
            <p>{pageContent ? pageContent.bannerdesc : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur malesuada risus quis ligula pellentesque fermentum. Aenean vel ex finibus, lacinia lacus non, sodales dolor. Phasellus eget turpis neque. Sed aliquet nec felis non faucibus. Quisque euliy tristique elit, sit amet laoreet neque. Vestibulum aliquam at augue at dictum. Praes ent interdum blandit elit, nec ullamcorper odio.'}</p>
            <p className="page_nav"> <a href="/">Home</a>/<a href="#" className="currentPage">{pageContent ? pageContent.bannertitle : 'Video Chat Form'}</a></p>
          </div>
        </div>
      </div>
    </div>
    </section>

      <section className="contact-form-sec secpd70">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-from-wrap">
                <div className="form-top-img-wrap">
                <div dangerouslySetInnerHTML={{ __html: pageContent.pagedesc }}></div>
                  <div className="row guttor-0 flex-md-row-reverse">
                    <div className="col-md-6 align-self-center">
                    {successMessage && (
                          <div className="alert alert-success mt-3" role="alert">
                            {successMessage}
                          </div>
                        )}
                        {errorMessage && (
                          <div className="alert alert-danger mt-3" role="alert">
                            {errorMessage}
                          </div>
                        )}
                      <div className="formwrapper">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-lg-12 mb-4 mb-md-5">
                              <h2 className="title-30">Contact Us</h2>
                            </div>
                            <div className="col-lg-12">
                              <div className="mb-3 mb-md-4">
                                <label>First Name<span className="required">*</span></label>
                                <input type="text" name="fname" value={fname} onChange={(e)=>setFname(e.target.value)} className="form-control"/>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="mb-3 mb-md-4">
                                <label>Last Name<span className="required">*</span></label>
                                <input type="text" name="lname" value={lname} onChange={(e)=>setLname(e.target.value)} className="form-control"/>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="mb-3 mb-md-4">
                                <label>Email Address<span className="required">*</span></label>
                                <input type="text" name="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control"/>
                              </div>
                            </div>
                            <div className="col-lg-12">
                            <div className="mb-3 mb-md-4">
                              <label>Comment<span className="required">*</span></label>
                              <textarea
                                name="comment"
                                rows="10"
                                cols="30"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                className="form-control comments"
                                defaultValue=""
                                placeholder="Enter Comments here"
                              ></textarea>
                            </div>
                          </div>
                            <div className="col-md-12 mt-3">
                            <div className="not-a-robot">
                              <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={onChange} />
                            </div>
                            </div>
                            <div className="col-md-12">
                              <button type="submit" className="btn-1 btn-md px-md-5">Send</button>
                            </div>
                          </div>
                        </form>
                        
                        
                      </div>
                    </div>
                    <div className="col-md-6 d-none d-md-block">
                      <img src={pageContent.pageImage} alt="" className="w-100 h-100 img-cover"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
