import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const DeleteCategory = ({ open, onClose, categoryId, categoryData }) => {
  const API_URL = `${process.env.REACT_APP_API_URL}`;
  const TOKEN = localStorage.getItem("adminToken");

  const handleConfirmDeleteCategory = async () => {
    try {
      const response = await fetch(
        `${API_URL}/giftcategory/delete/${categoryId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete category");
      }

      window.location.reload();
      onClose(); // Close the delete dialog after successful deletion
    } catch (error) {
      console.error("Failed to delete category:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal">
      <DialogTitle>Delete Category</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this category?
      </DialogContent>
      <DialogActions>
        <button
          className="btn btn-primary"
          onClick={handleConfirmDeleteCategory}
        >
          Delete
        </button>
        <button className="btn btn-secondary" onClick={onClose}>
          Cancel
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCategory;
