import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AdminNavigation from './AdminNavigation';

function ViewandAssignTranslator() {
  const navigate = useNavigate();
  const { callID } = useParams();
  const location = useLocation();
  // const { callID,FormList } = location.state || {}; // Add default to avoid destructuring error
  // console.log("state data",callID,FormList);
  // useEffect(() => {
  //   if (!localStorage.getItem('userRole') || localStorage.getItem('userRole') !== '1') {
  //     window.location.href = "/login";
  //   }
  // }, []);

  const [loading, setLoading] = useState(true);
  const [videoChatFormData, setVideoChatFormData] = useState({});
  
  const [translatordata, setTransLatorListingData] = useState([]);
  const [translaterId, setTranslator] = useState('');
  console.log(translaterId,"translaterId");
  const [meetingDate, setNewMeetingDate] = useState('');
  const [meetingTime, setNewMeetingTime] = useState('');
  const [meetingID, setMeetingId] = useState(''); // Initially empty
  const [successMessage, setSuccessMessage] = useState('');

  const API_URL = `${process.env.REACT_APP_API_URL}/svconsultation/get/${callID}`;
  const TOKEN = localStorage.getItem('adminToken');

  const fetchVideoChatFormData = async () => {
    try {
      setLoading(true);
      const res = await fetch(API_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${TOKEN}`
        }
      });
      const data = await res.json();
      const formData = data['data'];
      console.log(formData,"formData");
      setVideoChatFormData(formData);
      setMeetingId(formData.MeetingDetail?.meetingId);
      setNewMeetingDate(formData.meetingDate);
      setNewMeetingTime(formData.meetingTime);
      setTranslator(formData.MeetingDetail?.translaterId || '');
    } catch (error) {
      console.error('Error fetching video chat form data:', error);
    } finally {
      setLoading(false);
    }
  };


console.log(videoChatFormData,"===videoChatFormDatavideoChatFormDatavideoChatFormDatavideoChatFormData=====");


  useEffect(() => {
    fetchVideoChatFormData();
  }, [callID]);

  const fetchLadiesData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/getfiltereduser/`, {
        role: '2'
      });
      setTransLatorListingData(response.data.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLadiesData();
  }, []);

  const updateMeetingData = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/zoom/updateMeetingstatus/${meetingID}`, {
        translaterId,
        meetingDate,
        meetingTime
      }, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      });
      console.log(res,"RESPPPPPPP");
      if (res) {
        setSuccessMessage("Meeting updated successfully");
      }
    } catch (error) {
      console.error('Error updating meeting data:', error);
    }
  };

  return (
    <>
      <div id="wrapper" className="toggled">
        <AdminNavigation />
        <div id="page-content-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mx-auto">
                <div className="card">
                  <div className="card-header">View Call Details</div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-5">
                        {videoChatFormData?.ladiesdata?.profile_pic ? (
                          <img src={`${process.env.REACT_APP_API_URL}${videoChatFormData?.ladiesdata?.profile_pic}`} alt="" className="ih_img" />
                        ) : (
                          <img className="mx-auto d-block" src={"/img/status-1.png"} alt="Lady Image" />
                        )}
                      </div>
                      {/* <div className="col-md-5 view_details">
                        <span><b>Name:</b></span> <span>{videoChatFormData.ladiesdata.fname} {videoChatFormData.ladiesdata.lname}</span>
                        <br />
                        <span><b>Lady Id:</b></span> <span>#FCW_{videoChatFormData.profilenumber_ladies}</span>
                        <br />
                       
                      </div> */}
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-md-6">
                        <span className="badge bg-primary">{videoChatFormData.meetingDate} {videoChatFormData.meetingTime}</span>
                        <span className="badge bg-primary mx-3">Amount Paid: {videoChatFormData.amount} $</span>
                      </div>
                      <div className="col-md-6">
                        <a className="btn btn-warning float-end" href={videoChatFormData.MeetingDetail?.join_url}>Join</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <form onSubmit={updateMeetingData}>
                
                  <h4>Update Meeting Data</h4>
                  {successMessage && <div className="alert alert-success">{successMessage}</div>}
                  <label>Select translator</label>
                  <select
                    name="translaterId"
                    id="translaterId"
                    className="form-control"
                    value={translaterId}
                    onChange={(e) => setTranslator(e.target.value)}
                    required
                  >
                    <option value="">----Select a Translator---</option>
                    {translatordata.map((TranslatorData, key) => (
                      <option key={key} value={TranslatorData.id}>{TranslatorData.fname}</option>
                    ))}
                  </select>

                  {videoChatFormData.meetingDate}
                  <label>Change Meeting Date:</label>
                  <input
                    type="date"
                    name="meetingDate"
                    value={meetingDate}
                    onChange={(e) => setNewMeetingDate(e.target.value)}
                    className="form-control mt-3"
                    required
                  />
                  {videoChatFormData.meetingTime}
                  <input
                    type="time"
                    name="meetingTime"
                    value={meetingTime}
                    onChange={(e) => setNewMeetingTime(e.target.value)}
                    className="form-control mt-3"
                    required
                  />
                  <input
                    type="hidden"
                    name="meetingId"
                    value={meetingID}
                  />
                  <button type="submit" className="btn btn-primary register_btn mt-4 mb-3">Update Meeting</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewandAssignTranslator;
