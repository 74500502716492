import React from "react";
import WrappedShippedForm from "./ShippingAddress";

const Checkout = () => {
  return (
    <>
      <WrappedShippedForm />
    </>
  );
};

export default Checkout;
