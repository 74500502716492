import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const token = localStorage.getItem("adminToken");

export const ViewOrders = ({ open, onClose, orderId }) => {
  const [orderData, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("adminToken");

    if (open && orderId) {
      const fetchOrderDetails = async () => {
        try {
          setLoading(true);
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/order/getorderdetails/${orderId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
              },
            }
          );
          setOrderDetails(response.data);
        } catch (error) {
          setError("Failed to fetch order details.");
        } finally {
          setLoading(false);
        }
      };

      fetchOrderDetails();
    }
  }, [open, orderId]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="container-fluid">
          <div className="container">
            {/* Title */}
            <div className="d-flex justify-content-between align-items-center py-3">
              <h2 className="h5 mb-0">
                <a href="#" className="text-muted">
                  Order No : {orderData?.data?.id}
                </a>
              </h2>
            </div>

            {/* Main content */}
            <div className="row">
              <div className="col-lg-8">
                {/* Details */}
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="mb-3 d-flex justify-content-between">
                      <div>
                        <span className="me-3">
                          Order Date :
                          {new Date(
                            orderData?.data?.orderDate
                          ).toLocaleDateString()}
                        </span>
                        <span className="me-3">
                          Transaction id : {orderData?.data?.transactionId}
                        </span>
                        {/* <span className="me-3">Visa -1234</span> */}
                        <span className="badge rounded-pill bg-info">
                          SHIPPING
                        </span>
                      </div>
                      {/* <div className="d-flex">
                      <button className="btn btn-link p-0 me-3 d-none d-lg-block btn-icon-text">
                        <i className="bi bi-download"></i>{" "}
                        <span className="text">Invoice</span>
                      </button>
                      <div className="dropdown">
                        <button
                          className="btn btn-link p-0 text-muted"
                          type="button"
                          data-bs-toggle="dropdown"
                        >
                          <i className="bi bi-three-dots-vertical"></i>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-pencil"></i> Edit
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-printer"></i> Print
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    </div>
                    <table className="table table-borderless">
                      <div>
                        {orderData?.data?.Giftcarts?.map((gift, index) => (
                          <tbody>
                            <tr>
                              <td>
                                <div className="d-flex mb-2">
                                  <div className="flex-shrink-0">
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/${gift?.Gift?.image}`}
                                      alt=""
                                      width="35"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="flex-lg-grow-1 ms-3">
                                    <h6 className="small mb-0">
                                      <a href="#" className="text-reset">
                                        {gift?.Gift?.title}
                                      </a>
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>{gift?.quantity}</td>
                              <td className="text-end">${gift?.price}</td>
                            </tr>
                          </tbody>
                        ))}
                        <tfoot>
                          <tr>
                            <td colspan="2">Subtotal</td>
                            <td className="text-end">
                              ${orderData?.data?.amount}
                            </td>
                          </tr>
                          {/* <tr>
                          <td colspan="2">Shipping</td>
                          <td className="text-end">$20.00</td>
                        </tr>
                        <tr>
                          <td colspan="2">Discount (Code: NEWYEAR)</td>
                          <td className="text-danger text-end">-$10.00</td>
                        </tr> */}
                          <tr className="fw-bold">
                            <td colspan="2">TOTAL</td>
                            <td className="text-end">
                              ${orderData?.data?.amount}
                            </td>
                          </tr>
                        </tfoot>
                      </div>
                    </table>
                  </div>
                </div>

                {/* Payment */}
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <h3 className="h6">Payment Method</h3>
                        <p>
                          {orderData?.data?.paymentType}
                          <br />
                          Total: ${orderData?.data?.amount}
                          <span className="badge bg-success rounded-pill">
                            PAID
                          </span>
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <h3 className="h6">Billing address</h3>
                        <address>
                          <strong>{orderData?.data?.deliveryName}</strong>
                          <br />
                          {orderData?.data?.deliveryAddress}{" "}
                          {orderData?.data?.City?.name}
                          <br />
                          {orderData?.data?.State?.name},{" "}
                          {orderData?.data?.Country?.name},{" "}
                          {orderData?.data?.deliveryZipcode}
                          <br />
                          <abbr title="Phone">P:</abbr>{" "}
                          {orderData?.data?.deliveryContact}
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                {/* Customer Notes */}
                <div className="card mb-4">
                  <div className="card-body">
                    {/* <h3 className="h6">Other Details</h3> */}
                    <p>
                      {" "}
                      <strong> Gift For Ladies : </strong>
                      {orderData?.data?.ladiesdata?.fname}{" "}
                      {orderData?.data?.ladiesdata?.lname}
                    </p>
                    <p>
                      {" "}
                      <strong> Gift By : </strong>
                      {orderData?.data?.userdata?.fname}{" "}
                      {orderData?.data?.userdata?.lname}
                    </p>
                    {/* <p>
                      {" "}
                      <strong> User Mail ID : </strong>
                      {orderData?.data?.userdata?.email}
                    </p>
                    <p>
                      {" "}
                      <strong> Ladies Mail ID : </strong>
                      {orderData?.data?.ladiesdata?.email}
                    </p> */}
                    {/* <p>
                      {" "}
                      <strong>Delivery Status :</strong>{" "}
                      {orderData?.data?.orderStatus.toUpperCase()}
                    </p> */}
                    <p>
                      <strong>Delivery Date :</strong>
                      {orderData?.data?.deliveryDate}
                    </p>
                  </div>
                </div>

                {/* Shipping information */}
                <div className="card mb-4">
                  <div className="card-body">
                    <h3 className="h6">Shipping Information</h3>
                    <strong>PH-N</strong>
                    <span>
                      <a className="text-decoration-underline" target="_blank">
                        {" "}
                        {orderData?.data?.deliveryContact}
                      </a>
                      <i className="bi bi-box-arrow-up-right"></i>
                    </span>
                    <hr />
                    <h3 className="h6">Address</h3>
                    <address>
                      <strong>
                        {orderData?.data?.userdata?.fname}{" "}
                        {orderData?.data?.userdata?.lname}
                      </strong>
                      <br />
                      {orderData?.data?.deliveryAddress}{" "}
                      {orderData?.data?.City?.name}
                      <br />
                      {orderData?.data?.State?.name},{" "}
                      {orderData?.data?.Country?.name},{" "}
                      {orderData?.data?.deliveryZipcode}
                      <br />
                      <abbr title="Phone">P:</abbr>{" "}
                      {orderData?.data?.deliveryContact}
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="btn btn-secondary"
          style={{ position: "relative", left: "90%" }}
          onClick={onClose}
        >
          Cancel
        </button>
      </Box>
    </Modal>
  );
};

export default ViewOrders;
