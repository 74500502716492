const API_URL = `${process.env.REACT_APP_API_URL}/giftcategory/list`;
const Image_Url = `${process.env.REACT_APP_API_URL}`;
const TOKEN = localStorage.getItem("adminToken");

const fetchCategoryData = async () => {
  const response = await fetch(API_URL, {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  });
  const res = response.json();
  return res;
};

const commonFunction = {
  fetchCategoryData,
};

export default commonFunction;
