import axios from "axios";
import {useState, useEffect } from "react";
import {useNavigate} from 'react-router-dom';

function Ladies(){
  const navigate = useNavigate();
const [pageContent, setPageContent] = useState(null);
const [loading,setLoading] = useState(true);//Add a loader 




















/**
 * ladies listing data get here
 */
 const [ladieslisting, setLadiesListingData] = useState([]);
  const filterParameter = 'gender';

  const fetchLadiesData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/getfiltereduser`, {
        [filterParameter]: 'female'
      });

      setLadiesListingData(response.data.data[0]);
      console.log("RESPPPP",response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('ERROR:', error);
    }
  };

  useEffect(() => {
    fetchLadiesData();
  }, []);
/**
 * ladies listing data get ends here
 */

useEffect(()=>{
const fetchPageContent = async()=>{
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/slug/ladies`);
    setPageContent(response.data.data['0']);
    
  } catch (error) {
    // console.error('Error fetching page content:', error);
    console.error('Error fetching page data:', error);
            if (error.response.status === 401) {
              navigate("/login");
            }
  }finally {
    setLoading(false); // Set loading to false whether the request succeeds or fails
  }

}
fetchPageContent();
},[]);
//console.log("Page Contetn",pageContent);

/**
 * Below use effect hook is used for managing view profile button redirection based on the user is logged in or not.
 */
useEffect(() => {
  if (pageContent && pageContent.pagedesc) {
    const elementsWithClass = document.querySelectorAll('.view_profile_page_btn');
    if (elementsWithClass.length > 0) {
      const userRole = localStorage.getItem('userRole');

      elementsWithClass.forEach((element) => {
        const handleClick = (event) => {
          event.preventDefault(); // Prevent the default anchor click behavior
          if (userRole !== null) {
            navigate('/ladies_profile');
          } else {
            navigate('/login');
          }
        };

        element.addEventListener('click', handleClick);

        // Cleanup event listener on component unmount or pageContent change
        return () => {
          element.removeEventListener('click', handleClick);
        };
      });
    }
  }
}, [pageContent, navigate]);


if (loading) {
  // Render a loading overlay with the "Loading..." message
  return (
    <div className="loading-overlay">
      {/* <div className="loading-content">Loading...</div> */}
      <img src='/img/loader.gif'></img>
    </div>
  );
}
  if (!pageContent) {
    return null; // Or render some loading/error message
  }
  const style = {
    backgroundImage: `url('${pageContent.bannerurl}')`,
    // Add other CSS properties as needed
  };
return(
    <>
    <section className="page-info-sec">
    
<div className="bg-fixed"  style={style}>
</div>
<div className="container">
  <div className="row align-items-center justify-content-center">
    <div className="col">
      <div className="page-info-block">
        <h1>{pageContent ? pageContent.bannertitle : 'Ladies'}</h1>
        <p>{pageContent ? pageContent.bannerdesc : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur malesuada risus quis ligula pellentesque fermentum. Aenean vel ex finibus, lacinia lacus non, sodales dolor. Phasellus eget turpis neque. Sed aliquet nec felis non faucibus. Quisque euliy tristique elit, sit amet laoreet neque. Vestibulum aliquam at augue at dictum. Praes ent interdum blandit elit, nec ullamcorper odio.'}</p>
        <p className="page_nav"> <a href="/">Home</a>/<a href="#" className="currentPage">{pageContent ? pageContent.bannertitle : 'Ladies'}</a></p>
      </div>
    </div>
  </div>
</div>
</section>
{/* {pageContent.pagedesc} */}
{/* {pageContent.pagedesc && (<div dangerouslySetInnerHTML={{ __html: pageContent.pagedesc }} ></div>)}  */}
<section className="find-woman-sec secpd70">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="vid-wrap">
          <img src={`${process.env.REACT_APP_API_URL}${ladieslisting.profile_pic}`} alt=""/>

            <div className="vid-box">
              <a href="#">
                <img src="./img/playicon.png" alt=""/>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="ps-md-4">
            <h2 className="title-30 mb-3">{ladieslisting.fname}</h2>
            <p>{pageContent.pagedesc}</p>
            <p>Please watch the following compilation video that features a diversity of ladies and give us your perspective.</p>
            <a href="" className="btn-1 btn-md view_profile_page_btn manage-profile">View Profiles</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
);
}
export default Ladies