import React from "react";
import { Link, Redirect } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { isAuthenticated } from './auth'; // Import the isAuthenticated helper function

import  AdminNavigation  from './AdminNavigation';
function AllTranslators(){
    useEffect(() => {
        // Check if 'userId' is not present in localStorage
        if (!localStorage.getItem('userRole') && localStorage.getItem('userRole')!== 1) {
          // Redirect to the "/login" page if 'userId' is not present
          window.location.href = "/login";
        }
      }, []); // The empty dependency array ensures that this effect runs once when the component mounts
    
    // Define a loading state variable
    const [loading, setLoading] = useState(true);
    
      //Code for fetching users list
    const [userslist, getUsersData]= useState([]);
    const API_URL = `${process.env.REACT_APP_API_URL}/users`;
    
    const DEACTIVATE_API_URL = `${process.env.REACT_APP_API_URL}/users/deactivate`;
    const ACTIVATE_API_URL = `${process.env.REACT_APP_API_URL}/users/activate`;
    
    const [deactivate_msg,setDeactivateMsg]=useState('');
      //Get admin token form the database for the token
      const TOKEN = localStorage.getItem('adminToken');
    // const fetchUsersData = ()=>{
    //     // Set loading to true to indicate that data fetching is in progress
    //   setLoading(true);
    
    //     fetch(API_URL)
    //     .then((res)=>res.json())
    //     .then((res)=>{
    //        // console.log(res['data']);
    //         getUsersData(res['data']);
    //     }).catch((error) => {
    //         // Handle any errors and hide the loader
            
    //         console.error('Error fetching user data:', error);
    //       }).finally(() => {
    //         // Hide the loader whether the request succeeds or fails
    //         setLoading(false);
    //       });
    // } 


    const filterParameter = 'role';
    const fetchUsersData = async ()=>{
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/getfiltereduser`, {
          [filterParameter]: '2'
        });
  
        getUsersData(res.data.data);
        //console.log("RESPPPP",response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('ERROR:', error);
      }


    }


    useEffect(() => {
        fetchUsersData()
      }, [])
    
    useEffect(() => {
       // console.log("userlist", userslist);
    }, [userslist])
    // Render a loader conditionally based on the "loading" state
    
    console.log("UserList=========",userslist,"=========");
    
    //Delete Functionality 
    
    const delUserdata = (id) => {
        //console.log(id);
        if (window.confirm('Do You want to delete ?')) {
        axios.delete(`${process.env.REACT_APP_API_URL}/users/delete/${id}`).then(() => {
            fetchUsersData();
        })
        }
     }
    //Update functionality
    const updateTranslatorUserdata = async (updatedata)=>{
       // console.log(updatedata);
        let { id, fname, lname,email,dob,username,password,gender} = updatedata;
           localStorage.setItem('ID', id);
           localStorage.setItem('First Name', fname);
           localStorage.setItem('Last Name', lname);
           localStorage.setItem('Email', email);
           localStorage.setItem('DOB', dob);
           localStorage.setItem('Username', username);
           localStorage.setItem('password', password);
           localStorage.setItem('gender', gender);

           
    
    }
    
    
    // const ChangeUserStatus = async (user_id, status) => {
    //   //alert(user_id, status);
    //   if (window.confirm('Do You want to change the status?')) {
    //     try {
    //       let response;
    //       if (status === true) {
    //         response = await axios.put(DEACTIVATE_API_URL, { user_id }, {
    //           headers: {
    //             Authorization: `Bearer ${TOKEN}`
    //           }
    //         });
    //       } else if (status === false) {
    //         response = await axios.put(ACTIVATE_API_URL, { user_id }, {
    //           headers: {
    //             Authorization: `Bearer ${TOKEN}`
    //           }
    //         });
    //       }
    
    //       console.log(response, "RESP");
    
    //       if (response.data.msg) {
    //         setDeactivateMsg(response.data.msg);
    //         fetchUsersData();
    //       }
    //     } catch (error) {
    //       // Handle error
    //       console.error(error);
    //     }
    //   }
    // };
    
    
    
    if (loading) {
      // Render a loading overlay with the "Loading..." message
      return (
        <div className="loading-overlay">
          {/* <div className="loading-content">Loading...</div> */}
          <img src='/img/loader.gif'></img>
        </div>
      );
    }
    
      return (
        <>
          <div id="wrapper" className="toggled">
       <AdminNavigation/>
            
            <div id="page-content-wrapper">
                {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                    <span className="hamb-top"></span>
                    <span className="hamb-middle"></span>
                    <span className="hamb-bottom"></span>
                </button> */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-lg-offset-2">
                          {/* {deactivate_msg} */}
                        <Link to={"/admin/translator"} className="btn btn-primary translator_btn">Add Transaltor</Link>
                          <h3 >All Transaltor</h3>
                          <table className="table table-light table-striped">
                            <thead>
                            <tr>
                                <th>Sno</th>
                                <th>Firstname</th>
                                <th>Lastname</th>
                                <th>Email</th>
                                <th>Gender</th>
                                <th>DOB</th>
                                <th>Username</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                                {/* <th>Status</th> */}
                                <th colSpan={2}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {userslist.map((usersListData, key) => {
                                return(
    
                            <tr>
                                <td>{key+1}</td>
                                <td>{usersListData.fname}</td>
                                <td>{usersListData.lname}</td>
                                <td>{usersListData.email}</td>
                                <td>{usersListData.gender}</td>
                                <td>{usersListData.dob}</td>
                                <td>{usersListData.username}</td>
                                <td>{usersListData.createdAt}</td>
                                <td>{usersListData.updatedAt}</td>
                                {/* <td>
                                <button id="statusButton" onClick={() => ChangeUserStatus(usersListData.id, usersListData.status)} className={`btn ${usersListData.status ? 'btn-success' : 'btn-danger'}`} title={`${usersListData.status ? 'Click Here to Inactive' : 'Click Here to active'}`} >{usersListData.status ? 'Active' : 'Inactive'}</button>
                                  </td>
                                  */}
                                <td> <Link to='/admin/update_translators'><button  onClick={() => updateTranslatorUserdata(usersListData)} className='btn btn-primary'>Edit</button> </Link></td>
                                
                                <td><button onClick={() => delUserdata(usersListData.id)} className='btn btn-danger'>Delete</button></td> 
                            </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </>
      );
    
    }
export default AllTranslators