import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminNavigation from './AdminNavigation';

function UpdatePageData() {
    const [bannerurl, setBannerurl] = useState('');
    const [bannertitle, setBannertitle] = useState('');
    const [bannerdesc, setBannerdesc] = useState('');
    const [pagetitle, setPagetitle] = useState('');
    const [pagedesc, setPagedesc] = useState('');
    const [template, setTemplate] = useState('');
    const [slug, setSlug] = useState('');
    const [sort, setSort] = useState('');
    const [top_menu, setTopmenu] = useState('');
    const [id, setID] = useState(null);
    const [pageImage, setpageImage] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setID(localStorage.getItem('ID'));
        setBannerurl(localStorage.getItem('Banner URL'));
        setBannertitle(localStorage.getItem('Banner Title'));
        setBannerdesc(localStorage.getItem('Banner Description'));
        setPagetitle(localStorage.getItem('Page Title'));
        setPagedesc(localStorage.getItem('Page Description'));
        setTemplate(localStorage.getItem('Page Template'));
        setSlug(localStorage.getItem('Page Slug'));
        setSort(localStorage.getItem('Page Sort'));
        setTopmenu(localStorage.getItem('Top menu'));
        setpageImage(localStorage.getItem('Page Image'));
    }, []);

    const TOKEN = localStorage.getItem('adminToken');

    const updateSelectedPageData = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/pages/updatepage/${id}`, {
                bannerurl,
                bannertitle,
                bannerdesc,
                pagetitle,
                pagedesc,
                template,
                slug,
                sort,
                top_menu,
                pageImage
            }, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`
                }
            });
            console.log("Page Update RESP", res);
            setSuccess(true);
            setTimeout(() => {
                window.location.href = "/admin/pages";
            }, 5000);
        } catch (error) {
            console.log(error, 'ERRRRRRORRRRR');
        }
    }

    return (
        <div id="wrapper" className="toggled">
            <AdminNavigation />
            <div id="page-content-wrapper">
                <div className="container">
                    <div className="row">
                        <h4>Update</h4>
                        {success && <div className="alert alert-success">Page updated successfully! Redirecting...</div>}
                        <form onSubmit={updateSelectedPageData}>
                            <img src={bannerurl} alt="Banner" />
                            <div className="mb-3 mt-3">
                                <label htmlFor="bannerurl" className="form-label">Banner URL:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="bannerurl"
                                    placeholder="Enter Banner URL"
                                    name="bannerurl"
                                    value={bannerurl}
                                    onChange={(e) => setBannerurl(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="bannertitle" className="form-label">Banner Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="bannertitle"
                                    placeholder="Enter Banner Title"
                                    name="bannertitle"
                                    value={bannertitle}
                                    onChange={(e) => setBannertitle(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="bannerdesc" className="form-label">Banner Description:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="bannerdesc"
                                    placeholder="Enter Banner Description"
                                    name="bannerdesc"
                                    value={bannerdesc}
                                    onChange={(e) => setBannerdesc(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="pagetitle" className="form-label">Page Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="pagetitle"
                                    placeholder="Enter Page Title"
                                    name="pagetitle"
                                    value={pagetitle}
                                    onChange={(e) => setPagetitle(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="slug" className="form-label">Page Slug:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="slug"
                                    placeholder="Enter Page Slug"
                                    name="slug"
                                    value={slug}
                                    onChange={(e) => setSlug(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="pagedesc" className="form-label">Page Description:</label>
                                <textarea
                                    className="form-control"
                                    id="pagedesc"
                                    cols="50"
                                    rows="20"
                                    placeholder="Enter Page Description"
                                    name="pagedesc"
                                    value={pagedesc}
                                    onChange={(e) => setPagedesc(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="template" className="form-label">Page Template:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="template"
                                    placeholder="Enter Page Template"
                                    name="template"
                                    value={template}
                                    onChange={(e) => setTemplate(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="sort" className="form-label">Page Sorting:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="sort"
                                    placeholder="Enter Page Sort"
                                    name="sort"
                                    value={sort}
                                    onChange={(e) => setSort(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="top_menu" className="form-label">Page Menu Position:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="top_menu"
                                    placeholder="Enter Page Menu Position"
                                    name="top_menu"
                                    value={top_menu}
                                    onChange={(e) => setTopmenu(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="pageImage" className="form-label">Page Image:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="pageImage"
                                    placeholder="Enter Page Image"
                                    name="pageImage"
                                    value={pageImage}
                                    onChange={(e) => setpageImage(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary register_btn mb-4">Update</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdatePageData;
