import React from "react";
import { Link, Redirect } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { isAuthenticated } from './auth'; // Import the isAuthenticated helper function
import  AdminNavigation  from './AdminNavigation';

function AddVideos(){
    const [title, setVideoTitle] = useState("");
    const [url, setVideoURL] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const pageData = { title, url };
      const TOKEN = localStorage.getItem('adminToken');
  
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/video/addvideo`, pageData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`,
          },
        });
  
        // Check if the response indicates success (you might have a different way of checking it)
        if (res.status === 200) {
          setSuccessMessage("Video Added successfully!");
        } else {
          setSuccessMessage("Video creation failed.");
        }
      } catch (error) {
        setSuccessMessage("Video creation failed.");
      }
    };
return(
<>
<div id="wrapper" className="toggled">
   <AdminNavigation/>
        
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            </div>
<div className="container">
        <div className="row mt-4">
          <div className="col-md-8 mx-auto registration_section">
          {successMessage && (
              <div className="alert alert-success mt-3 text-center" role="alert">
                {successMessage}
              </div>
            )}
            <h4>Add Videos</h4>
            <form onSubmit={handleSubmit}>
              <div className="mb-3 mt-3">
                <label htmlFor="title" className="form-label">Video Title:</label>
                <input type="text" className="form-control" id="title" placeholder="Enter Video Title" name="title" value={title} onChange={(e) => setVideoTitle(e.target.value)} required />
              </div>
              <div className="mb-3 mt-3">
                <label htmlFor="url" className="form-label">Video URL:</label>
                <input type="text" className="form-control" id="url" placeholder="Enter Video URL" name="url" value={url} onChange={(e) => setVideoURL(e.target.value)} required />
              </div>
            <button type="submit" className="btn-1">Submit</button>
            </form>
        </div>
        </div>
      </div>
      </div>
</>
)}
export default AddVideos