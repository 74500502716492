import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';

/**
 * Mailchimp code start here
 */
/**
 * Import mailchimp library
 */
import MailchimpSubscribe from "react-mailchimp-subscribe"
/**
 * Pass mailchimp list url
 */
const url = "//xxxx.us13.list-manage.com/subscribe/post?u=zefzefzef&id=fnfgn";

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url}/>

// use the render prop and your custom form
const CustomForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <div>
        <SimpleForm onSubmitted={formData => subscribe(formData)} />
        {status === "sending" && <div style={{ color: "white" }}>sending...</div>}
        {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
      </div>
    )}
  />
)

/**
 * Mailchimp code ends here
 */

function Footer() {
  const userRole = localStorage.getItem('userRole');
  
  const [settinglist, getSettingsData] = useState([]);

  const API_URL = `${process.env.REACT_APP_API_URL}/setting/getsetting`;
  
  const fetchSettingsData = () => {
    fetch(API_URL)
      .then((res) => res.json())
      .then((res) => {
        getSettingsData(res['data']);
      });
  };

  useEffect(() => {
    fetchSettingsData();
  }, []);

  useEffect(() => {
    //console.log("settingslist", settinglist);
  }, [settinglist]);

  const Footer_Pages_API_URL = `${process.env.REACT_APP_API_URL}/pages/`;
  const TOKEN = localStorage.getItem('adminToken');
  const [pageslist, setPagesList] = useState([]);
  const getFootermenus = async ()=>{
    try {
      const footer_menu_resp = await axios.get(Footer_Pages_API_URL,{ headers: {
        Authorization: `Bearer ${TOKEN}`,
      },});
      setPagesList(footer_menu_resp.data.data);
     // console.log(footer_menu_resp.data.data,"FOOOTERRR");
    } catch (error) {
      console.log("Footer Menus not working due to error:",error);
      
    }

  }
  
  useEffect(()=>{
    getFootermenus();
  },[])
  
  return (
    <>
      {userRole!== '1' && (
        <>
          <section className="newsletter-sec">
            <img src="./img/heart-bg.png" alt="" className="fixed_bg" />
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h2>Subscribe to Our Newsletter for Daily Updates!</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nullam bm egestas orc tortor turpis retium quis est sit ame
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="input-group mb-0 newsletter-search-form newsletter_form">
                    {/* <input
                      className="form-control "
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <span className="input-group-text">Send</span> */}
                    <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                      <div>
                        <SimpleForm onSubmitted={formData => subscribe(formData)} />
                        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                        {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
                        {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
                      </div>
                    )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          
          {/* {settinglist.map((settinglistData, key) => (<>{settinglistData.footer_section && (<div dangerouslySetInnerHTML={{ __html: settinglistData.footer_section }} ></div>)} </>
          ))} */}
          <footer className="footerMain bg-dark">
              <div className="container">
                <div className="foote-wrap">
                  <ul className="f_link">
                    {/* <li><a href="/termsconditions">Terms & Conditions</a></li>
                    <li><a href="/privacypolicy">Privacy Policy</a></li>
                    <li><a href="/faq">FAQ</a></li>
                    <li><a href="/customerfeedback">Customer Feedback</a></li>
                     */}
                   {  pageslist.map((page) => (
                      page.template == 2 && page.top_menu === 0 ? (
                        <li className="nav-item" key={page.id}>
                          {page.slug === 'home' ? (
                            <Link className="nav-link" to="/">
                              {page.pagetitle}
                            </Link>
                          ) : (
                            <Link className="nav-link" to={`/${page.slug}`}>
                              {page.pagetitle}
                            </Link>
                          )}
                        </li>
                      ) : null
                    ))
                          }
                          <li> <p className="copyrightbox">Copyright © 2024 Foreignchatweb. All rights reserved 2024</p></li>
                  </ul>
                  <ul className="social-list">
                  <li><a href="https://www.facebook.com/foreignchatweb/"><i className="fab fa-facebook"></i></a></li>
                  <li><a href="https://www.instagram.com/foreignchatweb/"><i className="fab fa-instagram"></i></a></li>
                  <li><a href="https://www.tiktok.com/@foreignchatweb?_t=8gazG9W4kTi&_r=1"><i className="fab fa-tiktok"></i></a></li>
                  <li><a href="https://www.youtube.com/@Ukrainian-woman-of-your-dreams"><i className="fab fa-youtube"></i></a></li>
                </ul>
                </div>
                
              </div>
        </footer>
        </>
      )}
    </>
  );
}

export default Footer;
