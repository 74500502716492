import React from "react";
import { useState, useEffect } from 'react';
import axios from "axios";
function FaqSection(){

const [faqlisting,setFaqListing] = useState([]);//Define blank array for getting data from FAQ as it is in array format
const API_URL = `${process.env.REACT_APP_API_URL}/faq`;
const [loading, setLoading] = useState(true);
const getFAQData = async () =>{
  try {
    const response = await axios.get(API_URL);
    const  FAQResp = response.data.data;
    setFaqListing(FAQResp);
    
  } catch (error) {
    
  }
}

useEffect(()=>{getFAQData()},[]);

//console.log(faqlisting,"FAQ Listing");
  

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (index === activeIndex) {
      // Clicking on the currently active accordion item will close it
      setActiveIndex(null);
    } else {
      // Clicking on a different accordion item will open it
      setActiveIndex(index);
    }
  };
    return(<>
    <section className="page-info-sec">
    <div className="bg-fixed" style={{backgroundImage:"url('./img/inner_banner.png')"}}>
    </div>
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col">
          <div className="page-info-block">
            <h1>FAQ</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur male</p>
            <p className="page_nav"> <a href="/">Home</a><span  className="mx-2">/</span><a href="#" className="currentPage">FAQ</a></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="faq-section my-3">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="faq">
                {faqlisting.map((faq, index) => (
                  <div className="faq-item" key={index}>
                    <div
                      className={`faq-question ${index === activeIndex ? "active" : ""}`}
                      data-toggle="collapse"
                      data-target={`#collapse-${index}`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {faq.question}
                      <span className={`icon ${index === activeIndex ? "open" : ""}`}>
                        {index === activeIndex ? "-" : "+"}
                      </span>
                    </div>
                    <div
                      id={`collapse-${index}`}
                      className={`faq-answer collapse ${index === activeIndex ? "show" : ""}`}
                    >
                      {faq.answer}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
</>)

}
export default FaqSection