import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';

import Pagination from './Pagination';
function MyVideoChats() {
  // Define a loading state variable
  const [loading, setLoading] = useState(true);
  const [FormList, getVideoChatFormData] = useState([]);
  const userId = localStorage.getItem('userId');
  // Define itemsPerPage
  const [totalPages, setTotalPages] = useState(null); // Add this line for total pages
  const itemsPerPage = 10;
  // Assuming you have a state variable for the current page number
  const [currentPage, setCurrentPage] = useState(1);
  //console.log(userId,"userId data")
  //const API_URL = `${process.env.REACT_APP_API_URL}/svconsultation/list?user_id=${userId}&type=videochat`;
  const COUNT_API_URL = `${process.env.REACT_APP_API_URL}/svconsultation/list?user_id=${userId}&type=videochat&pageNumber=${currentPage}&pageSize=${itemsPerPage}`;
  const API_URL = `${process.env.REACT_APP_API_URL}/svconsultation/list?user_id=${userId}&type=videochat`;
  //Get admin token form the database for the token
  const TOKEN = localStorage.getItem('adminToken');
  // 

  const fetchVideoChatFormData = () => {
    // Set loading to true to indicate that data fetching is in progress
    setLoading(true);
    fetch(API_URL)
      .then((res) => res.json())
      .then((res) => {
        // console.log(res['data'].data);
        const totalCount = res['data'].data.length;
        getpaginatedData(currentPage)
        // console.log(totalCount, "totalCount");
        // console.log(responseData,"responseData");
        // Calculate total pages based on the totalCount and itemsPerPage
        const totalPages = (totalCount / itemsPerPage);
        console.log("totalCount", totalCount);
        console.log("itemsPerPage", itemsPerPage);
        console.log("Totpages", totalPages);
        // Update the state variables
        setTotalItems(totalCount);
        setTotalPages(totalPages);

      }).catch((error) => {
        // Handle any errors and hide the loader

        console.error('Error fetching user data:', error);
      }).finally(() => {
        // Hide the loader whether the request succeeds or fails
        setLoading(false);
      });

  }
  useEffect(() => {
    fetchVideoChatFormData()
  }, [])

  useEffect(() => {
    // console.log("userlist", userslist);
  }, [FormList])
  // Render a loader conditionally based on the "loading" state

  //console.log("UserList=========",FormList,"=========");

  //Delete Functionality 
  //console.log("TOKEN",TOKEN);
  const delUserdata = (id) => {
    //console.log(id);
    if (window.confirm('Do You want to delete ?')) {
      axios.delete(`${process.env.REACT_APP_API_URL}/svconsultation/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }).then(() => {
        fetchVideoChatFormData();
      }).catch(error => {
        console.error("Error deleting user data:", error);
        // Handle error as needed
      });
    }
  }


  //const itemsPerPage = 10;

  // Define totalItems in the component's state
  const [totalItems, setTotalItems] = useState(0);
  // Calculate totalPages here
  //const totalPages = Math.ceil(totalItems / itemsPerPage);
  // Function to handle pagination button click
  const handlePaginationClick = (newPage) => {
    console.log(newPage, 'newPage');
    setCurrentPage(newPage);
    getpaginatedData(newPage);
  };

  const getpaginatedData = (newPage) => {
    fetch(`${process.env.REACT_APP_API_URL}/svconsultation/list?user_id=${userId}&type=videochat&pageNumber=${newPage}&pageSize=${itemsPerPage}`).then(res => res.json()).then(res => {
      const responseData = res['data'].data;
      getVideoChatFormData(responseData);
    });
  }

  return (
    <>
      <section className="page-info-sec">
        <div className="bg-fixed" style={{ backgroundImage: "url('./img/inner_banner.png')" }}>
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>My Video Chat Listing</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur male</p>
                <p className="page_nav"> <a href="/">Home</a><span className="mx-2">/</span><a href="#" className="currentPage">My Video Chat Listing</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="video-chat-form-sec secpd70">
        <div className="container myprofile_container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-from-wrap">
                {/* <div className="main-from-inner-wrap"> */}
                <div className="title-centered text-center">
                  <h2 className="title-30 mb-3">My Video Chat Listing</h2>
                </div>
                <div className="formwrapper">
                  <table className="table table-light table-striped">
                    <thead>
                      <tr>
                        <th>Sno</th>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Age</th>
                        <th>country</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Occupation</th>
                        <th>Ladies Profile Id</th>
                        <th>Amount</th>
                        <th>Payment Type</th>
                        <th>payment status</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        {/* <th>Action</th> */}

                      </tr>
                    </thead>
                    <tbody>
                      {FormList.map((VideoChatListData, key) => {
                        return (

                          <tr>
                            <td>{key + 1}</td>
                            <td>{VideoChatListData.fname}</td>
                            <td>{VideoChatListData.lname}</td>
                            <td>{VideoChatListData.age}</td>
                            <td>{VideoChatListData.Country.name}</td>
                            <td>{VideoChatListData.State.name}</td>
                            <td>{VideoChatListData.City.name}</td>
                            <td>{VideoChatListData.occupation}</td>

                            {/**replace(/,/g, '\n') for remove comma and line break */}
                            <td className='ladies_profile_number_td'>{VideoChatListData.profilenumber_ladies.replace(/,/g, ',\n')}</td>
                            <td>{VideoChatListData.amount}</td>
                            <td>{VideoChatListData.payment_type}</td>
                            <td>{VideoChatListData.payment_status}</td>
                            <td>{VideoChatListData.createdAt}</td>
                            <td>{VideoChatListData.updatedAt}</td>
                            {/* <td><button onClick={() => delUserdata(VideoChatListData.id)} className='btn btn-danger'>Delete</button></td> */}

                          </tr>
                        )
                      })}
                    </tbody>
                  </table>

                  <Pagination currentPage={currentPage} totalPages={Math.ceil(totalItems / itemsPerPage)} handlePaginationClick={handlePaginationClick}
                  />
                </div>
                {/* </div> */}

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MyVideoChats