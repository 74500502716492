import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import  AdminNavigation  from './AdminNavigation';
import { useNavigate } from "react-router-dom";
function UpdateSiteURLSettingsData(){
    const[site_url,setSiteURLText]=useState('');
    const [id, setID] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        setID(localStorage.getItem('ID'));
        setSiteURLText(localStorage.getItem('Site URL'));
       
       
}, []);
const TOKEN = localStorage.getItem('adminToken');
    console.log(TOKEN);
    const updateSelectedSiteURLSettingData = () => {
     let res =   axios.put(`${process.env.REACT_APP_API_URL}/setting/${id}`, {
        site_url}, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      });
    if(res){
            console.log(res);
           // alert("updated"+res);
           // window.location.href = "/admin/pages";
           navigate("/admin/settings");
        }else{
          console.log(res);
           //alert("failed to update");
        }
    }
    
    return(<>
     <div id="wrapper" className="toggled">
      <AdminNavigation/>
           
           <div id="page-content-wrapper">
               
               <div className="container">
                   <div className="row">
                       <div className="col-md-4 mx-auto"></div>
                   <h2>Update Site URL</h2>
                   <form>
                      
                       <label>Site URL:</label>
                       <textarea name="site_url" id="site_url" className="form-control" placeholder="Enter Site URL" value={site_url} onChange = {(e) => setSiteURLText(e.target.value)} required></textarea>
                       <br/>
                       <input type="submit" onClick={updateSelectedSiteURLSettingData} className="btn btn-primary mb-2" id='update_site_url' value="Update Site URL"/>
   
                   </form>
       </div>
       </div>
       </div>
       </div>
    </>
    )

}
export default UpdateSiteURLSettingsData