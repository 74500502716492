import React, { useState, useEffect } from "react";
import axios from 'axios';
import { isAuthenticated } from './auth';
import AdminNavigation from './AdminNavigation';

function UpdateMedia() {
  const [id, setID] = useState(null);
  const [mediaFile, setMediaFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  
  useEffect(() => {
    setID(localStorage.getItem('ID'));
    setMediaFile(localStorage.getItem('Media path'));
  }, []);

  const TOKEN = localStorage.getItem('adminToken');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setMediaFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!mediaFile) {
      alert("Please select a media file to update.");
      return;
    }

    const formData = new FormData();
    formData.append('image', mediaFile);
    console.log(formData,"formData");
    console.log(`${process.env.REACT_APP_API_URL}/media/update/${id}`,'API URL');
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/media/update/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        setSuccessMessage('Media Updated successfully!');
      } else {
        setSuccessMessage('Media failed to add.');
      }
    } catch (error) {
      console.error('Error:', error);
      
      setSuccessMessage('Media creation failed.');
    }
  };

  return (
    <>
      <div id="wrapper" className="toggled">
        <AdminNavigation />
        <div id="page-content-wrapper">
          <div className="container">
            <div className="row mt-4">
              <div className="col-md-8 mx-auto registration_section">
                <h4>Update Media</h4>
                {/* Display selected file name */}
                {mediaFile && (
                  <div className="mb-3">
                    Selected File: <br />
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${mediaFile}`}
                      alt="Media"
                    />
                  </div>
                )}
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="mb-3 mt-3">
                    <label htmlFor="mediaFile" className="form-label">
                      Upload New Media:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="mediaFile"
                      name="mediaFile"
                      onChange={handleFileChange}
                      required
                    />
                  </div>
                  <button type="submit" className="btn-1">
                    Upload
                  </button>
                </form>
                {successMessage && (
                  <div className="mt-3 alert alert-success">
                    {successMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateMedia;
