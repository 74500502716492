import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
const API_URL = `${process.env.REACT_APP_API_URL}/giftcategory/list`;

const GiftCatgeories = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const { ladiesId } = useParams();
  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const token = localStorage.getItem("loginResponse");
        if (token) {
          const response = await axios.get(API_URL, {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          });
          setCategories(response.data?.data || []);
        } else {
          console.error("Token not found in local storage.");
        }
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };

    fetchCategoryData();
  }, []);
  const handleCategoryClick = (categoryId) => {
    navigate(`/giftcategory/${ladiesId}/gift/${categoryId}`, {
      state: {
        ladiesId: ladiesId,
      },
    });
  };

  return (
    <>
      <section className="page-info-sec">
        <div
          className="bg-fixed"
          style={{ backgroundImage: "url('./img/inner_banner.png')" }}
        ></div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>Gift Categories</h1>
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur male
                </p> */}
                <p className="page_nav">
                  {" "}
                  <a href="/">Home</a>
                  <span className="mx-2">/</span>
                  <a href="#" className="currentPage">
                    Gift Categories
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="welcome-sec secpd70">
        <div className="container">
          <div className="shadow-wrap-1">
            <div className="row gap-40">
              <div className="col-12">
                <h2 className="title-30 mb-3">Gift Catgeories</h2>
              </div>
              {categories?.length > 0 ? (
                categories?.map((category) => (
                  <div className="col-1/5" key={category.id}>
                    <div
                      className="welcome-box-gift"
                      onClick={() => handleCategoryClick(category.id)}
                    >
                      <div className="wb-icon-box-gift">
                        {/* <i
                          class="fa fa-gift"
                          style={{ fontSize: "50px", color: "red" }}
                        ></i> */}
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${category.image}`}
                          alt={category.title}
                          className="table-img"
                        />
                      </div>
                      <p>{category.title}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <p>No records found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default GiftCatgeories;
