import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StripeSuccess from "./StripeSuccess";
const stripePromise = loadStripe(
  "pk_test_51M7ESnSCq9c1L3o5dzZjWFWg7BzFr6Hl8TgJy0vzFSwdmjDXJZez5IibxUG0ocAjvhfmYG70gJKue2wVKjK6H3H100iagpGxlx"
);

const storedLoginResponse = localStorage.getItem("loginResponse");
const loginResponse = storedLoginResponse
  ? JSON.parse(storedLoginResponse)
  : null;
const tok =
  loginResponse && loginResponse.data && loginResponse.data.tokan
    ? loginResponse.data.tokan
    : null;
const tokan = localStorage.getItem("adminToken");

const WrappedShippedForm = () => {
  return (
    <Elements stripe={stripePromise}>
      <ShippingAddress />
    </Elements>
  );
};

const ShippingAddress = () => {
  const storedLoginResponse = localStorage.getItem("loginResponse");
  const loginResponse = storedLoginResponse
    ? JSON.parse(storedLoginResponse)
    : null;
  const localStorageToken =
    loginResponse && loginResponse.data && loginResponse.data.tokan
      ? loginResponse.data.tokan
      : null;
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId: orderIdFromParams } = useParams(); // Get orderId from URL parameters

  // Retrieve orderId from either location.state or URL parameters
  const orderId = location.state?.orderId || orderIdFromParams;
  // const amount = localStorage.getItem("totalPrice");

  const [insertsuccess, setInsertSuccess] = useState("");
  const { state } = useLocation(); // Retrieve state from location
  const { token: stateToken } = state || {}; // Retrieve token from state
  // Determine which token to use (state token has priority)
  const token = stateToken || localStorageToken;
  const [showPaymentOptions, setShowPaymentOptions] = useState(true);
  // shipping_address_form_data
  const [order_id, setOrderID] = useState(null);
  // Retrieve ladiesId and amount from location.state or fallback to local storage if not present
  const { ladiesId: stateLadiesId, amount: stateAmount } = location.state || {};
  const ladiesId = stateLadiesId || localStorage.getItem("ladiesId");
  const amount = stateAmount || localStorage.getItem("totalPrice");
  const [fname, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [form_country, setSelectedCountryName] = useState("");
  const [form_state, setSelectedStateName] = useState("");
  const [form_city, setSelectedCityName] = useState("");
  const [form_zipcode, setSelectedZipcode] = useState("");
  const [getcountries, setCountries] = useState([]);
  const [price, setPrice] = useState(null);
  const [states_list, setStates] = useState("");
  const [loading, setLoading] = useState(true);
  const userId = localStorage.getItem("userId");

  const [city_list, setCityList] = useState();

  const [stripe_price_id, setStripePriceId] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState("");

  const [selectedPaymentMethod, setPaymentSelectedMethod] = useState(null);
  const [setFormSubmittedpaymentMethod, SetSubmittedPayment] = useState(null);
  const [paypalerror, setPaypalError] = useState("");

  const paymentType = selectedPaymentMethod;
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [paymentsuccess, setPaymentSuccess] = useState("");
  const [errors, setErrors] = useState({});
  const [paymentError, setPaymentError] = useState(null);

  const [useremail, getUserEmail] = useState("");
  const [orderdata, getOrderData] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const [transId, settransId] = useState();
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Hide the payment options
    setShowPaymentOptions(false);
    // Validation
    const newErrors = {};
    // Validate required fields
    if (!fname.trim()) newErrors.fname = "Name is required.";
    if (!email.trim()) newErrors.email = "Email is required.";
    if (!contact.trim()) newErrors.contact = "Contact is required.";
    if (!form_country.trim()) newErrors.form_country = "Country is required.";
    if (!form_state.trim()) newErrors.form_state = "State is required.";
    if (!form_city.trim()) newErrors.form_city = "City is required.";
    if (!deliveryAddress.trim())
      newErrors.deliveryAddress = "Delivery address is required.";
    if (!form_zipcode.trim()) newErrors.form_zipcode = "Zip code is required.";
    if (!deliveryDate.trim())
      newErrors.deliveryDate = "Delivery date is required.";

    // Check email format
    if (email && !/^\S+@\S+\.\S+$/.test(email)) {
      newErrors.email = "Invalid email format.";
    }
    setPrice(amount);

    setErrors(newErrors);

    setTimeout(() => {
      setErrors({});
    }, 10000);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      setFormSubmitted(false);
      const deliveryCountry = form_country;
      const deliveryState = form_state;
      const deliveryZipcode = form_zipcode;
      const deliveryCity = form_city;
      const deliveryContact = contact;
      const deliveryEmail = email;
      const deliveryName = fname;

      // const price = price;
      // const type = Formtype;
      const shipping_address_form_data = {
        ladiesId,
        amount,
        deliveryDate,
        paymentType,
        deliveryName,
        deliveryAddress,
        deliveryEmail,
        deliveryContact,
        deliveryCity,
        deliveryState,
        deliveryCountry,
        deliveryZipcode,
      };
      // return false;
      try {
        const headers = {
          Authorization: `Bearer ${token}`, // Use token from state
          Accept: "application/json",
        };

        const insert_form_data = await axios.put(
          `${process.env.REACT_APP_API_URL}/order/placeorder/${orderId}`,
          shipping_address_form_data,
          { headers }
        );

        SetSubmittedPayment(paymentType);
        setPrice(amount);
        setOrderID(orderId);
        setLoading(false);
        setInsertSuccess("Data Inserted Successfully!!");

        // Call getOrderDatabyOrderId and wait for it to complete
        await getOrderDatabyOrderId();
      } catch (error) {
        if (error.response || error.request) {
          setErrors({
            form: "An error occurred during registration. Please try again later.",
          });
        } else {
          setErrors({
            form: "An error occurred during registration. Please try again later.",
          });
        }
        setLoading(false);
        setFormSubmitted(false);
      }
    }
  };

  const handleCheckout = async (event) => {
    event.preventDefault();
    const orderId = order_id;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/order/GiftStripePayment`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            orderId: orderId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Error in Stripe payment process");
      }

      const session = await response.json();

      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error("Stripe Checkout error:", result.error);
        navigate("/cancel");
        // Handle error (e.g., show error message)
      } else {
        console.log("Payment successful:", result);
        // navigate("/VideoChatForm/stripesuccess")
        // Handle success (e.g., redirect to success page)
      }
    } catch (error) {
      console.error("Error:", error);
      navigate("/cancel");
      // Handle network or other errors
    }
  };

  const getCityByStateId = async (event_one) => {
    const selectedStateId = event_one.target.value;
    setSelectedStateName(event_one.target.value);
    try {
      const cityresp = await axios.get(
        `${process.env.REACT_APP_API_URL}/location/getcities/${selectedStateId}`
      );
      setCityList(cityresp.data.data);
    } catch (error) {}

    //setCityList();
  };

  const getCityName = async (e1) => {
    const selectedcity = e1.target.value;
    setSelectedCityName(selectedcity);
  };

  const fetchCountriesList = () => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/location/getcountries`)
        .then((resp) => {
          setCountries(resp.data.data);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCountriesList();
  }, []);

  const getStateByCountryId = async (e) => {
    const selectedCountryId = e.target.value;
    setSelectedCountryName(e.target.value);

    try {
      const state_resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/location/getstates/${selectedCountryId}`
      );
      setStates(state_resp.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  //Getting user email based on the loggedin user ID
  const getUserData = async () => {
    try {
      const userdata = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/${userId}`
      );
      getUserEmail(userdata.data.data.email);
    } catch (error) {
      // Handle error
    }
  };

  //Get Order Information based on the order ID
  const getOrderDatabyOrderId = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        // Add any other headers if needed
      };
      const getOrderInfo = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/getorderdetails/${orderId}`,
        {
          headers: headers,
        }
      );
      getOrderData(getOrderInfo.data.data);
    } catch (error) {
      console.log(error, "Getting error in order info");
    }
  };

  useEffect(() => {
    getUserData();
    getOrderDatabyOrderId();
  }, []); // Empty dependency array to run the effect only once after the initial render

  useEffect(() => {
    const storedLoginResponse = localStorage.getItem("loginResponse");
    const loginResponse = storedLoginResponse
      ? JSON.parse(storedLoginResponse)
      : null;
    const token =
      loginResponse && loginResponse.data && loginResponse.data.tokan
        ? loginResponse.data.tokan
        : null;

    if (paymentsuccess === "Payment was approved!") {
      localStorage.removeItem("cartItems");
      //Email functionality for sending email
      const mailid = useremail;
      const deliveryEmail = orderdata?.deliveryEmail;

      const subject = "Order Placed Successfully";
      const html = `<p>Hello, 
      <b>${orderdata?.userdata?.fname} ${orderdata?.userdata?.lname}</b> 
      <br> Your Order Placed Successfully for the ladies profiles 
      <b>#${orderdata?.ladiesdata?.fname} ${orderdata?.ladiesdata?.lname}</b> of amount 
      <b> $ ${orderdata?.amount} </b> for service type 
      <b> ${orderdata?.paymentType}</b> Your Order ID is : ${orderId} Transaction Id is : <br> ${transId} </b> </p>`;

      const notify_admin = 1;

      const mail_data = { mailid, deliveryEmail, subject, html, notify_admin };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/svconsultation/sendCustomMail`,
          mail_data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((mail_response) => {
          navigate("/welcome");
          alert(mail_response?.data?.msg);
        })
        .catch((err) => console.log(err));
    }
  }, [paymentsuccess, orderdata, useremail, token, navigate]);

  return (
    <>
      <section className="page-info-sec">
        <div
          className="bg-fixed"
          style={{ backgroundImage: "url('./img/inner_banner.png')" }}
        ></div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>Shipping Address</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur male
                </p>
                <p className="page_nav">
                  {" "}
                  <a href="/">Home</a>
                  <span className="mx-2">/</span>
                  <a href="#" className="currentPage">
                    Shipping Address
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="video-chat-form-sec secpd70">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-from-wrap">
                <div
                  className="main-from-inner-wrap"
                  style={{ display: "block" }}
                >
                  <div>
                    <h2 className="title-page">Shipping Address</h2>
                  </div>
                  <div className="formwrapper">
                    <div className="col-md-6 mx-auto">
                      {insertsuccess && (
                        <div
                          className="alert alert-success mt-3 text-center"
                          role="alert"
                        >
                          {insertsuccess}
                        </div>
                      )}
                    </div>
                    <form onSubmit={handleFormSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Name<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={fname}
                              name="fname"
                              className="form-control"
                              onChange={(e) => setFirstName(e.target.value)}
                              required
                            />
                            {errors.fname && (
                              <p style={{ color: "red" }}>{errors.fname}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Email<span className="required">*</span>
                            </label>
                            <input
                              type="email"
                              value={email}
                              name="email"
                              className="form-control"
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                            {errors.email && (
                              <p style={{ color: "red" }}>{errors.email}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Contact<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={contact}
                              name="contact"
                              className="form-control"
                              onChange={(e) => setContact(e.target.value)}
                              required
                            />
                            {errors.contact && (
                              <p style={{ color: "red" }}>{errors.contact}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Country<span className="required">*</span>
                            </label>
                            <select
                              className="form-select"
                              name="country"
                              aria-label="Default select example"
                              value={form_country}
                              onChange={getStateByCountryId}
                              required
                            >
                              <option value="">Select</option>
                              {getcountries &&
                                getcountries.map((countrylist, index) => {
                                  return (
                                    <option key={index} value={countrylist.id}>
                                      {countrylist.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              State<span className="required">*</span>
                            </label>
                            <select
                              className="form-select"
                              name="state"
                              aria-label="Default select example"
                              value={form_state}
                              onChange={getCityByStateId}
                              required
                            >
                              <option value="">Select</option>
                              {states_list &&
                                states_list.length > 0 &&
                                states_list.map((statelist, index) => {
                                  return (
                                    <option key={index} value={statelist.id}>
                                      {statelist.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              City<span className="required">*</span>
                            </label>
                            <select
                              className="form-select"
                              name="city"
                              aria-label="Default select example"
                              value={form_city}
                              onChange={getCityName}
                              required
                            >
                              <option value="">Select</option>
                              {city_list &&
                                city_list.length > 0 &&
                                city_list.map((citylist, index) => {
                                  return (
                                    <option key={index} value={citylist.id}>
                                      {citylist.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Delivery Address
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={deliveryAddress}
                              name="deliveryAddress"
                              className="form-control"
                              onChange={(e) =>
                                setDeliveryAddress(e.target.value)
                              }
                              required
                            />
                            {errors.deliveryAddress && (
                              <p style={{ color: "red" }}>
                                {errors.deliveryAddress}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Zip Code<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={form_zipcode}
                              name="zipcode"
                              className="form-control"
                              onChange={(e) =>
                                setSelectedZipcode(e.target.value)
                              }
                              required
                            />
                            {errors.zipcode && (
                              <p style={{ color: "red" }}>{errors.zipcode}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Delivery Date<span className="required">*</span>
                            </label>
                            <input
                              type="date"
                              value={deliveryDate}
                              name="deliveryDate"
                              className="form-control"
                              onChange={(e) => setDeliveryDate(e.target.value)}
                              required
                              min={
                                new Date(Date.now() + 24 * 60 * 60 * 1000)
                                  .toISOString()
                                  .split("T")[0]
                              }
                            />
                            {errors.deliveryDate && (
                              <p style={{ color: "red" }}>
                                {errors.deliveryDate}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          {paypalerror && (
                            <div
                              className="alert alert-danger mt-3"
                              role="alert"
                            >
                              {paypalerror}
                            </div>
                          )}
                          {paymentsuccess && (
                            <div
                              className="alert alert-success mt-3"
                              role="alert"
                            >
                              {paymentsuccess}
                            </div>
                          )}
                          {showPaymentOptions && (
                            <div className="payment-options">
                              {/* <label className="payment-option">
                              <input
                                type="radio"
                                name="payment-option"
                                value="Stripe"
                                onClick={(e) =>
                                  setPaymentSelectedMethod(e.target.value)
                                }
                              />
                              <span className="mark"></span>
                              <img src="./img/Stripe.png" alt="" />
                            </label> */}
                              {paymentError && (
                                <p style={{ color: "red" }}>{paymentError}</p>
                              )}
                              <label className="payment-option">
                                <input
                                  type="radio"
                                  name="payment-option"
                                  value="Paypal"
                                  onClick={(e) =>
                                    setPaymentSelectedMethod(e.target.value)
                                  }
                                />
                                <span className="mark"></span>
                                <img src="./img/PayPal.png" alt="" />
                              </label>

                              {/* <label className="payment-option">
                              <input
                                type="radio"
                                name="payment-option"
                                value="cod"
                                onClick={(e) =>
                                  setPaymentSelectedMethod(e.target.value)
                                }
                              />
                              <span className="mark"></span>
                              <img src="./img/cod.png" alt="" />
                            </label> */}
                            </div>
                          )}
                          <p style={{ position: "relative", left: "37%" }}>
                            Click below to make a payment.
                          </p>
                        </div>
                        <div className="col-md-12">
                          {price === null && (
                            <button
                              type="submit"
                              className="btn-1 "
                              style={{
                                position: "relative",
                                left: "24%",
                                width: "50%",
                              }}
                            >
                              Submit
                            </button>
                          )}

                          {price &&
                            setFormSubmittedpaymentMethod === "Paypal" && (
                              <div
                                id="buttons-container"
                                className="paypal-payment"
                                style={{
                                  position: "relative",
                                  left: "106px",
                                }}
                                // ref={paypalButtonRef}
                              >
                                {/* PayPal button */}
                                <PayPalScriptProvider
                                options={{
                                  clientId:
                                    "AUEp11EuL5kicqTcKbLssOG1tToIyRW1_yT7jZ1we8QkS-TTgg4o6ALCebTlojWLl-89j6L1adLGCVlQ",
                                }}
                                  
                                >
                                  <PayPalButtons
                                    style={{
                                      layout: "horizontal",
                                    }}
                                    createOrder={(data, actions) => {
                                      return actions.order.create({
                                        purchase_units: [
                                          {
                                            amount: {
                                              value: price,
                                              currency_code: "USD",
                                            },
                                          },
                                        ],
                                      });
                                    }}
                                    onApprove={(data, actions) => {
                                      setPaymentSuccess(
                                        "Payment was approved!"
                                      );
                                      const transactionId = data.orderID;
                                      settransId(transactionId);

                                      /**
                                       * If payment dones successfully so it will update data based on the payment id
                                       */
                                      const orderStatus = "complete";
                                      const orderDate = new Date();
                                      const update_form_data = {
                                        orderStatus: orderStatus.toString(),
                                        transactionId: transactionId.toString(),
                                        orderDate: orderDate.toString(),
                                      };

                                      try {
                                        const { data } = axios.put(
                                          `${process.env.REACT_APP_API_URL}/order/orderpayment/${orderId}`,
                                          update_form_data,
                                          {
                                            headers: {
                                              Authorization: `Bearer ${token}`,
                                              "Content-Type":
                                                "application/x-www-form-urlencoded",
                                            },
                                          }
                                        );

                                        // Handle the response data as needed
                                      } catch (error) {
                                        navigate("/cancel");

                                        if (error.response) {
                                          console.error(
                                            "Server responded with error status:",
                                            error.response.status
                                          );
                                          console.error(
                                            "Error response data:",
                                            error.response.data
                                          );
                                          setErrors({
                                            form: "An error occurred during updation. Please try again later.",
                                          });
                                        } else if (error.request) {
                                          console.error(
                                            "No response received from the server:",
                                            error.request
                                          );
                                          setErrors({
                                            form: "No response received from the server. Please try again later.",
                                          });
                                        } else {
                                          console.error(
                                            "Error during request setup:",
                                            error.message
                                          );
                                          setErrors({
                                            form: "An error occurred during updation. Please try again later.",
                                          });
                                        }
                                      }

                                      /**
                                       * If payment dones successfully so it will update data based on the payment id
                                       */
                                      //window.location.href = `${process.env.REACT_APP_SITE_URL}/Singlevacationconsulatant/paymentReturn?transaction_id=${transactionId}&order_id=${transactionId}&status=complete`;

                                      return actions.order.capture();
                                    }}
                                    onError={(err) => {
                                      console.error("Payment error:", err);
                                      setPaypalError("Payment error:", err);

                                      //window.location.href = `${process.env.REACT_APP_SITE_URL}/VideoChatForm?status=failed`;
                                    }}
                                  />
                                </PayPalScriptProvider>
                              </div>
                            )}

                          {price &&
                            setFormSubmittedpaymentMethod === "Stripe" && (
                              <div>
                                {/* <form
                                  // action={`${process.env.REACT_APP_API_URL}/svconsultation/create-checkout-session`}
                                  action={
                                    (`${process.env.REACT_APP_API_URL}/order/GiftStripePayment`,
                                    {
                                      headers: {
                                        Authorization: `Bearer ${token}`,
                                        // Accept: "application/json",
                                      },
                                    })
                                  }
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="orderid"
                                    value={order_id}
                                  />
                                  <input
                                    type="hidden"
                                    name="pricekey"
                                    value={price}
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Checkout
                                  </button>
                                </form> */}
                                <div>
                                  <button
                                    onClick={handleCheckout}
                                    // type="submit"
                                    className="btn btn-primary"
                                    // disabled={isLoading}
                                  >
                                    {"Checkout"}
                                  </button>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WrappedShippedForm;
