import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import AdminNavigation from "../AdminNavigation";
import ViewOrders from "./ViewOrders";
import { Checkbox } from "@mui/material";

const storedLoginResponse = localStorage.getItem("loginResponse");
const loginResponse = storedLoginResponse
  ? JSON.parse(storedLoginResponse)
  : null;
const token =
  loginResponse && loginResponse.data && loginResponse.data.tokan
    ? loginResponse.data.tokan
    : null;

const Orders = () => {
  const [orderData, setOrderData] = useState(null);
  const [errors, setErrors] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // State to track the current page
  const [totalPages, setTotalPages] = useState(0); // State to track the total number of pages
  const [pageSize, setPageSize] = useState(5); // Number of orders per page
  const [viewOrderId, setViewOrderId] = useState(null);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  const fetchOrderData = () => {
    const storedLoginResponse = localStorage.getItem("loginResponse");
    const loginResponse = storedLoginResponse
      ? JSON.parse(storedLoginResponse)
      : null;
    const token =
      loginResponse && loginResponse.data && loginResponse.data.tokan
        ? loginResponse.data.tokan
        : null;

    axios
      .get(`${process.env.REACT_APP_API_URL}/order/adminorderlist/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        params: {
          pageNumber: currentPage,
          pageSize: pageSize,
          search: searchTerm, // Add the search term as a parameter
        },
      })
      .then((Data) => {
        setOrderData(Data.data.data.data, "Data");
        setTotalPages(Data.data.data.totalPages || 1);
      })
      .catch((error) => {
        if (error.response) {
          setErrors({
            form: "An error occurred.",
          });
        } else {
          setErrors({
            form: "An error occurred.",
          });
        }
      });
  };

  useEffect(() => {
    fetchOrderData(searchTerm);
  }, [currentPage, pageSize, searchTerm]);

  // Function to handle page changes
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  // Calculate the total sum of quantities in Giftcarts for each order
  const calculateTotalQuantity = (giftcarts) => {
    // Parse the quantities and sum them up
    return giftcarts.reduce(
      (total, giftcart) => total + parseInt(giftcart.quantity, 10),
      0
    );
  };

  const handleOrderModal = (ordreId) => {
    setViewOrderId(ordreId);
    setOpenViewDialog(true);
  };

  const handleCheckboxChange = async (orderId, statusType) => {
    // Determine the new status based on the statusType ("Complete" or "Delivered")
    const newStatus = statusType;

    try {
      // Send a PATCH request to update the order status
      await axios.put(
        `${process.env.REACT_APP_API_URL}/order/orderupdate/${orderId}`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );

      // Update the order data locally to reflect the new status
      setOrderData((prevData) =>
        prevData.map((order) =>
          order.id === orderId ? { ...order, status: newStatus } : order
        )
      );
      window.location.reload();
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Update search term state
  };

  return (
    <>
      <div id="wrapper" className="toggled">
        <AdminNavigation />
        <div className="container-fluid ">
          <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-2">
            <h1 className="h3 mb-0 text-gray-800 fontBold">Orders List</h1>
          </div>

          <div className="card shadow mb-4">
            <div className="card-body">
              <div
                className="table-responsive"
                style={{ overflowX: "inherit" }}
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6" style={{ width: "100%" }}>
                    <div
                      id="dataTable_filter"
                      style={{ float: "right" }}
                      className="dataTables_filter"
                    >
                      <label>
                        {/* Search input */}
                        <input
                          type="search"
                          placeholder="search "
                          className="form-control form-control-sm"
                          aria-controls="dataTable"
                          onChange={handleSearchChange}
                        />
                      </label>
                    </div>
                  </div>
                  <table
                    className="table table-bordered"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th scope="col" width="5%">
                          No
                        </th>
                        <th width="8%">Order No</th>
                        <th width="10%">Customer</th>
                        <th width="10%">Ladies Name</th>
                        <th width="10%">Payment Type</th>
                        <th width="10%">Order Date</th>
                        <th width="8%">Total Qty</th>
                        <th width="col">Status</th>
                        <th width="10%">Net Amount</th>
                        <th width="10%">Transaction ID</th>
                        <th width="40%">Change Order Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderData?.length > 0 ? (
                        orderData.map((data, index) => (
                          <tr key={index}>
                            <td scope="row">{index + 1}</td>
                            <td
                              className="text-primary text-decoration-none"
                              title="View Order Details"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleOrderModal(data.id);
                              }}
                            >
                              {data.id}
                            </td>
                            <td>
                              {data?.userdata?.fname} {data?.userdata?.lname}
                            </td>
                            <td>
                              {data?.ladiesdata?.fname}{" "}
                              {data?.ladiesdata?.lname}
                            </td>
                            <td>{data?.paymentType}</td>
                            <td>
                              {new Date(data.orderDate).toLocaleDateString()}
                            </td>

                            <td>{calculateTotalQuantity(data.Giftcarts)}</td>
                            <td>{data.orderStatus}</td>
                            <td>${data.amount}</td>
                            <td>{data.transactionId}</td>
                            <td>
                              {/* Checkboxes for Complete and Delivered */}
                              <div>
                                {/* Checkbox for Complete */}
                                <Checkbox
                                  checked={data.orderStatus === "Complete"}
                                  onClick={() =>
                                    handleCheckboxChange(data.id, "Complete")
                                  }
                                />
                                <label>Complete</label>

                                {/* Checkbox for Delivered */}
                                <Checkbox
                                  checked={data.orderStatus === "Delivered"}
                                  onClick={() =>
                                    handleCheckboxChange(data.id, "Delivered")
                                  }
                                />
                                <label>Delivered</label>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="noRecordFound">
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {/* Pagination controls */}
                  <nav aria-label="Page navigation example">
                    <ul className="pagination" style={{ float: "right" }}>
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          Previous
                        </a>
                      </li>
                      {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                        (page) => (
                          <li
                            key={page}
                            className={`page-item ${
                              currentPage === page ? "active" : ""
                            }`}
                          >
                            <a
                              className="page-link"
                              onClick={() => handlePageChange(page)}
                            >
                              {page}
                            </a>
                          </li>
                        )
                      )}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* View Classes dialog */}
      <ViewOrders
        open={openViewDialog}
        onClose={() => setOpenViewDialog(false)}
        // schoolData={schoolData}
        orderId={viewOrderId}
      />
    </>
  );
};

export default Orders;
