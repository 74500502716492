import axios from 'axios';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
function ForgetPassword(){

  const [pageContent, setPageContent] = useState(null);
  const [loading,setLoading] = useState(true);//Add a loader 
  useEffect(()=>{
    const fetchPageContent = async()=>{
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/slug/forgetpassword`);
       // console.log(response,"responseresponse");
        setPageContent(response.data.data['0']);
        
      } catch (error) {
        console.error('Error fetching page content:', error);
      }finally {
        setLoading(false); // Set loading to false whether the request succeeds or fails
      }
    
    }
    fetchPageContent();
    },[]);


  const [email,setEmail] = useState("");
  const [error, setError] = useState(null); // State variable to hold the error message
  const [values, setValues] = useState({
    email: email,
  });
  const handleSubmit = async(e)=>{
    e.preventDefault();
    setError(null); 
    const forgetEmail ={email};
    const TOKEN = localStorage.getItem('adminToken');
    let response = await axios.post(`${process.env.REACT_APP_API_URL}/users/forgetpassowrd`, forgetEmail,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },      
    }).then((response) => {
      console.log(response);
      
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with an error status
          
          
        } else if (error.request) {
          // The request was made but no response was received
          setError('No response received from the server.');
        } else {
          // Something else happened while setting up the request
          setError('An error occurred. Please try again later.');
        }
         }); 
  };
  const handleChange = name => e => {
    setValues({ ...values, [email]: e.target.value });
};
return(
    <>
    <div className="contant"> 
    
    {error && <div className="error-message">{error}</div>}
  <section className="LoginMain">
      <div className="loginIn">
        <div className="loginBg loginPageBg" style={{backgroundImage:`url(${pageContent && pageContent.bannerurl})`}}></div>
        <div className="loginHalf">
          <div className="loginForm">
            <div className="LoginTitle">
            <h1>{pageContent ? pageContent.bannertitle : 'Forgot Password'}</h1>
                  <p>{pageContent ? pageContent.bannerdesc : ''}</p>
              
            </div>
            
            <form onSubmit={handleSubmit}>
              <div className="mb-3 mb-md-4">
                <label className="form-label" >Username or Email Address<span className="required">*</span></label>
                <input type="email" name='email' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="DavidP@xyz.com" className="form-control" aria-describedby="emailHelp" required/>
              </div>
             
              {/* <Link to = {"/resetpassword"} className="btn-1"></Link> */}
              <button type="submit" className="btn-1">Get New Password</button>
            </form>
          </div>
        </div>
      </div>
  </section>
  </div>
    </>
);
}
export default ForgetPassword