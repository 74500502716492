import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
function ProfileImageGallery() {
  const [images, setProfilePic] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const userid = localStorage.getItem("userId");
  const formData = new FormData();
  const TOKEN = localStorage.getItem("adminToken");
  const fileInputRef = useRef(null);

  const handelFileUpload = (event) => {
    const images = Array.from(event.target.files);
    setProfilePic(images);
  };

  const handelSubmit = async (submitevent) => {
    submitevent.preventDefault();
    // Check if at least one image is selected
    if (images.length === 0) {
      // Display an error message or take appropriate action
      console.error("Please select at least one image");
      setErrorMessage("Please select at least one image!");

      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      return;
    }
    images.forEach((file, index) => {
      formData.append("images", file);
    });
    formData.append("user_id", userid);

    const TOKEN = localStorage.getItem("adminToken");
    console.log("Token", TOKEN);
    console.log(formData, "Form Data");
    try {
      const images_resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/gallery/add/`,
        formData, // Use the existing formData
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the Content-Type header for file uploads
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );

      console.log("response", images_resp);
      getGalleryImages();
      setSuccessMessage("Photos Uploaded successfully!");
      setProfilePic([]); // Clear the state
      fileInputRef.current.value = ""; // Clear the file input field
      // You can also automatically hide the success message after 5 seconds if needed
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    } catch (error) {
      // Handle the error here
      console.error("Error:", error);
    }
  };

  //Get all uploaded images

  const [slidergallery, setSliderImages] = useState([]);

  const getGalleryImages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/gallery/getbyid/${userid}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );
      console.log("GALLERY DATA", response.data.data);
      setSliderImages(response.data.data);
    } catch (error) {
      console.error("Error fetching gallery images:", error);
    }
  };
  useEffect(() => {
    getGalleryImages();
  }, []);

  //For deleting gallery images
  const handleRemoveGalleryImage = (imageId) => {
    //console.log("Image ID"+imageId);

    try {
      if (window.confirm("Do You want to delete ?")) {
        axios
          .delete(
            `${process.env.REACT_APP_API_URL}/gallery/delete/${imageId}`,
            {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
              },
            }
          )
          .then(() => {
            console.log("delete data");
            setSuccessMessage("Image Removed successfully!");
            getGalleryImages();
            // You can also automatically hide the success message after 5 seconds if needed
            setTimeout(() => {
              setSuccessMessage(null);
            }, 5000);
          });
      }
    } catch (error) {
      console.error("Error deleting gallery images:", error);
    }
  };

  return (
    <>
      <section className="page-info-sec">
        <div
          className="bg-fixed"
          style={{ backgroundImage: "url('./img/inner_banner.png')" }}
        ></div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>Upload Gallery Photos</h1>
                <p>Upload Gallery Photos</p>
                <p className="page_nav">
                  {" "}
                  <a href="/">Home</a>
                  <span className="mx-2">/</span>
                  <a href="#" className="currentPage">
                    Upload Gallery Photos
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="video-chat-form-sec secpd70">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <h3>Gallery Images:</h3>
              <div className="row">
                {slidergallery.map((gallerydata, index) => (
                  <div className="col-md-3 my-2">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${gallerydata.image}`}
                    />
                    <span
                      className="del_icon"
                      onClick={() => handleRemoveGalleryImage(gallerydata.id)}
                    >
                      X
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mx-auto">
              {successMessage && (
                <div
                  className="alert alert-success mt-3 text-center"
                  role="alert"
                >
                  {successMessage}
                </div>
              )}
              {errorMessage && (
                <div
                  className="alert alert-danger mt-3 text-center"
                  role="alert"
                >
                  {errorMessage}
                </div>
              )}
              <form onSubmit={handelSubmit} encType="multipart/form-data">
                <label className="mb-2">Upload Photos:</label>
                <input
                  type="file"
                  name="images"
                  id="images"
                  className="form-control"
                  multiple="multiple"
                  onChange={handelFileUpload}
                  ref={fileInputRef}
                ></input>
                <button type="submit" className="btn btn-primary my-3">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ProfileImageGallery;
