import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import  AdminNavigation  from './AdminNavigation';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
function UpdateSliderSettings(){
  
    const [slider_img1, setSliderImage1] = useState('');
    const [slider_img2, setSliderImage2] = useState('');
    const [slider_img3, setSliderImage3] = useState('');
    const [id, setID] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const siteUrl = process.env.REACT_APP_SITE_URL;

    const navigate = useNavigate();
    useEffect(() => {
        setID(localStorage.getItem('ID'))
        setSliderImage1(localStorage.getItem('Slider Image1'));
        setSliderImage2(localStorage.getItem('Slider Image2'));
        setSliderImage3(localStorage.getItem('Slider Image3'));
       
}, []);


const TOKEN = localStorage.getItem('adminToken');
    console.log(TOKEN);
    const updateSelectedSettingData = () => {
     let res =   axios.put(`${process.env.REACT_APP_API_URL}/setting/${id}`, {
        slider_img1,slider_img2,slider_img3}, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      });
    if(res){
            console.log(res);
         //alert("updated"+res);
         //alert(siteUrl+"/admin/pages");
          
            // setSuccessMessage("Updated Successfully!");
            // window.location.href = `${siteUrl}/admin/pages`;
            navigate("/admin/settings");
        }else{
          console.log(res);
           //alert("failed to update");
        }
    }
    return(<>
     <div id="wrapper" className="toggled">
   <AdminNavigation/>
        
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mx-auto"></div>
                    {successMessage && (
              <div className="alert alert-success mt-3 text-center" role="alert">
                {successMessage}
              </div>
            )}
                <h2>Update Slider Images</h2>
                <form>
                    <label>Slider Image 1:</label>
                    <input type="text" name="slider_img1" id="slider_img1" className="form-control" placeholder="Enter Slider Image1" value={slider_img1} onChange = {(e) => setSliderImage1(e.target.value)} required/><br/>
                    <label>Slider Image 2:</label>
                    <input type="text" name="slider_img2" id="slider_img2" className="form-control" placeholder="Enter Slider Image2" value={slider_img2} onChange = {(e) => setSliderImage2(e.target.value)} required/><br/>
                    <label>Slider Image 3:</label>
                    <input type="text" name="slider_img3" id="slider_img3" className="form-control" placeholder="Enter Slider Image3" value={slider_img3} onChange = {(e) => setSliderImage3(e.target.value)} required/><br/>
                    <input type="submit" onClick={updateSelectedSettingData} className="btn btn-primary mb-2" id='update_logo' value="Update Slider"/>

                </form>
    </div>
    </div>
    </div>
    </div>
    </>)

}
export default UpdateSliderSettings