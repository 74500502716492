import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import  AdminNavigation  from './AdminNavigation';
import { useNavigate } from "react-router-dom";
function UpdateContactEmailSettingsData(){
    const[contact_email,setAContactEmail] = useState(''); 
    const[id,setID]=useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        setID(localStorage.getItem('ID'));
        setAContactEmail(localStorage.getItem('Contact Email'));
       
       
}, []);
const TOKEN = localStorage.getItem('adminToken');
console.log(TOKEN);

const updateSelectedContactEmailSettingData = () => {
    let res =   axios.put(`${process.env.REACT_APP_API_URL}/setting/${id}`, {
        contact_email}, {
       headers: {
         Authorization: `Bearer ${TOKEN}`
       }
     });
   if(res){
           console.log(res);
          // alert("updated"+res);
          // window.location.href = "/admin/pages";
          navigate("/admin/settings");
       }else{
         console.log(res);
          //alert("failed to update");
       }
   }

   return(<>
    <div id="wrapper" className="toggled">
     <AdminNavigation/>
          
          <div id="page-content-wrapper">
              
              <div className="container">
                  <div className="row">
                      <div className="col-md-4 mx-auto"></div>
                  <h2>Update Contact Email</h2>
                  <form>
                     
                      <label>Contact Email:</label>
                      <textarea name="contact_email" id="contact_email" className="form-control" placeholder="Enter Site URL" value={contact_email} onChange = {(e) => setAContactEmail(e.target.value)} required></textarea>
                      <br/>
                      <input type="submit" onClick={updateSelectedContactEmailSettingData} className="btn btn-primary mb-2" id='update_contact_email' value="Update Contact Email"/>
  
                  </form>
      </div>
      </div>
      </div>
      </div>
   </>
   )
   

}
export default UpdateContactEmailSettingsData