import axios from "axios";
import React, { useState, useEffect } from "react";

function CustomerFeedback() {
  const [feedbackList, setFeedbackList] = useState([]);
  const API_URL = `${process.env.REACT_APP_API_URL}/feedback`;
  const IMG_API_URL = `${process.env.REACT_APP_API_URL}`;
  const SITE_URL = `${process.env.REACT_APP_SITE_URL}/feedback`;
  const [loading, setLoading] = useState(true);

//   const getFeedbackListingData = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.get(API_URL);
//       //const data = response.data.data;
//       const data = JSON.parse(response.data.data);
//       console.log('JSONNNN',data);
      
//       setFeedbackList(data);
//     } catch (error) {
//       console.error("Error fetching data: ", error);
//     } finally {
//       setLoading(false);
//     }
//   };

const getFeedbackListingData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(API_URL);
      const data = response.data.data;
  
      // Encode the data as JSON if it's not already in JSON format
      const jsonData = typeof data === 'string' ? JSON.parse(data) : data;
  
      // Loop through the jsonData array and merge User properties into each item
      for (let i = 0; i < jsonData.length; i++) {
        const user = jsonData[i].User;
        if (user) {
          jsonData[i] = { ...jsonData[i], ...user };
        }
      }
  
      console.log(jsonData, "Merged Data");
  
      setFeedbackList(jsonData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };
  
  

  useEffect(() => {
    getFeedbackListingData();
  }, []);

//console.log(feedbackList,"FEEDBACK LIST");

  return (
    <>
      <section className="page-info-sec">
        <div
          className="bg-fixed"
          style={{ backgroundImage: "url('./img/inner_banner.png')" }}
        ></div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>Customer Feedback</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur male
                </p>
                <p className="page_nav">
                  <a href="/">Home</a>
                  <span className="mx-2">/</span>
                  <a href="#" className="currentPage">
                    Customer Feedback
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="find-woman-sec secpd70">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <div className="container my-5">
                <div
                  id="testimonialCarousel"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                 <div className="carousel-inner">
  {feedbackList.reduce((chunks, feedback, index) => {
    const chunkIndex = Math.floor(index / 3);
    if (!chunks[chunkIndex]) {
      chunks[chunkIndex] = [];
    }
    chunks[chunkIndex].push(feedback);
    return chunks;
  }, []).map((feedbackChunk, chunkIndex) => (
    <div
      key={chunkIndex}
      className={`carousel-item ${chunkIndex === 0 ? "active" : ""}`}
    >
      <div className="row">
        {feedbackChunk.map((feedback, index) => (
          <div key={index} className="col-md-4 text-center">
            
            <img
            src={feedback.profile_pic ? `${IMG_API_URL}${feedback.profile_pic}` : './img/default-profile.jpeg'}
            alt="avatar"
            className="rounded-circle shadow-1-strong mb-4"
            style={{ width: "150px" }}
            />
            <h5 className="mb-3">{feedback.title}</h5>
            <p>{feedback.occupation}</p>
            <p className="text-muted">
              <i className="fas fa-quote-left pe-2"></i>
              {feedback.comments}
            </p>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              {Array(feedback.rating).fill().map((_, i) => (
                <li key={i}>
                  <i className="fas fa-star fa-sm"></i>
                </li>
              ))}
              {Array(5 - feedback.rating).fill().map((_, i) => (
                <li key={i}>
                  <i className="far fa-star fa-sm"></i>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  ))}
</div>
<a className="carousel-control-prev" href="#testimonialCarousel"
                    role="button"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next" href="#testimonialCarousel"
                    role="button"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CustomerFeedback;
