import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Snackbar } from "@mui/material";
const stripePromise = loadStripe(
  "pk_test_51M7ESnSCq9c1L3o5dzZjWFWg7BzFr6Hl8TgJy0vzFSwdmjDXJZez5IibxUG0ocAjvhfmYG70gJKue2wVKjK6H3H100iagpGxlx"
);
const WrappedVideoChatForm = () => {
  return (
    <Elements stripe={stripePromise}>
      <SingleVacationConsulatant />
    </Elements>
  );
};

function SingleVacationConsulatant() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [paymentError, setPaymentError] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  //States lists
  const [states_list, setStates] = useState(null);
  //City list
  const [city_list, setCityList] = useState(null);
  //Paypal success message constant
  const [paymentsuccess, setPaymentSuccess] = useState("");
  //paypal error message constant
  const [paypalerror, setPaypalError] = useState("");
  /**
   * Form Fields and submisson section starts here
   */
  const location = useLocation();
  const { selected_ladies_id } = location.state || {};
  const { ladiesListData_email } = location.state || {};
  const [showPaymentOptions, setShowPaymentOptions] = useState(true);
  // const [svcId,setSvcId] = useState("");
  const [meetingUrl, setMeetingUrl] = useState();
  const [data, setData] = useState();
  const [fname, setFirstName] = useState("");
  const [lname, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [form_country, setSelectedCountryName] = useState(null);
  const [form_state, setSelectedStateName] = useState(null);
  const [form_city, setSelectedCityName] = useState(null);
  const [occupation, setOccupation] = useState("");
  const [profile_num_lady1, setLadyProfileNumberOne] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [profile_num_lady4, setLadyProfileNumberFour] = useState("");
  const [profile_num_lady5, setLadyProfileNumberFive] = useState("");
  const [profile_num_lady6, setLadyProfileNumberSix] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [video_chat_amount, setVideoChatAmount] = useState(null);
  const [consultation_time, setConsultationTime] = useState(null);
  const [Formtype, setFormType] = useState("singlevacationconsulatant");
  const [selectedPaymentMethod, setPaymentSelectedMethod] = useState(null);
  const [setFormSubmittedpaymentMethod, SetSubmittedPayment] = useState(null);
  const [paid_video_chat_amount, setPaidVideoChatAmount] = useState(null);
  const payment_type = selectedPaymentMethod;
  const payment_status = "Pending";
  const filterParameter = "gender";
  const [ladiesListingData, setLadiesListingData] = useState([]);
  const [selectedLady, setSelectedLady] = useState(selected_ladies_id || "");
  const userId = localStorage.getItem("userId");
  const [stripe_price_id, setStripePriceId] = useState(null);
  const [order_id, setOrderID] = useState(null);
  const [formData, setFormdata] = useState();
  const mergedProfileNumbers = [selectedLady]
    .filter((value) => value !== "")
    .join(",");
  const [insertsuccess, setInsertSuccess] = useState("");
  const [errors, setErrors] = useState({});
  const handelVideoChatForm = async (submit_event) => {
    submit_event.preventDefault();
    // Hide the payment options
    setShowPaymentOptions(false);
    const validationErrors = {};
    if (!fname) {
      validationErrors.fname = "First name is required.";
    }
    if (!lname) {
      validationErrors.lname = "First name is required.";
    }
    if (!age) {
      validationErrors.age = "Age is required.";
    }
    if (!form_country) {
      validationErrors.form_country = "Country is required.";
    }
    if (!form_state) {
      validationErrors.form_state = "State is required.";
    }
    if (!form_city) {
      validationErrors.form_city = "City is required.";
    }
    if (!occupation) {
      validationErrors.occupation = "Occupation is required.";
    }
    if (!selectedLady) {
      validationErrors.selectedLady = "Please Insert Ladies Profile Id.";
    }
    if (!consultation_time) {
      validationErrors.consultation_time = "Please Select Consulation Time.";
    }
    if (!payment_type) {
      validationErrors.payment_type = "Please Select PaymentMethod.";
    }

    let video_chat_amount = "";
    if (consultation_time === "30") {
      video_chat_amount = "50";
      setStripePriceId("price_1OXgiOSCq9c1L3o5lhQM6dYG");
    }
    if (consultation_time === "60") {
      video_chat_amount = "100";
      setStripePriceId("price_1OXgj0SCq9c1L3o5HBkOSwyX");
    }

    setErrors(validationErrors);

    setTimeout(() => {
      setErrors({});
    }, 10000);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      setFormSubmitted(false);
      const country = form_country;
      const state = form_state;
      const city = form_city;
      const profilenumber_ladies = mergedProfileNumbers;
      const amount = video_chat_amount;
      const type = Formtype;
      const user_id = userId;
      const video_chat_form_data = {
        fname,
        lname,
        age,
        country,
        state,
        city,
        occupation,
        profilenumber_ladies,
        consultation_time,
        amount,
        payment_type,
        payment_status,
        user_id,
        type,
        meetingDate,
        meetingTime,
      };

      try {
        const insert_form_data = await axios.post(
          `${process.env.REACT_APP_API_URL}/svconsultation/add`,
          video_chat_form_data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        SetSubmittedPayment(insert_form_data.data.data.payment_type);
        setFormdata(insert_form_data?.data?.data);

        setPaidVideoChatAmount(insert_form_data.data.data.amount);

        setOrderID(insert_form_data.data.data.id);

        setLoading(false);
        setInsertSuccess("Data Inserted Successfully!!");
      } catch (error) {
        if (error.response) {
          setErrors({
            form: "An error occurred during registration. Please try again later.",
          });
        } else if (error.request) {
          setErrors({
            form: "No response received from the server. Please try again later.",
          });
        } else {
          setErrors({
            form: "An error occurred during registration. Please try again later.",
          });
        }

        setLoading(false);
        setFormSubmitted(false);
      }
    }
  };
  useEffect(() => {
    // Retrieve UserRole from localStorage
    const role = localStorage.getItem("userRole");
    setUserRole(role);
  }, []);
  useEffect(() => {
    const createAndJoinMeeting = async () => {
      const storedLoginResponse = localStorage.getItem("loginResponse");
      const loginResponse = storedLoginResponse
        ? JSON.parse(storedLoginResponse)
        : null;
      const userEmail = loginResponse?.data?.data?.email;
      const localStorageToken =
        loginResponse && loginResponse.data && loginResponse.data.tokan
          ? loginResponse.data.tokan
          : null;
      const token = localStorageToken;
      const userId = localStorage.getItem("userId");

      const [year, month, day] = meetingDate.split("-").map(Number);
      const [hour, minute, second] = meetingTime.split(":").map(Number);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/zoom/create-meeting`,
          {
            userId: userId,
            ladiesId: selected_ladies_id || selectedLady,
            // ladiesId: 40,
            translaterId: "",
            meetingDuration: consultation_time,
            svcId: order_id,
            meetingDateTime: [year, month, day, hour, minute, second],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        const meetingData = response?.data?.data?.meetingdata;
        const joinUrl = meetingData?.join_url;
        setMeetingUrl(joinUrl);

        const mailid = [ladiesListData_email, userEmail].filter(Boolean);

        const subject = "Meeting Created Successfully";

        const html = `
  <p>Hello, 
    <b>${formData?.fname} ${formData?.lname}</b> 
    <br> Your meeting has been successfully created. 
    Your Meeting ID is: ${meetingData?.meetingId} <br>
    <b>Please join the meeting using this URL: 
      <a href="${joinUrl}" target="_blank">${joinUrl}</a>
    </b>
  </p>`;

        const notify_admin = 1;

        const mail_data = {
          mailid,
          subject,
          html,
          notify_admin,
        };

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/svconsultation/sendCustomMail`,
            mail_data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((mail_response) => {
            navigate("/welcome");
          });
      } catch (error) {
        console.error("Error creating the meeting", error);
      }
    };
    if (paymentsuccess) {
      createAndJoinMeeting();
    } else {
    }
  }, [paymentsuccess, consultation_time, order_id, selected_ladies_id]);

  const fetchLadiesData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/getfiltereduser`,
        {
          [filterParameter]: "female",
        }
      );

      setLadiesListingData(response.data.data);
      setLoading(false);
    } catch (error) {
      //console.error('ERROR:', error);
    }
  };
  useEffect(() => {
    fetchLadiesData();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedLady(event.target.value);
  };

  const handleTimeChange = (event) => {
    const timeValue = event.target.value;
    const formattedTime = `${timeValue}:00`;
    setMeetingTime(formattedTime);
  };

  const [getcountries, setCountries] = useState([]);
  /**
   * Get Countries listing
   */
  const fetchCountriesList = () => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/location/getcountries`)
        .then((resp) => {
          setCountries(resp.data.data);
        });
    } catch (error) {
      console.log(error, "Country dropdown");
    }
  };
  useEffect(() => {
    fetchCountriesList();
  }, []);

  const [pageContent, setPageContent] = useState(null);
  //constant for loader and call it's
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/pages/slug/Singlevacationconsulatant`
        );
        setPageContent(response.data.data["0"]);
      } catch (error) {
        //console.error('Error fetching page content:', error);
        console.error("Error fetching page data:", error);
        if (error.response.status === 401) {
          navigate("/login");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchPageContent();
  }, []);
  if (loading) {
    // Render a loading overlay with the "Loading..." message
    return (
      <div className="loading-overlay">
        {/* <div className="loading-content">Loading...</div> */}
        <img src="/img/loader.gif"></img>
      </div>
    );
  }

  if (!pageContent) {
    return null; // Or render some loading/error message
  }
  const style = {
    backgroundImage: `url('${pageContent.bannerurl}')`,
    // Add other CSS properties as needed
  };

  //Get State isting Based on the Country Id
  const getStateByCountryId = async (e) => {
    const selectedCountryId = e.target.value;
    setSelectedCountryName(e.target.value);

    try {
      const state_resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/location/getstates/${selectedCountryId}`
      );
      setStates(state_resp.data.data);
    } catch (error) {}
  };

  /**
   * Get City name based on the state id
   */

  const getCityByStateId = async (event_one) => {
    const selectedStateId = event_one.target.value;
    setSelectedStateName(event_one.target.value);
    try {
      const cityresp = await axios.get(
        `${process.env.REACT_APP_API_URL}/location/getcities/${selectedStateId}`
      );
      setCityList(cityresp.data.data);
    } catch (error) {}

    //setCityList();
  };

  const getCityName = async (e1) => {
    const selectedcity = e1.target.value;
    setSelectedCityName(e1.target.value);
  };

  const handlePaymentOptionClick = (e) => {
    if (!userRole) {
      // Redirect to login page after a short delay (optional)
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } else {
      // Set the selected payment method
      setPaymentSelectedMethod(e.target.value);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
      <section className="page-info-sec">
        <div className="bg-fixed" style={style}></div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>
                  {pageContent ? pageContent.bannertitle : "Video Chat Form"}
                </h1>
                <p>
                  {pageContent
                    ? pageContent.bannerdesc
                    : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur malesuada risus quis ligula pellentesque fermentum. Aenean vel ex finibus, lacinia lacus non, sodales dolor. Phasellus eget turpis neque. Sed aliquet nec felis non faucibus. Quisque euliy tristique elit, sit amet laoreet neque. Vestibulum aliquam at augue at dictum. Praes ent interdum blandit elit, nec ullamcorper odio."}
                </p>
                <p className="page_nav">
                  {" "}
                  <a href="/">Home</a>/
                  <a href="#" className="currentPage">
                    {pageContent ? pageContent.bannertitle : "Video Chat Form"}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="video-chat-form-sec secpd70">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-from-wrap">
                {pageContent.pagedesc && (
                  <div
                    dangerouslySetInnerHTML={{ __html: pageContent.pagedesc }}
                  ></div>
                )}
                <div
                  className="main-from-inner-wrap"
                  style={{ display: "block" }}
                >
                  {/* <div className="title-centered text-center">
                    <h2 className="title-30 mb-3">Video Chats with Ladies</h2>
                    <p>
                      In the comfort of your home, you can relax and enjoy
                      talking and staring at an attractive lady on your computer
                      screen. You would both have an assigned translator who is
                      willing to help you communicate well and strengthen your
                      relationship. Thus, if you wish to start a viertual
                      connection with Ms. Right, Fill out and submit the form
                      below:
                    </p>
                  </div> */}

                  <div className="formwrapper">
                    <div className="col-md-6 mx-auto">
                      {insertsuccess && (
                        <div
                          className="alert alert-success mt-3 text-center"
                          role="alert"
                        >
                          {insertsuccess}
                        </div>
                      )}
                    </div>
                    <form onSubmit={handelVideoChatForm}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              First Name<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={fname}
                              name="fname"
                              className="form-control"
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                            {errors.fname && (
                              <p className="text-danger">{errors.fname}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Last Name<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={lname}
                              name="lname"
                              className="form-control"
                              onChange={(e) => setLastName(e.target.value)}
                            />
                            {errors.lname && (
                              <p className="text-danger">{errors.lname}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Age<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={age}
                              name="age"
                              className="form-control"
                              onChange={(e) => setAge(e.target.value)}
                            />
                            {errors.age && (
                              <p className="text-danger">{errors.age}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Country<span className="required">*</span>
                            </label>
                            <select
                              className="form-select"
                              name="country"
                              aria-label="Default select example"
                              value={form_country}
                              onChange={getStateByCountryId}
                            >
                              <option value="">Select</option>
                              {getcountries &&
                                getcountries.map((countrylist, index) => {
                                  return (
                                    <option key={index} value={countrylist.id}>
                                      {countrylist.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.form_country && (
                              <p className="text-danger">
                                {errors.form_country}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              State<span className="required">*</span>
                            </label>
                            <select
                              className="form-select"
                              name="state"
                              aria-label="Default select example"
                              value={form_state}
                              onChange={getCityByStateId}
                            >
                              <option value="">Select</option>
                              {states_list &&
                                states_list.length > 0 &&
                                states_list.map((statelist, index) => {
                                  return (
                                    <option key={index} value={statelist.id}>
                                      {statelist.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.form_state && (
                              <p className="text-danger">{errors.form_state}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-md-4">
                            <label>
                              City<span className="required">*</span>
                            </label>
                            <select
                              className="form-select"
                              name="city"
                              aria-label="Default select example"
                              value={form_city}
                              onChange={getCityName}
                            >
                              <option value="">Select</option>
                              {city_list &&
                                city_list.length > 0 &&
                                city_list.map((citylist, index) => {
                                  return (
                                    <option key={index} value={citylist.id}>
                                      {citylist.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.form_city && (
                              <p className="text-danger">{errors.form_city}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div>
                            <label>
                              Occupation<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={occupation}
                              name="occupation"
                              className="form-control"
                              onChange={(e) => setOccupation(e.target.value)}
                            />
                            {errors.occupation && (
                              <p className="text-danger">{errors.occupation}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-12">
                          <hr className="form-divider" />
                        </div>

                        <div className="col-12">
                          <h3 className="title-20">Profile Name of Ladies</h3>
                        </div>

                        <div className="col-md-4">
                          <div className="mb-3 mb-md-4">
                            <label>
                              Lady Name<span className="required">*</span>
                            </label>

                            <select
                              className="form-control"
                              value={selectedLady}
                              onChange={handleSelectChange}
                            >
                              <option value="">Select a lady</option>
                              {ladiesListingData.map((lady) => (
                                <option key={lady.id} required value={lady.id}>
                                  {lady.fname}{" "}
                                </option>
                              ))}
                            </select>

                            {errors.selectedLady && (
                              <p className="text-danger">
                                {errors.selectedLady}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="mb-3 mb-md-4">
                            <label>Meeting Date</label>
                            <input
                              type="date"
                              className="form-control"
                              name="profilenumber_ladies"
                              placeholder="20/06/2024"
                              value={meetingDate}
                              onChange={(e) => setMeetingDate(e.target.value)}
                              min={new Date().toISOString().split("T")[0]}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="mb-3 mb-md-4">
                            <label>Meeting Time</label>
                            <input
                              type="time"
                              className="form-control"
                              name="profilenumber_ladies"
                              placeholder="10:00:00"
                              value={meetingTime.slice(0, 5)}
                              onChange={handleTimeChange}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <label>
                            Choose one <span className="required">*</span>
                          </label>
                          <div className="checklist">
                            <label className="checkwrap-1">
                              <div className="cis_checkbox">
                                <input
                                  type="radio"
                                  name="consultation_time"
                                  onChange={(e) =>
                                    setConsultationTime(e.target.value)
                                  }
                                  value="30"
                                />
                                <span className="mark"></span>
                                30 minutes ($50)
                              </div>
                            </label>
                            <label className="checkwrap-1">
                              <div className="cis_checkbox">
                                <input
                                  type="radio"
                                  name="consultation_time"
                                  onChange={(e) =>
                                    setConsultationTime(e.target.value)
                                  }
                                  value="60"
                                />
                                <span className="mark"></span>
                                60 minutes ($100)
                              </div>
                            </label>
                            {errors.consultation_time && (
                              <p className="text-danger">
                                {errors.consultation_time}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          {paypalerror && (
                            <div
                              className="alert alert-danger mt-3"
                              role="alert"
                            >
                              {paypalerror}
                            </div>
                          )}
                          {paymentsuccess && (
                            <div
                              className="alert alert-success mt-3"
                              role="alert"
                            >
                              {paymentsuccess}
                            </div>
                          )}
                          {showPaymentOptions && (
                            <div className="payment-options">
                              {/* <label className="payment-option">
                              <input
                                type="radio"
                                name="payment-option"
                                value="stripe"
                                onClick={(e) =>
                                  setPaymentSelectedMethod(e.target.value)
                                }
                              />
                              <span className="mark"></span>
                              <img src="./img/Stripe.png" alt="" />
                            </label> */}
                              {paymentError && (
                                <p style={{ color: "red" }}>{paymentError}</p>
                              )}
                              <label className="payment-option">
                                <input
                                  type="radio"
                                  name="payment-option"
                                  value="paypal"
                                  // defaultChecked
                                  onClick={handlePaymentOptionClick}
                                />
                                <span className="mark"></span>
                                <img src="./img/PayPal.png" alt="" />
                              </label>
                            </div>
                          )}
                          {userRole && (
                            <p>
                              Click below to make a payment. There is no refund
                              when your video chat with a consultant begins.
                            </p>
                          )}
                        </div>
                        <div className="col-md-12">
                          {userRole && paid_video_chat_amount === null && (
                            <button type="submit" className="btn-1 w-100">
                              Submit
                            </button>
                          )}
                          {paid_video_chat_amount &&
                            setFormSubmittedpaymentMethod === "paypal" && (
                              <div>
                                {/* PayPal button */}
                                <PayPalScriptProvider
                                  options={{
                                    clientId:
                                      "AUEp11EuL5kicqTcKbLssOG1tToIyRW1_yT7jZ1we8QkS-TTgg4o6ALCebTlojWLl-89j6L1adLGCVlQ",
                                  }}
                                  
                                >
                                  <PayPalButtons
                                    style={{ layout: "horizontal" }}
                                    createOrder={(data, actions) => {
                                      return actions.order.create({
                                        purchase_units: [
                                          {
                                            amount: {
                                              value: paid_video_chat_amount,
                                              currency_code: "USD",
                                            },
                                          },
                                        ],
                                      });
                                    }}
                                    onApprove={(data, actions) => {
                                      setPaymentSuccess(
                                        "Payment was approved!"
                                      );
                                      const transactionId = data.orderID;

                                      /**
                                       * If payment dones successfully so it will update data based on the payment id
                                       */
                                      const payment_status = "complete";
                                      const transaction_id = transactionId;
                                      const update_video_chat_form_data = {
                                        payment_status,
                                        transaction_id,
                                      };
                                      try {
                                        const { data } = axios.get(
                                          `${process.env.REACT_APP_API_URL}/svconsultation/paymentReturn?transaction_id=${transactionId}&order_id=${order_id}&status=${payment_status}`,
                                          update_video_chat_form_data,
                                          {
                                            headers: {
                                              "Content-Type":
                                                "application/json",
                                            },
                                          }
                                        );
                                        // Handle the response data as needed
                                      } catch (error) {
                                        if (error.response) {
                                          console.error(
                                            "Server responded with error status:",
                                            error.response.status
                                          );
                                          console.error(
                                            "Error response data:",
                                            error.response.data
                                          );
                                          setErrors({
                                            form: "An error occurred during updation. Please try again later.",
                                          });
                                        } else if (error.request) {
                                          console.error(
                                            "No response received from the server:",
                                            error.request
                                          );
                                          setErrors({
                                            form: "No response received from the server. Please try again later.",
                                          });
                                        } else {
                                          console.error(
                                            "Error during request setup:",
                                            error.message
                                          );
                                          setErrors({
                                            form: "An error occurred during updation. Please try again later.",
                                          });
                                        }
                                      }

                                      return actions.order.capture();
                                    }}
                                    onError={(err) => {
                                      console.error("Payment error:", err);
                                      setPaypalError("Payment error:", err);
                                    }}
                                  />
                                </PayPalScriptProvider>
                              </div>
                            )}

                          {paid_video_chat_amount &&
                            setFormSubmittedpaymentMethod === "stripe" && (
                              <div>
                                <form
                                  action={`${process.env.REACT_APP_API_URL}/svconsultation/create-checkout-session`}
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="orderid"
                                    value={order_id}
                                  />
                                  <input
                                    type="hidden"
                                    name="pricekey"
                                    value={stripe_price_id}
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Checkout
                                  </button>
                                </form>
                              </div>
                            )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Snackbar Component */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Please log in to fill the form."
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </>
  );
}
export default WrappedVideoChatForm;
