import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import  AdminNavigation  from './AdminNavigation';
function FeedbackList(){
  useEffect(() => {
    // Check if 'userId' is not present in localStorage
    if (!localStorage.getItem('userRole') && localStorage.getItem('userRole')!== 1) {
      // Redirect to the "/login" page if 'userId' is not present
      window.location.href = "/login";
    }
  }, []); // The empty dependency array ensures that this effect runs once when the component mounts

//Define our variable for getting feedback list data
const [feedbacklist,setFeedbackList] = useState([]);
const API_URL = `${process.env.REACT_APP_API_URL}/feedback`;
// Define a loading state variable
const [loading, setLoading] = useState(true);

const getFeedbackListingData = async ()=>{
     // Set loading to true to indicate that data fetching is in progress
  setLoading(true);
  try {
      const response = await axios.get(`${API_URL}`);
      const data = response.data.data;
      //console.log(response,"Feedback Data");
      setFeedbackList(data)
      
  } catch (error) {
    console.error("Error fetching data: ", error);
      
  }finally {
    setLoading(false); // Set loading to false whether the request succeeds or fails
  }

};

useEffect(() => {
    getFeedbackListingData();
  }, []);
//console.log(feedbacklist,"feedback data");

//Delete Functionality 
 const delFeedBackdata = (id) => {
    //console.log(id);
    const TOKEN = localStorage.getItem('adminToken');
    console.log('TOKEN====',TOKEN);
    if (window.confirm('Do You want to delete this page ?')) {
    axios.delete(`${process.env.REACT_APP_API_URL}/feedback/delete/${id}`,{ headers: {
        Authorization: `Bearer ${TOKEN}`,
      },}).then(() => {
        getFeedbackListingData();
    })
    }
 }

 

return(<>
<div id="wrapper" className="toggled">
   <AdminNavigation/>
        
        <div id="page-content-wrapper">
            {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-lg-offset-2">
                    <h5>All Feedback</h5>
                    <table className="table table-dark table-striped">
                        <thead>
                        <tr>
                            <th>Sno</th>
                            <th>Useranme</th>
                            <th>Rating</th>
                            <th>Title</th>
                            <th>Comment</th>
                            <th>Images</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                            <th colSpan={2}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            {feedbacklist.map((feedbacklistData,key)=>{
                                    return(<><tr>
                                        <td>{key+1}</td>
                                        <td>{feedbacklistData.user_id}</td>
                                        <td>{feedbacklistData.rating}</td>
                                        <td>{feedbacklistData.title}</td>
                                        <td>{feedbacklistData.comments}</td>
                                        <td>{feedbacklistData.attachfile}</td>
                                        <td>{feedbacklistData.createdAt}</td>
                                        <td>{feedbacklistData.updatedAt}</td>
                                        <td><button onClick={() => delFeedBackdata(feedbacklistData.id)} className='btn btn-danger'>Delete</button></td>
                                        </tr></>)
                                }
                                
                            )}
                        
                            </tbody>
                            </table>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
</>)
}
export default FeedbackList