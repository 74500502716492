import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
// import {} from "react-router-dom"
import SearchResults from './SearchResults';
const API_URL = `${process.env.REACT_APP_API_URL}/pages/`;
const HEADER_LOGO_API_URL = `${process.env.REACT_APP_API_URL}/setting/getsetting/`;

function Header() {
  const [pageslist, setPagesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [headerLogo,setHeaderLogo]=useState([]);
  const navigate = useNavigate();
  const siteUrl = process.env.REACT_APP_SITE_URL;
  useEffect(() => {
    // Fetch pages data
    const fetchPagesData = async () => {
      try {
        const storedLoginResponse = localStorage.getItem('loginResponse');
        const loginResponse = storedLoginResponse ? JSON.parse(storedLoginResponse) : null;
        const token = loginResponse && loginResponse.data && loginResponse.data.tokan ? loginResponse.data.tokan : null;

        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setPagesList(response.data.data);
       // console.log("RESPDATA",storedLoginResponse.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching pages:', error);
        setIsLoading(false);
        if (error.response.status === 401) {
          navigate("/login");
        }
      }

      //console.log(HEADER_LOGO_API_URL);
      const storedLoginResponse = localStorage.getItem('loginResponse');
        const loginResponse = storedLoginResponse ? JSON.parse(storedLoginResponse) : null;
        const token = loginResponse && loginResponse.data && loginResponse.data.tokan ? loginResponse.data.tokan : null;
      const header_response = await axios.get(HEADER_LOGO_API_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      //console.log("Header Logo",header_response.data.data[0].logo)
      setHeaderLogo(header_response.data.data[0].logo);

    };

    fetchPagesData();

    // Check if the user is already logged in
    const storedLoginResponse = localStorage.getItem('loginResponse');
    const loginResponse = storedLoginResponse ? JSON.parse(storedLoginResponse) : null;
    if (loginResponse && loginResponse.data && loginResponse.data.tokan) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    const userId = localStorage.getItem('userId');
    localStorage.removeItem('userId'); // Remove the specific userId item from localStorage
    localStorage.clear(); // Clear all items from localStorage
    setIsLoggedIn(false); // Update the state to indicate the user is logged out
   // window.location.href = siteUrl; // Redirect to the site URL
   navigate("/login");
};

  const [search, setSearch] = useState('');

  // State variable to manage search results
  const [showResults, setShowResults] = useState(false);

//Search functionality for the header section
const handleSearch = async (e) => {
  e.preventDefault();
  navigate(`/searchresults/${search}`);
  
};
const userRole = localStorage.getItem('userRole');
const handleNavbarToggle = () => {
  // Close the navbar toggle section when a link is clicked (for mobile view)
  const navbarToggler = document.querySelector(".navbar-toggler");
  const navbarCollapse = document.querySelector(".navbar-collapse");
  if (navbarToggler && navbarCollapse) {
    navbarToggler.classList.add("collapsed");
    navbarCollapse.classList.remove("show");
  }
};


const userId = localStorage.getItem('userId');
console.log(userId,"Logged In user ID");
const css = userId && userId == ''
  ? `ul.f_link li:first-child { display: block; }` // If userId is greater than 0, display the feedback option
  : `ul.f_link li:first-child { display: none; }`; // Otherwise, hide the feedback option

const style = document.createElement('style'); // Create a style element
style.appendChild(document.createTextNode(css)); // Append the CSS to the style element
document.head.appendChild(style); 
//console.log(storedLoginResponse,"hello");
//console.log(userRole,"hello jarviss status");


const handleNavigation = (event) => {
  event.preventDefault();
  navigate('/');
};
  return (
    <>
     {userRole !== '1' && (
        
      <>
      <div className="topHeader">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="headerIn">
                <form className="d-flex" onSubmit={handleSearch}>
                  <div className="input-group mb-0 header-search-form">
                    <input className="form-control" type="search" placeholder="Search" aria-label="Search" onChange={(e) => setSearch(e.target.value)} value={search}  />
                    <span className="input-group-text">
                      <button type="submit" className="input-group-text" onClick={handleSearch}>  
                          <i className="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </span>
                  </div>
                </form>
                <div className="right-btns">
                  {userRole == '0' || userRole == '2'   ? (
                    // Show logout button when user is logged in
                    <><button className="btn-1" onClick={handleLogout}>
                      Logout
                    </button>
                    
                    {userRole === '0' && (
                  <Link className="btn-white" to="/welcome">
                    My Profile
                  </Link>
                )}
                {userRole === '2' && (
                  <Link className="btn-white" to="/translatordashboard">
                    Translator
                  </Link>
                )}
                    </>
                  ) : (
                    // Show login and signup buttons when user is not logged in
                    <>
                      <Link className="btn-1" to="/login">
                        Login
                      </Link>
                      <Link className="btn-white" to="/register">
                        Sign up
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header className="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
            <a className="navbar-brand" href="/" onClick={handleNavigation}>
            <img src={`${headerLogo}`} alt="" className='logo_img'/>
        </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarScroll"
                aria-controls="navbarScroll"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarScroll">
                <ul className="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
                  {isLoading || pageslist.length === 0 ? (
                    // Static menu items when data is not available or loading
                    <>
                      <li className="nav-item">
                        <Link className="nav-link" to="/">Home</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/aboutus">About Us</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/contactus">Contact Us</Link>
                      </li>
                      {/* Add more static menu items as needed */}
                    </>
                  ) : (
                    // Dynamic menu when data is available
                    pageslist.map((page) => (
                      page.template!= 2 && page.template!=4 ? (
                        <li className="nav-item" key={page.id}>
                          {page.slug === 'home' ? (
                            <Link className="nav-link" to="/">
                              {page.pagetitle}
                            </Link>
                          ) : (
                            <Link className="nav-link" to={`/${page.slug}`} onClick={handleNavbarToggle}>
                              {page.pagetitle}
                            </Link>
                          )}
                        </li>
                      ) : null
                    ))
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
      </>
      )}
      <div className="search-results">
      {/* {showResults && <SearchResults results={searchResults} />}
                    <ul>
                    {searchResults.length > 0 ? ( 
                      // Only render when there are search results
                      searchResults.map((result) => (
                        <li key={result.id}>
                          <Link to={`/${result.slug}`}>{result.pagetitle}</Link>
                        </li>
                      ))
                    ) : null}
                  </ul> */}
                </div>
                
    </>
  );
}

export default Header;
