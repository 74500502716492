import React from "react";
import { Link, Redirect } from "react-router-dom";
import { isAuthenticated } from "./auth"; // Import the isAuthenticated helper function
import "../.././App.css";

function AdminNavigation() {
  const logout = () => {
    localStorage.clear();
    // you can also like localStorage.removeItem('Token');
    window.location.href = "/login";
  };
  return (
    <>
      <div className="overlay"></div>
      <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu" />
      {/* <nav
        className="navbar navbar-inverse fixed-top"
        id="sidebar-wrapper"
        role="navigation"
      > */}
      <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
        <div className="spinner diagonal part-1"></div>
        <div className="spinner horizontal"></div>
        <div className="spinner diagonal part-2"></div>
      </label>
      <div id="sidebarMenu">
    <ul className="nav sidebar-nav ">
        <div className="sidebar-header">
            <div className="sidebar-brand">
                <Link className="mb-2 mt-2" to="/admin/dashboard">
                    <img src={`https://spnode4002.elb.cisinlive.com//upload/images/1694521513633.png`}></img>
                </Link>
            </div>
        </div>
        <li>
            <Link className="" to="/admin/dashboard">
                <i className="fas fa-tachometer-alt"></i> Dashboard
            </Link>
        </li>
        <li>
            <Link className="" to="/admin/allusers">
                <i className="fas fa-users"></i> All Users
            </Link>
        </li>
        
        <li>
            <Link className="" to="/admin/settings">
                <i className="fas fa-cog"></i> Settings
            </Link>
        </li>
        <li>
            <Link className="" to="/admin/admin_video_playlist">
                <i className="fas fa-video"></i> Video Listing
            </Link>
        </li>
       
        <li>
            <Link className="" to="/admin/categories">
                <i className="fas fa-gift"></i> All Gifts
            </Link>
        </li>
            <ul className="submenu">
                <li>
                    <Link className="" to="/admin/categories">
                        Categories
                    </Link>
                </li>
                <li>
                    <Link className="" to="/admin/giftlist">
                        Gifts
                    </Link>
                </li>
            </ul>
      
        <li>
            <Link className="" to="/admin/orders">
                <i className="fas fa-shopping-cart"></i> Orders
            </Link>
        </li>
        <li>
            <Link className="" to="/admin/adminfaqsection">
                <i className="fas fa-question-circle"></i> FAQ Listing
            </Link>
        </li>
        <li>
            <Link className="" to="/admin/video_chat_form_data_listing">
                <i className="fas fa-video"></i> Video Chat Form
            </Link>
        </li>
        <li>
            <Link className="" to="/admin/consultation_form_data_listing">
                <i className="fas fa-comment"></i> Consultation
            </Link>
        </li>
        <li>
            <Link className="" to="/admin/alltranslators">
                <i className="fas fa-language"></i> All Translator
            </Link>
        </li>
        <li>
            <Link className="" to="/admin/translator">
                <i className="fas fa-user-plus"></i> Add Translator
            </Link>
        </li>
        <li>
            <Link className="" to="/admin/createmeeting">
                <i className="fas fa-user-plus"></i> Add Meeting
            </Link>
        </li>
        <li>
            <Link className="" to="/admin/media">
                <i className="fas fa-file-video-o"></i> Media
            </Link>
        </li>
        <li>
            <Link className="" to="/admin/pages">
                <i className="fas fa-clone"></i> Pages
            </Link>
        </li>
        <li>
            <Link className="" onClick={logout}>
                <i className="fas fa-sign-out-alt"></i> Logout
            </Link>
        </li>
    </ul>
</div>

      {/* </nav> */}
    </>
  );
}
export default AdminNavigation;
