import axios from "axios";
import React, { useState } from "react";

function UploadPhotos() {
  const [profilePic, setProfilePic] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const userid = localStorage.getItem('userId');
  console.log("Loggedin user id", userid);
  const formData = new FormData(); // Initial FormData

  const handelFileUpload = (event) => {
    const profilePic = Array.from(event.target.files);
    setProfilePic(profilePic);
  }

  const handelSubmit = async (submitevent) => {
    submitevent.preventDefault();
    profilePic.forEach((file, index) => {
      formData.append("profile_pic", file);
    });
    formData.append('user_id', userid);

    const TOKEN = localStorage.getItem('adminToken');

    try {
      const profile_pic_resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/profilepic/`,
        formData, // Use the existing formData
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the Content-Type header for file uploads
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );

      console.log('response', profile_pic_resp);
      setSuccessMessage('Photos Uploaded successfully!');
    } catch (error) {
      // Handle the error here
      console.error('Error:', error);
    }
  }

  return (
    <>
      <section className="page-info-sec">
        <div className="bg-fixed" style={{backgroundImage:"url('./img/inner_banner.png')"}}>
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col">
              <div className="page-info-block">
                <h1>Upload Photos</h1>
                <p>Upload Photos</p>
                <p className="page_nav"> <a href="/">Home</a><span  className="mx-2">/</span><a href="#" className="currentPage">Upload Photos</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="video-chat-form-sec secpd70">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto">
              {successMessage && (
                <div className="alert alert-success mt-3 text-center" role="alert">
                  {successMessage}
                </div>
              )}
              <form onSubmit={handelSubmit} encType="multipart/form-data">
                <label className="mb-2">Upload Photos:</label>
                <input type="file" name="profile_pic" id="profile_pic" className="form-control" multiple="multiple" onChange={handelFileUpload}></input>
                <button type="submit" className="btn btn-primary my-3">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UploadPhotos;
