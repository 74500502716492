import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import './admin_css.css';
import './responsive.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Aboutus from './components/Aboutus';
import Login from './components/Login';
import Register from './components/Register';
import ForgetPassword from './components/Forgetpassword';
import ResetPassword from './components/Resetpassword';
import VideoChatForm from './components/Videochatform';
import Ladies from './components/Ladies';
import ContactUs from './components/Contactus';
import SingleVacationConsulatant from './components/SingleVacationConsultant';
import VideoPlayList from './components/VideoPlayList';
import Dashboard from './components/admin/Dashboard';
import Allusers from './components/admin/Allusers';
import UpdateUserData from './components/Updateuser';
import Pages from './components/admin/Pages';
import UpdatePageData from './components/admin/UpdatePages';
import AddPages from './components/admin/Addpages';
import Welcome from './components/Welcome';
import LadiesProfile from './components/Ladies_profile';
import  { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SearchResults from './components/SearchResults';
import Settings from './components/admin/Settings';
import UpdateSettingsData from './components/admin/UpdateSetting';
import UpdateSliderSettings from './components/admin/UpdateSliderSettings';
import AdminVideoPlaylist from './components/admin/VideoListing';
import AddVideos from './components/admin/AddVideos';
import UpdateVideosData from './components/admin/EditVidos';
import AdminAddUsers from './components/admin/AddUsers';
import UpdateUserDataByAdmin from './components/admin/EditUsers';
import Media from './components/admin/Media';
import AddMedia from './components/admin/AddMedia';
import UpdateMedia from './components/admin/EditMedia';
import UpdateFooterSetting from './components/admin/UpdateFooterSetting';
import UpdateSiteURLSettingsData from './components/admin/updateSiteURLSettingsData';
import UpdateAPIURLSettingsData from './components/admin/updateAPIURLSettingsData';
import UpdateAdminEmailSettingsData from './components/admin/updateAdminEmailSettingsData';
import UpdateContactEmailSettingsData from './components/admin/updateContactEmailSettingsData';
import TermsConditions from './components/TermsConditions';

import PrivacyPolicy from './components/PrivacyPolicy';

import Feedback from './components/Feedback';
import FeedbackList from './components/admin/Feedbacklisting';
import ConsultationFormDataListing from './components/admin/ConsultationFormDataListing';

/**
 * Welcome section Upload Photo component imported below
 */
import UploadPhotos from './components/UploadPhotos';

/**
 *Welcome section Update Profile section  
*/
import UpdateProfile from './components/UpdateProfile';

/**
 * FAQ Section component
 */
import FaqSection from './components/FaqSection';


import AdminFAQSection from './components/admin/AdminFAQSection';

import SingleLadiesProfile from './components/SingleLadiesProfile';

import CustomerFeedback from './components/CustomerFeedback';

import AddFaqs from './components/admin/AddFaqs';


import UpdateFAQData from './components/admin/UpdateFAQData';

//Deactivate account section component included
import DeactivateAcc from './components/DeactivteAcc';

//Profile image gallery section for uploading multiple images for the slider
import ProfileImageGallery from './components/ProfileImageGallery'; 


import VideoChatFormDataListing from './components/admin/VideoChatFormDataListing';

/**
 * User video chat listing 
 */
import MyVideoChats from './components/MyVideoChats';

import MyConsultation from './components/MyConsultation';

import StripeSuccess from './components/StripeSuccess';

import StripeCancel from './components/StripeCancel';

//Translator section
import Translator from './components/admin/Translator';

import TranslatorDashboard from './components/translator/TranslatorDashboard';
import RequestedCallListing from './components/translator/RequestedCallListing';


import AllTranslators from './components/admin/AllTranslators';

import UpdateTranslatorsDataByAdmin from './components/admin/EditTranslators';

import CreateaMeeting from './components/admin/CreateaMeeting';


// edit by 2585
import GiftCatgeories from './components/GiftCategories'
import Gift from './components/Gift'
import Checkout from './components/Checkout'
import UserOrder from './components/UserOrder'
import Category from "./components/admin/categories/CategoryList";
import GiftList from "./components/admin/gift/GiftList";
import Cart from "./components/Cart";
import Orders from "./components/admin/order/Orders";
import OrderDetails from "./components/OrderDetails";

import VideoChatLog from "./components/Video_chat_log";
import Downloads from './components/Downloads';

import ViewandAssignTranslator from './components/admin/ViewandAssignTranslator';


function App() {

const API_URL = `${process.env.REACT_APP_API_URL}/pages/`;
  const [pageslist, setPagesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [role,setRole] = useState(0)
  //console.log(role,"userRoleAppfile" );

  useEffect(() => {

    const userRole = localStorage.getItem('userRole')
    if(userRole == null){
      setRole(0);
    }
    else if(userRole == 1){
      //console.log('coming')
      setRole(1);
    }

  })

  //console.log("ROLE IS",role);


  useEffect(() => {
    // Fetch pages data
    const fetchPagesData = async () => {
      try {
        const storedLoginResponse = localStorage.getItem('loginResponse');
        const loginResponse = storedLoginResponse ? JSON.parse(storedLoginResponse) : null;
        const token = loginResponse && loginResponse.data && loginResponse.data.tokan ? loginResponse.data.tokan : null;

        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setPagesList(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching pages:', error);
        setIsLoading(false);
      }
    };

    fetchPagesData();

    // Check if the user is already logged in
    const storedLoginResponse = localStorage.getItem('loginResponse');
    const loginResponse = storedLoginResponse ? JSON.parse(storedLoginResponse) : null;
    if (loginResponse && loginResponse.data && loginResponse.data.tokan) {
      setIsLoggedIn(true);
    }
  }, []);

  /**
   * Custom function for managing login role 
   */
  const onCustomHandle = (data) => {
    //console.log("sdf", data)
    setRole(data)
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
        {/* {pageslist.map((page) => (
                    <Route path={page.slug} element={<Aboutus />} />
        ))} */}
                  
          <Route path="/"  element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/login" element={<Login onCustom={onCustomHandle}/>} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/VideoChatForm" element={<VideoChatForm />} />
          <Route path="/ladies" element={<Ladies />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/Singlevacationconsulatant" element={<SingleVacationConsulatant />} />
          <Route path="/videoplaylist" element={<VideoPlayList />} />
          <Route path="/ladies_profile" element={<LadiesProfile />} />
          <Route path="/searchresults/:data" element={<SearchResults />} />
          <Route path="/termsconditions" element={<TermsConditions />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path='/admin/dashboard' element={<Dashboard />} />
          <Route path='/admin/allusers' element={<Allusers />} />
          <Route path="/update" element={<UpdateUserData />} />
          <Route path="/admin/updatepagedata" element={<UpdatePageData />} />
          <Route path="/admin/pages" element={<Pages />} />
          <Route path="/admin/add_pages" element={<AddPages />} />
          <Route path="/admin/settings" element={<Settings />} />

          {/* *********2585 start********** */}
          <Route path="/giftcategory/:ladiesId" element={<GiftCatgeories />} />
          <Route
            path="/giftcategory/:ladiesId/gift/:categoryId"
            element={<Gift />}
          />
          <Route
            path="/giftcategory/:ladiesId/gift/:categoryId/cart"
            element={<Cart />}
          />
          <Route path="/make-payment" element={<Checkout />} />
          <Route path="/user_order" element={<UserOrder />} />
          <Route path="/video_logs" element={<VideoChatLog />} />
          <Route path="/view-details/:orderId" element={<OrderDetails />} />
          {/* *********2585 end********** */}

             {/* *********2585 start********** */}
             <Route path="/admin/categories" element={<Category />} />

<Route path="/admin/giftlist" element={<GiftList />} />
<Route path="/admin/orders" element={<Orders />} />

{/* *********2585 end********** */}

          <Route path="/admin/updatesettingdata" element={<UpdateSettingsData />} />
          <Route path="/admin/update_slider_settingdata" element={<UpdateSliderSettings />} />
          <Route path="/admin/update_footer_settingdata" element={<UpdateFooterSetting />} />
          <Route path="/admin/update_site_url_settingdata" element={<UpdateSiteURLSettingsData />} />
          <Route path="/admin/update_api_url_settingdata" element={<UpdateAPIURLSettingsData />} />
          <Route path="/admin/update_admin_email_settingdata" element={<UpdateAdminEmailSettingsData />} />
          <Route path="/admin/update_contact_email_settingdata" element={<UpdateContactEmailSettingsData />} />
         
          <Route path="/admin/admin_video_playlist" element={<AdminVideoPlaylist />} />
          <Route path="/admin/add_video_playlist" element={<AddVideos />} />
          <Route path="/admin/edit_video" element={<UpdateVideosData />} />
          <Route path="/admin/add_users" element={<AdminAddUsers />} />
          <Route path="/admin/update_users" element={<UpdateUserDataByAdmin />} />
          <Route path="/admin/update_translators" element={<UpdateTranslatorsDataByAdmin />} />
          <Route path="/admin/media" element={<Media />} />
          <Route path="/admin/add_media" element={<AddMedia />} />
          <Route path="/admin/update_media" element={<UpdateMedia />} />
          <Route path="/admin/feedbacklisting" element={<FeedbackList />} />
          <Route path="/admin/adminfaqsection" element={<AdminFAQSection />} />
          <Route path="/admin/addfaq" element={<AddFaqs />} />
          <Route path="/admin/updatefaqdata" element={<UpdateFAQData/>} />
          <Route path="/admin/video_chat_form_data_listing" element={<VideoChatFormDataListing/>} />
          <Route path="/admin/consultation_form_data_listing" element={<ConsultationFormDataListing/>} />
          {/* Adding our translator component and set path for that */}
          <Route path="/admin/translator" element={<Translator/>} />
          
          
          <Route path="/welcome" element={<Welcome />} />
          {/* Section for managing welcome section menus  */}
          <Route path="/uploadphotos" element={<UploadPhotos />} />
          <Route path="/updateprofile" element={<UpdateProfile />} />
          <Route path="/faq" element={<FaqSection />} />
          <Route path="/singleLadiesProfile/:ladiesid" element={<SingleLadiesProfile />} />
          <Route path="/customerfeedback" element={<CustomerFeedback />} />
          <Route path="/deactivateaccount" element={<DeactivateAcc />} />
          <Route path="/profile_image_gallery" element={<ProfileImageGallery />} />
          <Route path="/myvideo-chats" element={<MyVideoChats />} />
          <Route path="/myconsultation" element={<MyConsultation />} />
           <Route path="/VideoChatForm/stripesuccess/" element={<StripeSuccess />} />
           <Route path="/cancel/" element={<StripeCancel/>} />

        {/**Transaltor section starts here */}
        <Route path='/translatordashboard' element={<TranslatorDashboard/>}/>
        <Route path='/requestedcallslisitng' element={<RequestedCallListing/>}/>
        <Route path='/admin/alltranslators' element={<AllTranslators/>}/>
        <Route path='/admin/createmeeting' element={<CreateaMeeting/>}/>
        <Route path='/admin/viewandassigntranslators/:callID' element={<ViewandAssignTranslator/>}/>

        {/*  Add download page path added by vaibhav ga on june 11 2024  */}
        <Route path='/downloads' element={<Downloads/>}/>


        </Routes>
        
        {role == '0' ? <Footer /> : null}
        {/* <Footer />  */}
      </BrowserRouter>
    </div>
  );
}

export default App;
