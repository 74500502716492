import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { isAuthenticated } from './auth';
import AdminNavigation from './AdminNavigation';

function Media() {
  useEffect(() => {
    // Check if 'userId' is not present in localStorage
    if (!localStorage.getItem('userRole') && localStorage.getItem('userRole')!== 1) {
      // Redirect to the "/login" page if 'userId' is not present
      window.location.href = "/login";
    }
  }, []); // The empty dependency array ensures that this effect runs once when the component mounts

  const [medialist, setMediaListingData] = useState([]);
  const [successMessage, setSuccessMessage] = useState(''); // Add successMessage state variable
  
  const API_URL = `${process.env.REACT_APP_API_URL}/media`;

// Define a loading state variable
const [loading, setLoading] = useState(true);

  const fetchMediaListingData = () => {
    fetch(API_URL)
      .then((res) => res.json())
      .then((res) => {
        setMediaListingData(res.data);
      }).catch((error) => {
        // Handle any errors and hide the loader
        
        console.error('Error fetching user data:', error);
      }).finally(() => {
        // Hide the loader whether the request succeeds or fails
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMediaListingData();
  }, []);

  const delMediadata = (id) => {
    if (window.confirm('Do You want to delete ?')) {
      axios.delete(`${process.env.REACT_APP_API_URL}/media/delete/${id}`).then(() => {
          // Set a success message
        setSuccessMessage('Media deleted successfully.');
        fetchMediaListingData();
      });
    }
  };

  const updateMediadata = (mediaListData) => {
    // Update logic here
    let {id,path} = mediaListData;
    //console.log("Mdata",mediaListData);
    localStorage.setItem('ID', mediaListData.id);
    localStorage.setItem('Media path', mediaListData.path);
  };

  const [copySuccessMessages, setCopySuccessMessages] = useState({});

  const copyToClipboard = (text, id) => {
    navigator.clipboard.writeText(text).then(() => {
      // Clipboard text successfully copied
      setCopySuccessMessages({ ...copySuccessMessages, [id]: 'Copied to Clipboard'});
      setTimeout(() => {
        setCopySuccessMessages({ ...copySuccessMessages, [id]: '' });
      }, 5000); // Clear the success message after 3 seconds
    }).catch((error) => {
      console.error('Failed to copy to clipboard: ', error);
      setCopySuccessMessages({ ...copySuccessMessages, [id]: 'Failed to copy file path to clipboard.' });
    });
  };

  if (loading) {
    // Render a loading overlay with the "Loading..." message
    return (
      <div className="loading-overlay">
        {/* <div className="loading-content">Loading...</div> */}
        <img src='/img/loader.gif'></img>
      </div>
    );
  }

  return (
    <>
      <div id="wrapper" className="toggled">
        <AdminNavigation />
        <div id="page-content-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-lg-offset-2">
                <Link to={"/admin/add_media"} className="btn btn-primary">Add Media</Link>
                <h5>All Media Listing</h5>
                {/* Display success message */}
                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                )}
                <table className="table table-dark table-striped">
                  <thead>
                    <tr>
                      <th>Sno</th>
                      <th>Id</th>
                      <th>Copy Path</th>
                      <th>Image</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th colSpan={2}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {medialist.map((mediaListData, key) => {
                      return (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{mediaListData.id}</td>
                          <td>
                            <button
                              className="btn btn-warning btn-sm"
                              onClick={() => copyToClipboard(`${process.env.REACT_APP_API_URL}/${mediaListData.path}`, mediaListData.id)}
                            >
                              Copy File Path
                            </button>
                            {copySuccessMessages[mediaListData.id] && <p className="text-success">{copySuccessMessages[mediaListData.id]}</p>}
                          </td>
                          <td>
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${mediaListData.path}`}
                              style={{ height: "50px" }}
                              alt={`Media ${mediaListData.id}`}
                            />
                          </td>
                          <td>{mediaListData.createdAt}</td>
                          <td>{mediaListData.updatedAt}</td>
                          <td>
                            <Link to="/admin/update_media">
                              <button
                                onClick={() => updateMediadata(mediaListData)}
                                className="btn btn-primary"
                              >
                                Edit
                              </button>
                            </Link>
                          </td>
                          <td>
                            <button
                              onClick={() => delMediadata(mediaListData.id)}
                              className="btn btn-danger"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Media;
