import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import "./";
function StripeSuccess() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderid");
  const [data, setDataId] = useState();
  const [meetingUrl, setMeetingUrl] = useState();
  const ladiesEmail = data?.ladiesdata?.email;
  const meetingDate = data?.meetingDate;
  const meetingTime = data?.meetingTime;


  useEffect(() => {
    const storedLoginResponse = localStorage.getItem("loginResponse");
    const loginResponse = storedLoginResponse
      ? JSON.parse(storedLoginResponse)
      : null;
    const token =
      loginResponse && loginResponse.data && loginResponse.data.tokan
        ? loginResponse.data.tokan
        : null;
    const fetchSvcConsulantDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/svconsultation/get/${orderId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          }
        );
        setDataId(response?.data?.data);
      } catch (error) {
        console.error("please try again later", error);
      }
    };

    fetchSvcConsulantDetails();
  }, []);

  useEffect(() => {
    const createAndJoinMeeting = async () => {
      console.log("createAndJoinMeeting");
      const storedLoginResponse = localStorage.getItem("loginResponse");
      const loginResponse = storedLoginResponse
        ? JSON.parse(storedLoginResponse)
        : null;
      const userEmail = loginResponse?.data?.data?.email;
      const localStorageToken =
        loginResponse && loginResponse.data && loginResponse.data.tokan
          ? loginResponse.data.tokan
          : null;
      const token = localStorageToken;
      const userId = localStorage.getItem("userId");
      if (!meetingDate || !meetingTime) {
        console.error("Meeting date or time is not available.");
        return;
      }
      const [year, month, day] = meetingDate?.split("-").map(Number);
      const [hour, minute, second] = meetingTime?.split(":").map(Number);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/zoom/create-meeting`,
          {
            userId: userId,
            ladiesId: data?.profilenumber_ladies,
            translaterId: "",
            meetingDuration: data.consultation_time,
            svcId: orderId,
            meetingDateTime: [year, month, day, hour, minute, second],
          },
          {
            // Include necessary headers and payload for the Zoom API request
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response, "response");
        const meetingData = response?.data?.data?.meetingdata;
        const joinUrl = meetingData?.join_url;

        setMeetingUrl(joinUrl);

        const mailid = [ladiesEmail, userEmail].filter(Boolean);
        const subject = "Meeting Created Successfully";
        const html = `
  <p>Hello,
    <b>${data?.userdata?.fname} ${data?.userdata?.lname}</b>
    <br> Your meeting has been successfully created.
    Your Meeting ID is: ${meetingData?.meetingId} <br>
    <b>Please join the meeting using this URL:
      <a href="${joinUrl}" target="_blank">${joinUrl}</a>
    </b>
  </p>`;

        const notify_admin = 1;

        const mail_data = {
          mailid,
          subject,
          html,
          notify_admin,
        };

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/svconsultation/sendCustomMail`,
            mail_data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((mail_response) => {
            navigate("/welcome");
          });
      } catch (error) {
        console.error("Error creating the meeting", error);
      }
    };
    if (data) {
      createAndJoinMeeting();
    }
  }, [orderId, data]);


  return (
    <div className="container text-center mt-5">
      <div className="card shadow-lg p-3 mb-5 bg-body rounded">
        <div className="card-body">
          <div
            className="icon-container mb-4"
            style={{ backgroundColor: "#28a745" }}
          >
            <i className="fas fa-thumbs-up text-white"></i>
          </div>
          <h1 className="display-4 text-success">Payment Successful!</h1>
          <p className="lead">
            Thank you for your payment. Your transaction has been completed
            successfully.
          </p>
          <Link to="/" className="btn btn-primary mt-3">
            Go to Homepage
          </Link>
        </div>
      </div>
    </div>
  );
}

export default StripeSuccess;
