import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import axios from "axios";
import { toast } from "react-toastify";
import commonFunction from "../common/commonFunction";
const API_URL = `${process.env.REACT_APP_API_URL}`;

const AddGift = ({ open, onClose, categories }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState();
  const [getCategories, setGetCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };
  useEffect(() => {
    const response = commonFunction.fetchCategoryData();
    response.then((data) => {
      setGetCategories(data?.data);
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("image", imageFile);
    formData.append("price", price);

    formData.append("categoryId", category ? category : "");

    const TOKEN = localStorage.getItem("adminToken");

    try {
      const res = await axios.post(`${API_URL}/gifts/add`, formData, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response data:", res.data); // Log the response data

      if (res.status === 200 && res.data.status === true) {
        toast.success(res.data.message);
        onClose();
        window.location.reload();
      } else {
        toast.error("Gift creation failed.");
      }
    } catch (error) {
      toast.error("Gift creation failed.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal large-dialog">
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add Gift</DialogTitle>
        <DialogContent>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <input
              type="text"
              className="form-control"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="imageFile">Image</label>
            <input
              type="file"
              className="form-control"
              id="imageFile"
              name="imageFile"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="price">Price</label>
            <input
              type="number"
              className="form-control"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              className="form-control"
              id="category"
              value={category} // Make sure category is a scalar value (e.g., string)
              onChange={(e) => setCategory(e.target.value)}
            >
              <option key="" value="">
                Select Category
              </option>
              {getCategories?.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {" "}
                  {/* Change value to cat._id */}
                  {cat.title}
                </option>
              ))}
            </select>
          </div>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" type="submit" disabled={loading}>
            {loading ? "Adding..." : "Add"}
          </button>
          <button
            className="btn btn-secondary"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddGift;
