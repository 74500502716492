import React, { useState, useEffect } from "react";
import { Route, Link, Routes, useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import FormData from 'form-data';

function SingleLadiesProfile() {
  const navigate = useNavigate();

  
  const params = useParams();
  //Getting ladies ID
  const LadiesID = params.ladiesid;
  const [singleladieslisting, setSingleLadiesListingData] = useState([]);
  const filterParameter = 'id';
  const filterParameter2 = 'gender';
 
//consultation Time for the video call
const [consultation_time_for_call,setConsultationTime]= useState('');

  const fetchSingleLadiesData = async (userId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/getfiltereduser`, {
        [filterParameter]: userId,
        [filterParameter2]: 'female'
      });

      const userData = response.data.data[0];

      if (userId && userData) {
        // Data found, update state or do something with the data
        setSingleLadiesListingData(userData);
        console.log(userData, "Userrr Dataataa");
      } else {
        // Data not found, display a message
        console.log("Data not found");
        // You can also set a state variable to manage the UI accordingly
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
      // You might want to set an error state or display an error message
    }
  };
  // useEffect(() => {
  //     fetchSingleLadiesData();
  // }, []);

  useEffect(() => {
    fetchSingleLadiesData(LadiesID);
  }, [LadiesID]);


  const handlePrevious = () => {
    // Assuming LadiesID is a numeric value
    const previousId = parseInt(LadiesID, 10) - 1;
    fetchSingleLadiesData(previousId);
  };

  const handleNext = () => {
    // Assuming LadiesID is a numeric value
    const nextId = parseInt(LadiesID, 10) + 1;
    fetchSingleLadiesData(nextId);
  };

  //Calculate age by the birth date
  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    //console.log(age_now);
    return age_now;
  }


// Utility function to convert centimeters to feet and inches
const convertToFeetAndInches = (centimeters) => {
  const totalInches = centimeters / 2.54;
  const feet = Math.floor(totalInches / 12);
  const remainingInches = Math.round(totalInches % 12); // Round to the nearest whole inch

  // Format the result as a string
  const resultString = `${feet} feet ${remainingInches} inches`;

  return resultString;
};



  //Getting all uploaded images based on the ID
  //const [slidergallery,getSliderImages] =useState([]);
  const [slidergallery, setSliderImages] = useState(null);
  // console.log("SLIDER GLARRY", slidergallery);
  const TOKEN = localStorage.getItem('adminToken');
  //console.log(TOKEN);

  const getGalleryImages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/gallery/getbyid/${LadiesID}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      });
     if(response.data.data.length > 0){

       setSliderImages(response.data.data);
     }


    } catch (error) {
      console.error("Error fetching gallery images:", error);
    }

  }
  useEffect(() => {
    getGalleryImages();
  }, [LadiesID]);
  //console.log(slidergallery,"slidergallery=====");


/**
 * Zoom Integration for video call
 */
const client = ZoomMtgEmbedded.createClient();

var authEndpoint = "https://zoomsdk-sign-generator-express.onrender.com/";
  var sdkKey = "8SN3hNZ8RHmEo_hhBvPpFA";
  var meetingNumber = "7677432145";
  var passWord = "6Qm3Hq";
  var role = 0;
  var userName = "gorank";
  var userEmail = "gws.gorank@gmail.com";
  var registrantToken = "";
  var zakToken = "";
  var duration = consultation_time_for_call; // Set the duration in minutes


// const getSignature = async (e) => {
//   e.preventDefault();

//   try {
//     const response = await fetch(authEndpoint, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({
//         meetingNumber: meetingNumber,
//         role: role,
//         duration: '1',
//       }),
//     });

//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }

//     const responseData = await response.json();
//     startMeeting(responseData.signature);
//   } catch (error) {
//     console.error('Error fetching signature:', error);
//   }
// };
let video_calluserID = localStorage.getItem('userId');
const AUTHTOKEN = localStorage.getItem('adminToken');

const getSignature = async (selected_ladies_id, e) => {
  
  const formData = new FormData();
  if (e) {
    e.preventDefault();
  }


  const video_calluserID = localStorage.getItem('userId');
  const AUTHTOKEN = localStorage.getItem('adminToken');
  //console.log(AUTHTOKEN);
  const lid = '%23FCW_' + selected_ladies_id;
  formData.append(video_calluserID,lid); 
  let checkExistingUrl = `${process.env.REACT_APP_API_URL}/svconsultation/checkexisting/?user_id=${video_calluserID}&ladies_id=${lid}`;
  //alert(checkExistingUrl);
  try {
   
    let checkExistingResponse = await axios.get(checkExistingUrl, {
      headers: { Authorization: `Bearer ${AUTHTOKEN}`,
     },
    //  params: formData, 
    });
    console.log(checkExistingResponse,"checkExistingResponsecheckExistingResponsecheckExistingResponse");

    // console.log(checkExistingResponse, "I am a response");
   //console.log(checkExistingResponse.data.status,"Resp status")

    if (checkExistingResponse.data.status === 1) {
      // Assuming the checkexisting API returns JSON data
      const checkExistingData = checkExistingResponse.data.data;
      //console.log(checkExistingData,"I am else block!!!!!!!!!!");
     // console.log(checkExistingResponse.data,"I am resp block!!!!!!!!!!");
      console.log(checkExistingResponse.data.data.consultation_time,"consultation_time!!!!!!!!!!");
      setConsultationTime(checkExistingResponse.data.data.consultation_time);
      // Check if the data indicates that the user and lady exist
      if (checkExistingResponse.data.status === 1) {
        var authEndpoint = "https://zoomsdk-sign-generator-express.onrender.com";
        var sdkKey = "8SN3hNZ8RHmEo_hhBvPpFA";
        var meetingNumber = "7677432145";
        var passWord = "6Qm3Hq";
        var role = 0;
        var userName = "gorank";
        var userEmail = "gws.gorank@gmail.com";
        var registrantToken = "";
        var zakToken = "";
        //var duration = consultation_time_for_call; // Set the duration in minutes
        const authResponse = await fetch(authEndpoint, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            meetingNumber: meetingNumber,
            role: role,
            duration: '1',
          }),
        });

       

        if (!authResponse.ok) {
          throw new Error(`HTTP error! Status: ${authResponse.status}`);
        }

        const authData = await authResponse.json();
        //console.log(authData,"authData");
        //console.log(authData.signature);
        startMeeting(authData.signature);
        
      } else {
        // Redirect to another page if the user and lady do not exist
       //window.location.href = '/Singlevacationconsulatant';
      }
    } else {
     
      throw new Error(`HTTP error! Status: ${checkExistingResponse.status}`);
    }
  } catch (error) {
   // console.log(error.response.data.status, 'error');
    if (error.response && error.response.data.status === 0) {
      //console.log((error.response));
      navigate('/Singlevacationconsulatant');
    } 
  }
};


// const getSignature = async (selected_ladies_id, e) => {
//   try {
//     const video_calluserID = localStorage.getItem('userId');
//     const AUTHTOKEN = localStorage.getItem('adminToken');
//     const lid = '%23FCW_' + selected_ladies_id;

//     const checkExistingUrl = `https://spnode4002.elb.cisinlive.com/svconsultation/checkexisting/?user_id=${video_calluserID}&ladies_id=${lid}`;

//     const myHeaders = new Headers();
//     myHeaders.append("Authorization", `Bearer ${AUTHTOKEN}`);

//     const requestOptions = {
//       method: "GET",
//       headers: myHeaders,
//       redirect: "follow"
//     };

//     const response = await fetch(checkExistingUrl, requestOptions);
//     const result = await response.text();

//     console.log(result);
//   } catch (error) {
//     console.error('Error:', error);
//   }

// }

// useEffect(() => {
//   getSignature();
// }, []);

function startMeeting(signature) {
  //console.log(signature,"INSIDE MEEEEEETING GG")
  let meetingSDKElement = document.getElementById("meetingSDKElement");

  client
    .init({
      zoomAppRoot: meetingSDKElement,
      language: "en-US",
      patchJsMedia: true,
    })
    .then(() => {
      client
        .join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          password: passWord,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
        })
        .then(() => {
          console.log("joined successfully");

          // Open the Zoom meeting in a new window
         // Set a timer to end the meeting after the specified duration
         setTimeout(() => {
          endZoomMeeting();
        }, duration * 60 * 1000); // Convert duration to milliseconds
      })
        .catch((error) => {
          console.log(error, "Meeting having error");
          handleJoinMeetingError(error);
        });
    })
    .catch((error) => {
      console.log(error, "Meeting having error in catch");
    });
}
function endZoomMeeting() {
  //alert("End metting!!" + duration);
  ///VideoChatForm
  // Redirect to /VideoChatForm
  window.location.href = '/VideoChatForm';
}

function openZoomMeetingInNewWindow() {
  // Customize window features if needed (e.g., width, height, etc.)
  const windowFeatures = "width=800,height=600,resizable=yes,scrollbars=yes";

  // Construct the Zoom meeting URL
 // const zoomMeetingURL = `https://zoom.us/wc/${meetingNumber}/join?prefer=1&un=${encodeURIComponent(userName)}&pwd=${passWord}&role=${role}`;

  // Open the Zoom meeting in a new window
 // window.open(zoomMeetingURL, "_blank", windowFeatures);
}

function handleJoinMeetingError(error) {
  // Log the error details
  console.error('Error joining the meeting:', error);

  // Check the error type and provide user-friendly feedback
  if (error.type === 'JOIN_MEETING_FAILED') {
    //alert(`Failed to join the meeting. Reason: ${error.reason} (Error Code: ${error.errorCode})`);
  } else {
   // alert('An unexpected error occurred while joining the meeting. Please try again.');
  }
}

/**
 * Zoom Integration for video call ends here
 */
const handelgift = (ladiesId) => {
  localStorage.setItem("ladiesId", ladiesId);
  navigate(`/giftcategory/${ladiesId}`);
};


const handleFavProfile = async (ladiesID) =>{
  const userID = localStorage.getItem('userId');
  //console.log(Fav_l_id,"Fav_l_id",userID,"userID");

  try {
    const favorite_resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/favorite/managefavorite`,
      {
        user_id: userID,
        ladies_user_id: ladiesID,
      },
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
      
    ); 
     fetchSingleLadiesData(ladiesID);
  } catch (error) {
    console.log(error);
    
  }
};


useEffect(() => {
  fetchSingleLadiesData();
},[]);//Pass filter keys in our useeffect hook so based on the values it filters the data

  return (<>
    <section className="page-info-sec">
      <div className="bg-fixed" style={{ backgroundImage: "url('./img/inner_banner.png')" }}>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col">
            <div className="page-info-block">
              <h1>{singleladieslisting.fname}</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur male</p>
              <p className="page_nav"> <a href="/">Home</a><span className="mx-2">/</span><a href="#" className="currentPage">Profile</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="profile-sec secpd70">
      <div className="container" id="meetingSDKElement">
        <div className="row">
          <div className="col-md-12">
            {slidergallery && <div className="find-woman-slider-wrap">
              <OwlCarousel
                // className="owl-carousel owl-theme find-woman-slider-main"
                className="owl-theme"
                items={1}
                nav
                dots
                loop
                margin={10}
                autoplay={true} // Add this line
                autoplayTimeout={3000}
              >
                {slidergallery.map((gallerydata, index) => (
                  <div className="item" data-hash={index} key={index}>
                    <div className="user-img-box single-ladies-section" >
                      <img src={`${process.env.REACT_APP_API_URL}/${gallerydata.image}`} alt="" />
                    </div>
                  </div>
                ))}
              </OwlCarousel>

              <OwlCarousel
                className="find-woman-slider-nav owl-carousel owl-theme"
                items={1}
                nav
                dots
                margin={10}
                responsive={{
                  0: { items: 3 },
                  600: { items: 4 },
                  1000: { items: 5 },
                }}
              >
                {slidergallery && slidergallery.map((gallerydata, index) => (
                  <div className="item" data-hash={index} key={index}>
                    <div className="user-img-thumb">
                      <img src={`${process.env.REACT_APP_API_URL}/${gallerydata.image}`} alt="" />
                      {/* You can add any additional thumbnail content here */}
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>}
          </div>
          <div className="col-md-12 mt-4">
            <div className="text-center">
              <h2 className="title-30">{singleladieslisting.fname} {singleladieslisting.lname} <span className="text-black-50">({calculate_age(singleladieslisting.dob)})</span></h2>
            </div>
          </div>
          <div className="col-md-5 mt-3">
            <p className="fs-6 mb-3">Profile Number: #FCW_{singleladieslisting.id} </p>
            <p className="fs-6 mb-3">Location: {singleladieslisting.address} </p>
            <p className="fs-6 mb-3">Weight : {singleladieslisting.weight}kg </p>
            <p className="fs-6 mb-3">Height :{singleladieslisting.height} cm  ({convertToFeetAndInches(singleladieslisting.height)}) </p>
            <p className="fs-6 mb-3">Eye Color : {singleladieslisting.eyecolor} </p>
            <p className="fs-6 mb-3">Hair Color : {singleladieslisting.haircolor} </p>
            {/* </p> */}
          </div>

          <div className="col-md-7 mt-3">
            <p className="fs-6 mb-3">Do you have children? If so, what are their ages? </p>
            <p className="fs-6 mb-3">{singleladieslisting.children_ages}</p>
            <p className="fs-6 mb-3">What is your Occupation or profession? </p>
            <p className="fs-6 mb-3">{singleladieslisting.occupation}</p>
            <p className="fs-6 mb-3">What are your favorite hobbies or activities? </p>
            <p className="fs-6 mb-3">{singleladieslisting.hobbies}</p>
            <p className="fs-6 mb-3">How would you describe your personality or characteristics? </p>
            <p className="fs-6 mb-3">{singleladieslisting.personality}</p>
            <p className="fs-6 mb-3">What type of man do you wish to date or marry? </p>
            <p className="fs-6 mb-3">{singleladieslisting.person_to_marry}</p>
            <p className="fs-6 mb-3">Where do you see yourself in the future with the right man? </p>
            <p className="fs-6 mb-3">{singleladieslisting.yourself_in_future}</p>
          </div>
          <div className="col-md-12 mt-4">
            <div className="btns-wrap justify-content-center">
              <a href="#" className="btn-1 m-0 btn-md"  onClick={(e)=>getSignature(singleladieslisting.id,e)}> Video chat with Her</a>
             
              <a href="#" className="outline-btn-1 m-0 btn-md" onClick={(e) => handleFavProfile(singleladieslisting.id)}>
           
                <i className={`fa fa-heart${singleladieslisting.favorites && singleladieslisting.favorites.length > 0 && singleladieslisting.favorites[0].total_favorite != 0 ? ' redHeartIcon' : ''}`}>
                  {singleladieslisting.favorites && singleladieslisting.favorites.length > 0 && singleladieslisting.favorites[0].total_favorite!= 0
                    ? singleladieslisting.favorites[0].total_favorite
                    : ''}
                </i>
          </a>


            

              <a  onClick={() => handelgift(singleladieslisting.id)} className="btn-1 m-0 btn-md send_gift_btn">Send Gift to Her</a>
            </div>
          </div>
          <div className="col-md-12">
            <div className="next-prev-profile justify-content-between flex-wrap">
              <Link to={`/singleLadiesProfile/${parseInt(LadiesID, 10) - 1}`} className="outline-btn-1 btn-md">
                Previous profile
              </Link>
              <Link to={`/singleLadiesProfile/${parseInt(LadiesID, 10) + 1}`} className="outline-btn-1 btn-md">
                Next profile
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>)
}
export default SingleLadiesProfile