import { Link } from "react-router-dom";
import "./StripeCancel.css";
function StripeCancel() {
  return (
    <div className="container text-center mt-5">
      <div className="card shadow-lg p-3 mb-5 bg-body rounded">
        <div className="card-body">
          <div className="icon-container mb-4">
            <i className="fas fa-thumbs-down text-white"></i>
          </div>
          <h1 className="display-4 text-danger">Payment Cancelled</h1>
          <p className="lead">
            Your payment was cancelled. If you have any questions, please
            contact support.
          </p>
          <Link to="/" className="btn btn-primary mt-3">
            Go to Homepage
          </Link>
        </div>
      </div>
    </div>
  );
}

export default StripeCancel;
