import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
function UpdateUserData(){
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [dob, setDob] = useState('');
    const [password, setPassword] = useState('');
    const [id, setID] = useState(null);

    useEffect(() => {
            setID(localStorage.getItem('ID'))
            setFname(localStorage.getItem('First Name'));
            setLname(localStorage.getItem('Last Name'));
            setEmail(localStorage.getItem('Email'));
            setUsername(localStorage.getItem('Username'));
            setDob(localStorage.getItem('DOB'));
            setPassword(localStorage.getItem('password'));
    }, []);

    const updateSelectedUserData = () => {
     let res =   axios.put(`${process.env.REACT_APP_API_URL}/users/update/${id}`, {
            fname,
            lname,
            email,
            username,
            dob,
            password

        })
        if(res){
            console.log(res);
            //alert("updated"+res);
            window.location.href = "/userslist";
        }else{
           //alert("failed to update");
        }
    }
    

    return(
		<div className="container">
		<div className="row mt-4">
		<div className="col-md-5" style={{backgroundImage: "linear-gradient(0deg, rgba(1, 1, 1, 0.3), rgba(0, 0, 0, 0.3)),url('./images/Sign-up.jpg')", backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat',height:'500px',marginRight:'5px'}}>
		</div>
		<div className="col-md-6 registration_section">
		<h4>Update</h4>
		<form>
		  <div className="mb-3 mt-3">
		    <label htmlFor="email" className="form-label">First Name:</label>
		    <input type="text" className="form-control" id="firstname" placeholder="Enter firstname" name="fname" value={fname} onChange = {(e) => setFname(e.target.value)} required/>
		  </div>
		   <div className="mb-3 mt-3">
		    <label htmlFor="email" className="form-label">Last Name:</label>
		    <input type="text" className="form-control" id="lastname" placeholder="Enter lastname" name="lname" value={lname} onChange={(e)=>setLname(e.target.value)} required/>
		  </div>
		  <div className="mb-3 mt-3">
              <label htmlFor="email" className="form-label">Email:</label>
              <input type="email" className="form-control" id="email" placeholder="Enter email" name="email" value={email} onChange={(e)=>setEmail(e.target.value)} required/>
           </div>
           <div className="mb-3 mt-3">
              <label htmlFor="Username" className="form-label">Username:</label>
              <input type="text" className="form-control" id="username" placeholder="Enter username" name="username" value={username} onChange={(e)=>setUsername(e.target.value)} required/>
              <span className="text-muted">(Limit of 15 characters)</span>
           </div>
		   <div className="mb-3 mt-3">
              <label htmlFor="dob" className="form-label">dob:</label>
              <input type="date" className="form-control" id="dob" placeholder="Enter dob" name="dob" value={dob} onChange={(e)=>setDob(e.target.value)} required/>
              
           </div>
		  <div className="mb-3">
		    <label htmlFor="pwd" className="form-label">Password:</label>
		    <input type="password" className="form-control" id="pwd" placeholder="Enter password" name="password" value={password} onChange={(e)=>setPassword(e.target.value)} required/>
		  </div>
		  
		  <button type="submit" onClick={updateSelectedUserData} className="btn btn-primary regsiter_btn">Submit</button>
		</form>
		</div>
		</div>
		</div>
	)

}
export default UpdateUserData
 