// Dashboard.js

import React from "react";
import { Link, Redirect } from 'react-router-dom';
import { isAuthenticated } from './auth'; // Import the isAuthenticated helper function
import AdminNavigation from './AdminNavigation';
import { useState, useEffect } from 'react';
function Dashboard() {
  useEffect(() => {
    // Check if 'userId' is not present in localStorage
    if (!localStorage.getItem('userRole') && localStorage.getItem('userRole')!== 1) {
      // Redirect to the "/login" page if 'userId' is not present
      window.location.href = "/login";
    }
    // window.location.reload();
  }, []); // The empty dependency array ensures that this effect runs once when the component mounts

  const userRole = localStorage.getItem('userRole')
  console.log(userRole,"userRole");



  return (
    <>
      <div id="wrapper" className="toggled">
        <AdminNavigation />
        <div id="page-content-wrapper">
          {/* <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button> */}
          <section className="welcome-sec secpd70">
            <div className="container">
              <div className="shadow-wrap-1">
                <div className="row gap-40">
                  <div className="col-12">
                    <h2 className="title-30 mb-3">Welcome,Admin</h2>
                  </div>
                  <div className="col-1/5">
                    <Link to="/admin/allusers">
                      <div className="welcome-box">
                        <div className="wb-icon-box">
                          <img src={`${process.env.PUBLIC_URL}/img/icon-1.png`} alt="" />
                        </div>
                        <p>All Users</p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-1/5">
                    <Link to="/admin/pages">
                      <div className="welcome-box">
                        <div className="wb-icon-box">
                          <img src={`${process.env.PUBLIC_URL}/img/icon-2.png`} alt="" />
                        </div>
                        <p>All Pages</p>
                      </div>
                    </Link>
                  </div>

                  <div className="col-1/5">
                    <Link to="/admin/settings">
                      <div className="welcome-box">
                        <div className="wb-icon-box">
                          <img src={`${process.env.PUBLIC_URL}/img/icon-3.png`} alt="" />
                        </div>
                        <p>Settings</p>
                      </div>
                    </Link>
                  </div>

                  <div className="col-1/5">
                    <Link to="/admin/admin_video_playlist">
                      <div className="welcome-box">
                        <div className="wb-icon-box">
                          <img src={`${process.env.PUBLIC_URL}/img/icon-4.png`} alt="" />
                        </div>
                        <p>Video Listing</p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-1/5">
                    <Link to="/admin/media">
                      <div className="welcome-box">
                        <div className="wb-icon-box">
                          <img src={`${process.env.PUBLIC_URL}/img/icon-6.png`} alt="" />
                        </div>
                        <p>Media Listing</p>
                      </div>
                    </Link>
                  </div>

                </div>

              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Dashboard;