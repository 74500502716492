import React from 'react';

const Pagination = ({ currentPage, totalPages, handlePaginationClick }) => {
    //console.log("==============",currentPage);
    //console.log("==============",totalPages);
   
    const renderPageButtons = () => {
        const buttons = [];
        for (let i = 1; i <= totalPages; i++) {
            buttons.push(
                <li key={i} className={currentPage === i ? 'active' : ''}>
                    <button onClick={() => handlePaginationClick(i)} className={currentPage === i ? 'btn btn-warning mx-1' : 'btn btn-primary mx-1'}>{i}</button>
                </li>
            );
        }
        return buttons;
    };

    return (
        <ul className="pagination">
            {renderPageButtons()}
        </ul>
    );
};

export default Pagination;
