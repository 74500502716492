import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const EditCategory = ({ open, onClose, categoryId, categoryData }) => {
  const API_URL = `${process.env.REACT_APP_API_URL}`;
  const TOKEN = localStorage.getItem("adminToken");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageError, setImageError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  useEffect(() => {
    if (categoryData) {
      setTitle(categoryData.title || "");
      setDescription(categoryData.description || "");
      setImage(categoryData.image || "");
    }
  }, [categoryData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      setTitle(value);
    } else if (name === "description") {
      setDescription(value);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImageFile(file);
      setImageError("");
    } else {
      setImageFile(null);
      setImageError("Please select a valid image file.");
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!title.trim()) {
      setTitleError("Title cannot be empty");
      isValid = false;
    } else {
      setTitleError("");
    }
    if (!description.trim()) {
      setDescriptionError("Description cannot be empty");
      isValid = false;
    } else {
      setDescriptionError("");
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("image", imageFile);
        const res = await axios.put(
          `${API_URL}/giftcategory/update/${categoryId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        );
        console.log("Category Update RESP", res);
        window.location.reload();
        onClose();
      } catch (error) {
        console.error("Error updating category:", error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="mui-modal large-dialog">
      <form onSubmit={handleSubmit}>
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Title"
            name="title"
            value={title}
            onChange={handleChange}
            variant="outlined"
            margin="dense"
            error={Boolean(titleError)}
            helperText={titleError}
          />

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            name="description"
            value={description}
            onChange={handleChange}
            variant="outlined"
            margin="dense"
            error={Boolean(descriptionError)}
            helperText={descriptionError}
          />
          {image && (
            <img
              src={`${process.env.REACT_APP_API_URL}/${image}`}
              alt="Category Image"
              style={{ maxWidth: "15%", marginTop: "10px" }}
            />
          )}
          <div className="form-group">
            <input
              type="file"
              className="form-control"
              id="imageFile"
              name="imageFile"
              accept="image/*"
              onChange={handleImageChange}
            />
            {imageError && <div className="error m-1">{imageError}</div>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            Edit
          </Button>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditCategory;
